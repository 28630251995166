import React, { FC } from 'react';
import clsx from 'clsx';

interface RadioButtonProps {
  isSelected: boolean;
  label: string;
  value: string;
  onClick: (e: any) => void;
  disabled?: boolean;
  error?: boolean;
  errorStyle?: string;
  labelStyle?: string;
}

const labelStyles = {
  common: 'text-body ml-2 mr-6',
  error: 'text-error peer-focus:text-error'
};

const inputStyles = {
  common: 'h-4 w-4 accent-primary',
  error: 'appearance-none border-error border rounded-full'
};

const RadioButton: FC<RadioButtonProps> = ({
  isSelected,
  label,
  onClick,
  value,
  error,
  errorStyle,
  labelStyle,
  ...props
}) => {
  const labelClassNames = clsx(
    labelStyles.common,
    labelStyle,
    error && labelStyles.error
  );
  const inputClassNames = clsx(inputStyles.common, error && inputStyles.error);

  return (
    <div className="flex items-center">
      <input
        type="radio"
        onClick={onClick}
        id={label}
        value={value}
        {...props}
        checked={isSelected}
        className={inputClassNames}
        onChange={() => {}}
      />
      <label htmlFor={label} className={labelClassNames}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
