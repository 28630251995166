import { CopyText } from '@components/index';

interface HeaderCardProps {
  header: string;
  text: string;
  className?: string;
  isCopyText?: boolean;
  testId: string;
}

const HeaderCard = ({
  header,
  text,
  className = '',
  isCopyText = false,
  testId
}: HeaderCardProps) => (
  <div className={className} data-testid={testId}>
    <div className="text-body-md mb-1 text-background90">{header}</div>
    <div className="text-body-base-semi flex items-start">
      {text}
      {isCopyText && <CopyText textToCopy={text} className="relative ml-3" />}
    </div>
  </div>
);

export default HeaderCard;
