import { posIntRegex, floatNumberRegex } from '@constants/regexps';
import { CourierPartner, Field, PartnerAccount } from '@types';
import { timeFormat, validateWithRegexp } from '@utils/generic';
import { Time } from '@components/timePicker/TimePicker';

const getCourierPartnersListState = (courierPartnersList: CourierPartner[]) => {
  const courierPartnersListMap = new Map<string, CourierPartner>();

  courierPartnersList.forEach((partner) => {
    courierPartnersListMap.set(partner.entityId, partner);
  });

  return courierPartnersListMap;
};

const getPartnerAccountsListState = (partnerAccountsList: PartnerAccount[]) => {
  const partnerAccountsListMap = new Map<string, PartnerAccount>();

  partnerAccountsList.forEach((account) => {
    partnerAccountsListMap.set(account.entityId, account);
  });

  return partnerAccountsListMap;
};

const validateInputFields = (fieldItem: Field, value: string) => {
  switch (fieldItem.dataType.toLocaleLowerCase()) {
    case 'int':
      return validateWithRegexp(posIntRegex, value.trim());
    case 'float':
      return validateWithRegexp(floatNumberRegex, value.trim());
    default:
      return true;
  }
};

const applyCustomLabel = (predefinedFields: any) => {
  const LENGTH_FIELDS = ['length', 'breadth', 'height'];
  const WEIGHT_FIELD = 'weight';
  let updatedPredefined = [];
  if (predefinedFields?.data) {
    updatedPredefined = predefinedFields.data.map((item: any) => {
      if (
        LENGTH_FIELDS.includes(item.displayName.toLocaleLowerCase()) ||
        item.displayName.toLocaleLowerCase() === WEIGHT_FIELD
      ) {
        return {
          ...item,
          displayName:
            item.displayName.toLocaleLowerCase() === WEIGHT_FIELD
              ? `${item.displayName}(Grams)`
              : `${item.displayName}(cm)`
        };
      }
      return item;
    });
  }
  return { ...predefinedFields, data: updatedPredefined };
};

const validateTime = (startTime: Time, endTime: Time) => {
  if (
    (startTime.hour === endTime.hour && startTime.min >= endTime.min) ||
    startTime.hour > endTime.hour
  )
    return false;
  return true;
};

const timeToMins = (time: string) => {
  const b = time?.split(':');
  return parseInt(b?.[0], 10) * 60 + parseInt(b?.[1], 10);
};

const timeFromMins = (minutes: number) => {
  let totalMin = minutes;
  if (minutes < 0) {
    totalMin = minutes + 24 * 60;
  }
  const hour = Math.floor(totalMin / 60) % 24;
  const min = totalMin % 60;
  return {
    hour,
    min
  };
};

const getFormattedTime = (time?: Time) =>
  `${timeFormat(time?.hour)}:${timeFormat(time?.min)}`;

const getLocalTime = (utcTime: string, offset: string) => {
  if (offset?.[0] === '+')
    return timeFromMins(timeToMins(utcTime) + timeToMins(offset?.slice(1)));
  return timeFromMins(timeToMins(utcTime) - timeToMins(offset?.slice(1)));
};

const getUtcTime = (localTime: string, offset: string) => {
  let time;
  if (offset?.[0] === '+')
    time = timeFromMins(timeToMins(localTime) - timeToMins(offset?.slice(1)));
  else
    time = timeFromMins(timeToMins(localTime) + timeToMins(offset?.slice(1)));

  return `${timeFormat(time?.hour)}:${timeFormat(time?.min)}:00`;
};

const formatStatusData = (dataToFormat: any) =>
  dataToFormat?.map((data: any) => ({
    entityId: data.entityId,
    status: data.status,
    cpStatus: data.cpStatus,
    description: data.description || ''
  }));

const handleIsChangedStatusMapData = (formData: any, initialData: any) => {
  let isChangesMade = false;
  const formattedData = formatStatusData(formData);
  const formattedInitialData = formatStatusData(initialData);
  isChangesMade =
    JSON.stringify(formattedData) !== JSON.stringify(formattedInitialData);
  return isChangesMade;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getCourierPartnersListState,
  getPartnerAccountsListState,
  validateInputFields,
  applyCustomLabel,
  validateTime,
  getUtcTime,
  getLocalTime,
  getFormattedTime,
  handleIsChangedStatusMapData
};
