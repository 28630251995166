export const pwdRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=~`!*()_\-{}[\]|\\:;'"<>,./?]).{6,}$/;

export const emailRegex =
  /^[a-zA-Z0-9._:$!%-]+[+]?[0-9]*@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const nameRegex = /^[A-Za-z]+[ _A-Za-z.]*$/;
export const minCharRegex = /([a-zA-Z0-9@#$%^&+=]*).{6,}/;
export const upperCharRegex = /[A-Z]/;
export const lowerCharRegex = /[a-z]/;
export const specialCharRegex = /[@#$%^&+=~`!*()_\-{}[\]|\\:;'"<>,./?]/;

export const posIntRegex = /^[0-9]\d*$/;
export const numberRegex = /^[0-9]\d*(.[0-9]\d*)*$/;

export const ruleCappingRegex = /^(\d{1,6}|1000000)$/;

export const rulePriorityRegex = /^(\d{1,4}|10000)$/;
export const cappingRegex = /^(\d{1,6}|10000)$/;

export const regionNameRegex = /^[ A-Za-z0-9]+([ A-Za-z.]+)*$/;
export const regionCodeRegex = /^[A-Z]{2,3}$/;

export const ruleNameRegex = /^[ A-Za-z0-9]+$/;
export const strictUpperCharRegex = /^[A-Z]/;
export const awbLabelCaseRegex = /awb/i;
export const floatNumberRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
export const accountCodeRegex = /^([_0-9A-Za-z]*)$/;

export const stringRegex = /^[a-zA-Z]$/;
export const capitalLetterRegex = /(?=[A-Z])/;
export const milliSecondInDateRegex = /\.(?=[^.]+$)/; // detect milliseconds in dates
