import { MutableRefObject, ReactNode, useRef } from 'react';
import clsx from 'clsx';
import ClickOutside from '@utils/clickOutside';
import testId from '@constants/testId';

interface SearchWIthOptionsProps {
  className?: string;
  searchOptions: { key: any; value: any }[];
  onOptionChange: (e: any) => void;
  selectedSearchOption: string;
  title?: string;
  wrapperStyle?: string;
  onClickOutsideHandler: () => void;
  children?: ReactNode;
}
const SearchWithOptions = ({
  className,
  searchOptions,
  onOptionChange,
  selectedSearchOption,
  title,
  wrapperStyle,
  onClickOutsideHandler,
  children,
  ...props
}: SearchWIthOptionsProps) => {
  const SearchRef = useRef() as MutableRefObject<HTMLDivElement>;
  ClickOutside({ ref: SearchRef, handler: onClickOutsideHandler });
  return (
    <div
      ref={SearchRef}
      className={clsx(
        'absolute z-50 rounded-md bg-white p-3.5 shadow-mediumBlur',
        wrapperStyle
      )}
      data-testid={testId.orders.searchBar.searchPopup}
      {...props}
    >
      <p
        className="text-body-md mb-3"
        data-testid={testId.orders.searchBar.searchBy}
      >
        {title}
      </p>
      <div className={className}>
        {searchOptions.map((option) => {
          const isSelected = selectedSearchOption === option.key;
          return (
            <label
              key={option.key}
              className={clsx(
                'mr-2 flex h-7 cursor-pointer items-center rounded-2xl border border-solid px-3',
                isSelected ? 'border-primary text-primary' : 'text-background70'
              )}
              data-testid={testId.orders.searchBar.searchOption}
            >
              <input
                type="radio"
                name="search"
                value={option.key}
                onChange={onOptionChange}
                checked={selectedSearchOption === option.key}
                className="mr-3 h-4 w-4 cursor-pointer accent-primary"
                data-testid={testId.orders.searchBar.radioButton}
              />
              <div
                className={clsx(
                  'text-body',
                  isSelected ? 'text-primary' : 'text-background90'
                )}
              >
                {option.value}
              </div>
            </label>
          );
        })}
      </div>
      {children}
    </div>
  );
};
export default SearchWithOptions;
