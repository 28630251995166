import { useEffect, useRef } from 'react';

import {
  DoughnutChartDataProps,
  CustomDoughnutChartProps,
  DoughnutChartTooltipProps,
  ChartData
} from './types';
import {
  drawDoughnutChartToolTip,
  getCustomDoughnutChartValues
} from './helpers';
import ChartLegendText from './ChartLegendText';
import ChartLegendTextTable from './ChartLegendTextTable';

const CustomDoughnutChart = ({
  chartData,
  innerText = '',
  primaryColor = '#3BB785',
  secondaryColor = '#F3C350',
  legendTableHeaders,
  legendTextHeader,
  dimensions
}: CustomDoughnutChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const tooltipCanvasRef = useRef<HTMLCanvasElement>(null);

  const { width, height, radius } = dimensions;

  const drawArc = (ctx: any, color: string, startAt: number, endAt: number) => {
    const ctxWidth = ctx.canvas.width;
    const ctxHeight = ctx.canvas.height;
    ctx.strokeStyle = color;
    ctx.beginPath();
    // ctx.arc(width, height, radius, start, end)
    ctx.lineCap = 'round';
    ctx.arc(ctxWidth / 2, ctxHeight / 2, ctxWidth / 2 - radius, startAt, endAt);
    ctx.lineWidth = radius;
    ctx.stroke();
  };
  const chartList = getCustomDoughnutChartValues(
    chartData,
    primaryColor,
    secondaryColor
  );

  const getPercentage = (angle: number, quarter: number) => {
    if (quarter === 1) return -angle / (2 * Math.PI);
    if (quarter === 2 || quarter === 3)
      return (Math.PI - angle) / (2 * Math.PI);
    return (2 * Math.PI - angle) / (2 * Math.PI);
  };

  const getTooltipText = (
    percentageValue: number
  ): DoughnutChartTooltipProps => {
    let tooltipText = {
      text: '',
      value: ''
    };

    chartList.map((item) => {
      if (
        item.endAt > percentageValue &&
        item.startAt < percentageValue &&
        percentageValue < 0.9 &&
        percentageValue > 0.1
      ) {
        tooltipText = {
          text: item.name,
          value: item.value.toString()
        };
      }
      return null;
    });
    return tooltipText;
  };

  const handleMouseMove = (event: any) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      let quarter: number = 0;
      if (mouseX > centerX) {
        if (mouseY > centerY) quarter = 4;
        else quarter = 3;
      } else if (mouseY > centerY) quarter = 1;
      else quarter = 2;

      const diffX = mouseX - centerX;
      const diffY = mouseY - centerY;
      const distance = Math.sqrt(diffX * diffX + diffY * diffY);

      // Radius 15 so stroke b/w 7.5 and 22.5
      if (distance > centerX - 22.5 && distance < centerX - 7.5) {
        const angle = Math.atan(diffX / diffY);
        const percentageValue = getPercentage(angle, quarter);
        const tooltipCanvas = tooltipCanvasRef.current;
        if (tooltipCanvas) {
          const tooltipData = getTooltipText(percentageValue);
          drawDoughnutChartToolTip(tooltipCanvas, tooltipData, mouseX, mouseY);
        }
      } else {
        // Clear canvas if not hit
        const tooltipCanvas = tooltipCanvasRef.current;
        if (tooltipCanvas) {
          tooltipCanvas.style.left = '-200px';
          const ctx = tooltipCanvas.getContext('2d');
          if (ctx) {
            ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
          }
        }
      }
    }
  };

  const drawFixedCustomDoughnutChart = () => {
    const canvas = canvasRef.current;
    if (canvas && chartList.length > 0) {
      const context = canvas.getContext('2d');
      if (context) {
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        chartList.map((item) => {
          if (item.value) {
            const startPoint = item.startAt === 0 ? 0.1 : item.startAt;
            const endPoint = item.endAt === 1 ? 0.9 : item.endAt;
            drawArc(
              context,
              item?.fillColor || 'FFF',
              2 * Math.PI * startPoint + Math.PI / 2,
              2 * Math.PI * endPoint + Math.PI / 2
            );
          }
          return null;
        });
      }
    }
  };

  const drawAnimatedCustomDoughnutChart = () => {
    const canvas = canvasRef.current;
    let animationFrameId: any;

    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        let frameCount = 60;
        const totalFrame = 600;
        const frameLimit = totalFrame - frameCount;
        let counter = 0;
        let element: DoughnutChartDataProps = chartList[0];
        const render = () => {
          [...Array(15)].forEach(() => {
            // Math.PI / 2 is used to rotate 90 degree
            if (frameCount <= frameLimit) {
              if (element.endAt < frameCount / totalFrame) {
                counter += 1;
                element = chartList[counter];
              }
              if (element.value)
                drawArc(
                  context,
                  element.fillColor || '#FFF',
                  (2 * Math.PI * (frameCount - 1)) / totalFrame + Math.PI / 2,
                  (2 * Math.PI * (frameCount + 1)) / totalFrame + Math.PI / 2
                );
            }
            frameCount += 1;
          });

          if (frameCount <= frameLimit) {
            animationFrameId = window.requestAnimationFrame(() => render());
          }
        };

        requestAnimationFrame(() => render());
      }
    }
    return animationFrameId;
  };

  useEffect(() => {
    const animationFrameId = drawAnimatedCustomDoughnutChart();
    // Total 60 frames with speed of 60pfs in animation
    // Drawing Complete Chart after 1 sec
    setTimeout(() => {
      drawFixedCustomDoughnutChart();
    }, 2000);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableData = (): ChartData[] => {
    const tempData: ChartData[] = chartData.map((item) => ({
      name: item.name,
      value: item.value,
      tableValue: `${item.value}/${item.secondaryValue}`,
      fillColor: item.status ? '#3BB785' : '#F3C350'
    }));
    return tempData;
  };

  const tableData = getTableData();

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center">
        <div className="relative flex h-fit w-full justify-center">
          <div className="relative h-fit w-fit">
            <canvas
              ref={canvasRef}
              width={width}
              height={height}
              style={{ backgroundColor: 'white' }}
              onMouseMove={(e) => handleMouseMove(e)}
            />
            <canvas
              ref={tooltipCanvasRef}
              className="absolute "
              style={{ zIndex: 4 }}
              onMouseMove={(e) => handleMouseMove(e)}
            />
          </div>
          {innerText && (
            <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center ">
              <div className="text-body text-center text-background90">
                {innerText}
              </div>
              <div className="text-h4">{chartData.length}</div>
            </div>
          )}
        </div>
        {legendTextHeader && (
          <div className="text-body-base-md my-2">{legendTextHeader}</div>
        )}
        <div className="flex gap-4">
          {chartList.map((item) =>
            item.name ? (
              <ChartLegendText
                text={item.name}
                color={item.fillColor || '#FFF'}
                key={item.name}
                count={item.value}
              />
            ) : null
          )}
        </div>
      </div>
      <ChartLegendTextTable
        chartData={tableData}
        infoPosition="right"
        maxHeight={height}
        maxWidth={width}
        legendTableHeaders={legendTableHeaders}
      />
    </div>
  );
};

export default CustomDoughnutChart;
