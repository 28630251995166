import { Region, Scalars } from '@types';
import { parentRoutesWithChilds } from '@constants/core';

export const extractRegionCodeFromUrl = (
  hostName?: Scalars['String']
): Scalars['String'] => {
  const currentDomain = process.env.REACT_APP_DOMAIN;
  const hostname = hostName || window.location.hostname;
  const regionCode = hostname
    ?.split(currentDomain || '')?.[0]
    ?.split('.')?.[0]
    ?.trim();
  return regionCode || '';
};

export const validateRegionCode = (
  regionCode: Scalars['String'],
  regionCodes: Region[]
): Scalars['Boolean'] => {
  let isRegionCodeValid = false;
  if (regionCode) {
    isRegionCodeValid = regionCodes?.some(
      (region: Region) => region?.regionCode === regionCode?.toUpperCase()
    );
  }
  return isRegionCodeValid;
};

// eslint-disable-next-line max-len
// NOTE: Doesnt support child paths without base path appended to have a dynamic id appended along with route.
const getValidPathname = (pathname: Scalars['String']): Scalars['String'] => {
  let modifiedPathname = pathname;
  if (modifiedPathname) {
    parentRoutesWithChilds.forEach((routeItem) => {
      if (
        modifiedPathname.includes(routeItem.parentPath) ||
        routeItem.childPaths.some((childPath) =>
          modifiedPathname.includes(childPath)
        )
      ) {
        modifiedPathname = routeItem.parentPath;
      }
    });
  }
  return modifiedPathname;
};

export const redirectToRegionURL = (
  regionCode: Scalars['String'],
  isInitialRedirect: Scalars['Boolean'],
  prevRegionCode?: Scalars['String']
) => {
  const { host, protocol, pathname } = window.location;
  const modifiedPathname = getValidPathname(pathname);
  let modifiedHost = host;
  if (prevRegionCode && !isInitialRedirect)
    modifiedHost = host?.split(`${prevRegionCode?.toLowerCase()}.`)?.[1];
  const redirectUrl = `${protocol}//${regionCode}.${modifiedHost}${modifiedPathname}`;
  window.location.replace(redirectUrl);
};
