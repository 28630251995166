import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ALLOCATION } from '@constants/routes';

const AllocateRule = lazy(() => import('@features/rules/pages/rules/Rules'));
const CreateRule = lazy(
  () => import('@features/rules/pages/create-rule/CreateRule')
);

export const RuleRoutes: RouteObject = {
  path: ALLOCATION.ALLOCATION,
  children: [
    {
      path: ALLOCATION.RULE,
      element: <AllocateRule />
    },
    {
      path: ALLOCATION.CREATE_RULE,
      element: <CreateRule />
    },
    {
      path: ALLOCATION.EDIT_RULE,
      element: <CreateRule />
    }
  ]
};

export default RuleRoutes;
