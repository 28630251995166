/* eslint-disable tailwindcss/no-contradicting-classname */
import { useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';
import { ReactComponent as DragIcon } from '@components/icon/icons/draggable.svg';

import { DraggableRowProps, DragItem, TableData } from './types';

export const DraggableRow: React.FC<DraggableRowProps> = ({
  id,
  index,
  moveCard,
  tableData,
  colKeys,
  tableDataStyle
}) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const previewRef = useRef(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'tableRow',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'tableRow',
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(previewRef);
  drag(ref);
  preview(previewRef);
  return (
    <tr
      ref={previewRef}
      style={{ opacity: isDragging ? 0 : 1 }}
      data-handler-id={handlerId}
      className="group/drag hover:bg-background5 "
    >
      <td ref={ref} className="cursor-grab px-2">
        <DragIcon className="group-hover/drag:visible invisible m-auto" />
      </td>
      {colKeys.map((colKey) => (
        <td
          className={clsx(
            'text-body-md max-w-0 truncate px-6 py-4',
            tableDataStyle
          )}
          key={colKey}
        >
          {tableData[colKey]}
        </td>
      ))}
    </tr>
  );
};

export const TableRow = ({
  tableData,
  colKeys,
  tableDataStyle
}: {
  tableData: TableData;
  colKeys: string[];
  tableDataStyle?: string;
}) => (
  <tr>
    {colKeys.map((colKey) => (
      <td
        className={clsx(
          'text-body-md max-w-0 truncate px-6 py-4',
          tableDataStyle
        )}
        key={colKey}
      >
        {tableData[colKey]}
      </td>
    ))}
  </tr>
);
