import { useTheme } from '@contexts/theme';
import Button, { ButtonLabel } from '@components/button/Button';
import Icon from '@components/icon/Icon';
import useTranslate from '@hooks/intl';
import {
  CheckboxFilterConfig,
  DateRangeFilterConfig,
  RadioFilterConfig,
  Scalars,
  TableFilterConfigType,
  TableFilterTypes
} from '@types';
import clsx from 'clsx';

interface FilterListProps {
  selectedFilterKey: Scalars['String'];
  filterState: TableFilterConfigType;
  setSelectedFilterKey: (key: string) => void;
  handleApply: () => void;
  clearFilters: () => void;
}

const FilterList: React.FC<FilterListProps> = ({
  filterState,
  selectedFilterKey,
  clearFilters,
  setSelectedFilterKey,
  handleApply
}) => {
  const translate = useTranslate();
  const { colors } = useTheme();

  return (
    <div className="w-1/2 border-r border-background10">
      <div className="max-h-full overflow-auto p-2 pb-24">
        {Object.keys(filterState)?.map((key: any) => {
          const { selectedOptionsCount, type } = filterState[
            key
          ] as CheckboxFilterConfig;
          const { isDateSelected } = filterState[key] as DateRangeFilterConfig;

          return (
            <div
              className={clsx(
                'mb-2 rounded-lg text-start hover:bg-primary10',
                selectedFilterKey === key ? 'bg-primary10' : ''
              )}
              key={key}
            >
              <Button
                className="flex w-full items-center justify-between p-3 text-sm"
                handleClick={() => {
                  setSelectedFilterKey(key);
                }}
                variant="transparent"
                size="none"
              >
                <ButtonLabel
                  label={filterState[key].name}
                  variant="transparent"
                  className={clsx(
                    'flex w-full items-center justify-between p-3 text-sm',
                    (selectedOptionsCount &&
                      selectedOptionsCount > 0 &&
                      type === TableFilterTypes.RADIO) ||
                      isDateSelected
                      ? 'text-primary'
                      : '!text-black'
                  )}
                />
                <div className="flex items-center">
                  {(isDateSelected ||
                    (type === TableFilterTypes.RADIO &&
                      (filterState[key] as RadioFilterConfig).options?.find(
                        (item) => item.selected
                      ))) && (
                    <Icon
                      name="circle-tick"
                      className="h-5 w-5"
                      fill={colors.primary}
                    />
                  )}
                  {!!selectedOptionsCount &&
                    type !== TableFilterTypes.RADIO && (
                      <div className="h-5 w-5 rounded-full bg-primary text-white">
                        {selectedOptionsCount}
                      </div>
                    )}
                  <Icon
                    name="arrow-right"
                    fill="transparent"
                    stroke={
                      selectedFilterKey === key ? colors.primary : colors.black
                    }
                    className="ml-2 mt-2"
                  />
                </div>
              </Button>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 z-10 flex w-[310px] gap-4 bg-white p-6">
        <Button
          variant="secondary"
          handleClick={clearFilters}
          label={translate('component.filter.clearAll_button')}
          size="small"
        />
        <Button
          variant="primary"
          handleClick={() => handleApply()}
          label={translate('component.filter.apply_button')}
          size="small"
        />
      </div>
    </div>
  );
};

export default FilterList;
