import { Scalars } from '@types';

interface ChartLegendTextProps {
  text: Scalars['String'];
  color: Scalars['String'];
  count?: number;
  className?: string;
}

const ChartLegendText = ({
  text,
  color,
  count,
  className = ''
}: ChartLegendTextProps) => (
  <div className={`flex items-center gap-2 ${className}`}>
    <div
      className="h-3 w-3 min-w-[12px] rounded-full"
      style={{ backgroundColor: color }}
    />
    <div className="truncate text-sm text-background">{text}</div>
    {count && <div className="text-sm text-black">{count}</div>}
  </div>
);

export default ChartLegendText;
