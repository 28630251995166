import { Scalars } from '@types';

export type GetLogType = {
  entityId: Scalars['ID'];
  executedAt: Scalars['String'];
  data: Scalars['String'];
  event: Scalars['String'];
  logKey: Scalars['String'];
  tenantId: Scalars['Int'];
  type: Scalars['String'];
};

export enum ApiLogsEventName {
  FAILED_EVENT = 'FAILED_EVENTS',
  COURIER_PARTNER_REQUEST = 'COURIER_PARTNER_REQUEST',
  COURIER_PARTNER_RESPONSE = 'COURIER_PARTNER_RESPONSE',
  CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_RESPONSE = 'CREATE_ORDER_RESPONSE',
  CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST'
}

export type WebhookLogType = {
  eventCode: Scalars['String'];
  awb: Scalars['String'];
  externalEntityId: Scalars['String'];
  externalEntityType: Scalars['String'];
  retryCount: Scalars['Int'];
  retryAt: Scalars['Int'];
  responseHttpCode: Scalars['String'];
  responsePayload: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
  name: Scalars['String'];
  requestPayload: Scalars['String'];
  status: Scalars['String'];
};
