const testId = {
  authentication: {
    login: {
      emailInput: 'authentication_login_email',
      passwordInput: 'authentication_login_password',
      forgetPasswordButton: 'authentication_login_forgotPassword',
      submitButton: 'authentication_login_submit',
      loading: 'authentication_login_loading'
    },
    confirmPassword: {
      newPasswordInput: 'authentication_reset_confirm_newPassword',
      confirmPasswordInput: 'authentication_reset_confirmPassword',
      continueButton: 'authentication_reset_confirm_continue',
      loginButton: 'authentication_reset_confirm_login',
      loading: 'authentication_reset_confirm_loading'
    },
    resetPassword: {
      emailInput: 'authentication_reset_email',
      resetPasswordButton: 'authentication_resetPassword',
      continueBtn: 'authentication_continueBtn',
      resendButton: 'authentication_reset_resendButton'
    },
    setPassword: {
      passwordInput: 'authentication_register_password',
      confirmInput: 'authentication_register_confirm',
      continueButton: 'authentication_register_continue',
      loading: 'authentication_register_loading',
      email: 'authentication_register_email'
    },
    authSection: {
      title: 'authentication_title',
      description: 'authentication_description',
      icon: 'authentication_icon'
    },
    passwordValidation: {
      title: 'authentication_passwordValidation_title',
      minimumChar: 'authentication_passwordValidation_minimumChar',
      specialChar: 'authentication_passwordValidation_specialChar',
      upperCaseChar: 'authentication_passwordValidation_upperCaseChar',
      lowerCaseChar: 'authentication_passwordValidation_lowerCaseChar',
      icon: 'authentication_passwordValidation_icon'
    }
  },
  orders: {
    searchBar: {
      searchBar: 'orders_searchBar',
      searchPopup: 'orders_searchBar_searchPopup',
      searchBy: 'orders_searchBar_searchBy',
      searchOption: 'orders_searchBar_searchOption',
      radioButton: 'orders_searchBar_radioButton'
    },
    ordersPage: {
      ordersTitle: 'orders_title',
      generateReport: 'orders_generateReport_button',
      searchBar: 'orders_searchBar',
      filter: 'orders_filter',
      table: 'orders_table',
      bulkSearchToggle: 'orders_bulkSearchToggle'
    },
    manualStatus: {
      form: {
        table: 'orders_table',
        pagination: 'orders_pagination',
        remarks: 'order_remarks'
      }
    },
    orderDetails: {
      title: 'orders_orderDetails_title',
      awb: 'orders_orderDetails_awb_label',
      courierPartner: 'orders_orderDetails_courierPartner',
      shipmentStatus: 'orders_orderDetails_shipmentStatus',
      destination: 'orders_orderDetails_destination',
      dateTime: 'orders_orderDetails_dateTime',
      customerInfo: 'orders_orderDetails_customerInfo',
      orderInfo: 'orders_orderDetails_orderInfo',
      shipmentTimeLine: 'orders_orderDetails_shipmentTimeLine',
      productList: 'orders_orderDetails_productList',
      deliveryType: 'orders_orderDetails_deliveryType',
      deliveryMode: 'orders_orderDetails_deliveryMode'
    },
    globalOrdersPage: {
      ordersTitle: 'globalOrders_title',
      generateReport: 'globalOrders_generateReport_button',
      searchBar: 'globalOrders_searchBar',
      bulkSearchToggle: 'globalOrders_bulkSearchToggle',
      filter: 'globalOrders_filter',
      table: 'globalOrders_table'
    }
  },
  rules: {
    allocateRule: {
      title: 'rules_allocateRule_title',
      table: 'rules_allocateRule_table',
      priorityInput: 'rules_allocateRule_priority',
      reorderPrioritySwitch: 'rules_allocateRule_reorderPriority',
      cancelButton: 'rules_allocateRule_cancelButton',
      saveButton: 'rules_allocateRule_saveButton',
      createNewRuleButton: 'rules_allocateRule_createNewRuleButton',
      viewRuleTable: 'rules_allocateRule_viewRuleTable',
      confirmationModal: 'rules_allocateRule_confirmationModal'
    },
    createRule: {
      title: 'rules_createRule_title',
      ruleNameInput: 'rules_createRule_name',
      tooltip: 'rules_createRule_tooltip',
      nameTooltip: 'rules_createRule_nameTooltip',
      priorityTooltip: 'rules_createRule_priorityTooltip',
      clearAllButton: 'rules_createRule_clearAllButton',
      submitButton: 'rules_createRule_submitButton',
      fieldTypeDropDown: 'rules_createRule_fieldTypeDropDown',
      fieldNameDropDown: 'rules_createRule_fieldNameDropDown',
      dataTypeDropDown: 'rules_createRule_dataTypeDropDown',
      fieldOperatorDropDown: 'rules_createRule_fieldOperatorDropDown',
      fieldValue: 'rules_createRule_fieldValue',
      editButton: 'rules_createRule_editButton',
      deleteButton: 'rules_createRule_deleteButton',
      addConditionButton: 'rules_createRule_addConditionButton',
      cancelButton: 'rules_createRule_cancelButton ',
      partnerAccountsDropdown: 'rules_createRule_partnerAccountsDropdown',
      clearAllConfirmationModal: 'rules_createRule_clearAll_confirmationModal',
      condition: 'rules_createRule_condition'
    },
    addEditCustomField: {
      nameInput: 'rules_addCustomField_nameInput',
      dataTypeDropdown: 'rules_addCustomField_dataTypeDropdown',
      cancelButton: 'rules_addCustomField_cancelButton',
      submitButton: 'rules_addCustomField_submitButton'
    },
    accessSwitch: {
      label: 'rules_accessSwitch_label',
      toggleButton: 'rules_accessSwitch_toggleButton'
    },
    actionButtons: {
      deleteButton: 'rules_actionButtons_deleteButton',
      viewButton: 'rules_actionButtons_viewButton',
      edit: 'rules_actionButtons_edit'
    },
    viewRule: {
      viewRulePopup: 'rules_viewRule_popUp',
      viewRuleTable: 'rules_viewRule_table',
      ruleName: 'rules_viewRule_ruleName',
      priority: 'rules_viewRule_priority',
      accountList: 'rules_viewRule_accountList'
    }
  },
  users: {
    usersPage: {
      title: 'userManagement_users_title',
      newUserButton: 'userManagement_users_newUserButton',
      searchBar: 'userManagement_users_searchBar',
      filter: 'userManagement_users_filter',
      table: 'userManagement_users_table',
      addUser: 'userManagement_users_addUser'
    },
    addEditUser: {
      title: 'userManagement_editUser_title',
      nameInput: 'userManagement_editUser_nameInput',
      emailInput: 'userManagement_editUser_emailInput',
      roleDropdown: 'userManagement_editUser_roleDropdown',
      cancelButton: 'userManagement_editUser_cancelButton',
      submitButton: 'userManagement_editUser_submitButton',
      region: 'userManagement_editUser_regionDropdown'
    },
    accessSwitch: {
      accessLabel: 'userManagement_accessSwitch_accessLabel',
      statusLabel: 'userManagement_accessSwitch_statusLabel',
      toggleButton: 'userManagement_accessSwitch_toggleButton'
    },
    actionButtons: {
      resendButton: 'userManagement_actionButtons_resendButton',
      editButton: 'userManagement_actionButtons_editButton',
      deleteButton: 'userManagement_actionButtons_deleteButton'
    }
  },
  notification: {
    toast: 'notification_toast'
  },
  courier: {
    partners: {
      title: 'courierIntegration_partners_title',
      searchBar: 'courierIntegration_partners_searchBar',
      tab: 'courierIntegration_partners_tab',
      courierPartnerCard: 'courierIntegration_partners_card',
      viewButton: 'courierIntegration_partners_view',
      addPartnerButton: 'courierIntegration_partners_addButton',
      addPartnerInput: 'courierIntegration_partners_add',
      courierPartnerAccountList:
        'courierIntegration_partners_courierPartnerAccountList',
      statusFilter: {
        label: 'courierIntegration_partners_statusFilter_label',
        dropdown: 'courierIntegration_partners_statusFilter_dropdown'
      }
    },
    partnerDetail: {
      statusSwitch: 'courierIntegration_partnerDetail_statusSwitch',
      settings: 'courierIntegration_partnerDetail_settings'
    },
    partnerAccounts: {
      title: 'courierIntegration_partnerAccounts_title',
      accountPartnerSection:
        'courierIntegration_partnerAccounts_accountPartnerSection',
      searchBar: 'courierIntegration_partnerAccounts_searchBar',
      partnerCode: 'courierIntegration_partnerAccounts_partnerCode',
      tab: 'courierIntegration_partnerAccounts_tab',
      accountCard: 'courierIntegration_partnerAccounts_accountCard',
      statusFilter: {
        label: 'courierIntegration_partnerAccounts_statusFilter_label',
        dropdown: 'courierIntegration_partnerAccounts_statusFilter_dropdown'
      }
    },
    addCourierAccount: {
      title: 'courierIntegration_addCourierAccount_title',
      orderType: 'courierIntegration_addCourierAccount_orderType',
      radioGroup: 'courierIntegration_addCourierAccount_radioGroup',
      addPartnerInput: 'courierIntegration_addCourierAccount_input',
      cancelButton: 'courierIntegration_addCourierAccount_cancelButton',
      submitButton: 'courierIntegration_addCourierAccount_submitButton'
    },
    partnerAccountDetail: {
      title: 'courierIntegration_partnerAccountDetail_title',
      lastUpdatedInfo:
        'courierIntegration_partnerAccountDetail_lastUpdatedInfo',
      accountCode: 'courierIntegration_partnerAccountDetail_accountCode',
      partnerName: 'courierIntegration_partnerAccountDetail_partnerName',
      setUp: {
        title: 'courierIntegration_partnerAccountDetail_setUp_title',
        subTitle: 'courierIntegration_partnerAccountDetail_setUp_subTitle',
        card: 'courierIntegration_partnerAccountDetail_setUp_card'
      },
      statusSwitch: {
        toggleButton: 'courierIntegration_partnerAccount_status_toggle',
        label: 'courierIntegration_partnerAccount_status_label'
      }
    },
    serviciability: {
      title: 'courierIntegration_partnerAccounts_serviciability_title',
      accountCode:
        'courierIntegration_partnerAccounts_serviciability_accountCode',
      partnerName:
        'courierIntegration_partnerAccounts_serviciability_parnerName',
      partnerCode:
        'courierIntegration_partnerAccounts_serviciability_partnerCode',
      threshold: 'courierIntegration_partnerAccounts_serviciability_threshold',
      addTimeSlot:
        'courierIntegration_partnerAccounts_serviciability_addTimeSlot',
      addThresholdBtn:
        'courierIntegration_partnerAccounts_serviciability_addThreshold',
      activeTab: 'courierIntegration_partnerAccounts_serviciability_activeTab',
      dropPincode:
        'courierIntegration_partnerAccounts_serviciability_dropPincode',
      pickupPincode:
        'courierIntegration_partnerAccounts_serviciability_pickupPincode',
      addEditTimeSlot: {
        startTime:
          'courierIntegration_partnerAccounts_serviciability_addEditTimeSlot_startTime',
        endTime:
          'courierIntegration_partnerAccounts_serviciability_addEditTimeSlot_endTime',
        statusSwitch:
          'courierIntegration_partnerAccounts_serviciability_addEditTimeSlot_statusSwitch',
        cancelBtn:
          'courierIntegration_partnerAccounts_serviciability_addEditTimeSlot_cancelBtn',
        addBtn:
          'courierIntegration_partnerAccounts_serviciability_addEditTimeSlot_addBtn'
      }
    },
    credentials: {
      title: 'courierIntegration_partnerAccounts_credentials_title',
      submitButton:
        'courierIntegration_partnerAccounts_credentials_submitButton',
      cancelButton:
        'courierIntegration_partnerAccounts_credentials_cancelButton',
      dynamicForm:
        'courierIntegration_partnerAccounts_credentials_dynamic_form_wrapper'
    },
    allocation: {
      title: 'courierIntegration_allocation_title',
      tab: 'courierIntegration_allocation_tab',
      serviceabilityCheck: {
        title: 'courierAllocation_serviceabilityCheck_title',
        radioGroup: 'courierAllocation_serviceabilityCheck_radioGroup',
        singleSearch: 'courierAllocation_serviceabilityCheck_singleSearch',
        table: 'courierAllocation_serviceabilityCheck_table',
        searchModifier: 'courierAllocation_serviceabilityCheck_searchModifier'
      },
      searchHistory: {
        searchBar: 'courierIntegration_allocation_searchHistory_searchBar',
        searchHistoryCard:
          'courierIntegration_allocation_searchHistory_searchHistoryCard',
        executedAt: 'courierIntegration_allocation_searchHistory_executedAt',
        partnerTable:
          'courierIntegration_allocation_searchHistory_partnerTable',
        executionDetailsCard:
          'courierIntegration_allocation_searchHistory_executionDetailsCard'
      },
      testRecommendation: {
        customFields: 'allocaion_testRecommendation_customFields',
        clearButton: 'allocaion_testRecommendation_clearButton',
        submitButton: 'allocaion_testRecommendation_submitButton'
      }
    },
    addressMapper: {
      title: 'courierIntegration_addressMapper_title',
      table: 'courierIntegration_addressMapper_table',
      tooltip: 'courierIntegration_addressMapper_tooltip',
      upload: {
        title: 'courierIntegration_addressMapper_upload_title',
        lastUploadInfo:
          'courierIntegration_addressMapper_upload_lastUploadInfo',
        partnerName: 'courierIntegration_addressMapper_upload_partnerName',
        partnerLogo: 'courierIntegration_addressMapper_upload_partnerLogo',
        uploadWrapper: 'courierIntegration_addressMapper_upload_uploadWrapper'
      }
    },
    statusMapping: {
      title: 'courierIntegration_statusMapping_title',
      clearAllButton: 'courierIntegration_statusMapping_clearAllButton',
      submitButton: 'courierIntegration_statusMapping_submitButton',
      clearAllConfirmationModal:
        'courierIntegration_statusMapping_clearAllConfirmationModal',
      courierPartnerStatus: {
        title:
          'courierIntegration_statusMapping_title_courierPartnerStatus_title',
        partnerDropdown:
          'courierIntegration_statusMapping_title_courierPartnerStatus_partnerDropdown',
        deliveryTypeDropdown:
          'courierIntegration_statusMapping_title_courierPartnerStatus_deliveryTypeDropdown'
      },
      partnerStatusMappingList: {
        title:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_title',
        addStatus:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_addStatus',
        tooltip:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_tooltip',
        cpStatus:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_cpStatus',
        boxatyStatus:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_boxatyStatus',
        description:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_description',
        deleteButton:
          'courierIntegration_statusMapping_title_partnerStatusMappingList_deleteButton'
      }
    }
  },
  mainHeader: {
    region: {
      regionName: 'mainHeader_region_title',
      regionDropdown: 'mainHeader_region_regionDropdown'
    },
    profile: {
      profileName: 'mainHeader_profile_title',
      profileDropdown: 'mainHeader_profile_profileDropdown'
    }
  },
  roles: {
    roleList: {
      title: 'role_roleList_title',
      createRoleBtn: 'role_roleList_createRoleBtn',
      searchBar: 'role_roleList_searchBar',
      table: 'role_roleList_table'
    },
    editRole: {
      title: 'role_createRole_title',
      entity: 'role_editRole_entity',
      roleName: 'role_editRole_roleName',
      cancelBtn: 'role_editRole_cancelBtn',
      saveBtn: 'role_editRole_saveBtn'
    },
    viewRole: {
      roleName: 'role_viewRole_roleName',
      editRole: 'role_viewRole_editRole',
      entity: 'role_viewRole_entity'
    }
  },
  regions: {
    title: 'regionManagement_regions_title',
    addRegionButton: 'regionManagement_regions_addRegionButton',
    searchBar: 'regionManagement_regions_searchBar',
    table: 'regionManagement_regions_table',
    addRegionModal: 'regionManagement_regions_addRegionModal',
    editRegionModal: 'regionManagement_regions_editRegionModal',
    addRegion: {
      title: 'regionManagement_addEditRegion_title',
      nameInput: 'regionManagement_addEditRegion_nameInput',
      codeInput: 'regionManagement_addEditRegion_codeInput',
      cancelButton: 'regionManagement_addEditRegion_cancelButton',
      submitButton: 'regionManagement_addEditRegion_submitButton',
      timeZone: 'regionManagement_addEditRegion_timeZone',
      consolidateInput: 'regionManagement_addEditRegion_consolidateInput'
    },
    editRegion: {
      title: 'regionManagement_editRegion_title',
      nameInput: 'regionManagement_editRegion_nameInput',
      cancelButton: 'regionManagement_editRegion_cancelButton',
      submitButton: 'regionManagement_editRegion_submitButton'
    },
    actions: {
      editButton: 'regionManagement_actions_editButton'
    }
  },
  myAccount: {
    myAccountPage: {
      title: 'accountManagement_myAccount_title',
      profileLogoText: 'accountManagement_myAccount_profileLogoText',
      actionButton: {
        editButton: 'accountManagement_myAccount_edit'
      },
      userName: 'accountManagement_myAccount_userName',
      email: 'accountManagement_myAccount_email',
      rolesHeading: 'accountManagement_myAccount_rolesHeading',
      userRole: 'accountManagement_myAccount_userRole',
      regionsHeading: 'accountManagement_myAccount_regionsHeading',
      editUserDetailsModal: 'accountManagement_myAccount_editUserDetailsModal',
      entity: 'accountManagement_myAccount_entity'
    },
    editUserDetails: {
      title: 'accountManagement_editUserDetails_title',
      nameInput: 'accountManagement_editUserDetails_nameInput',
      cancelButton: 'accountManagement_editUserDetails_cancelButton',
      submitButton: 'accountManagement_editUserDetails_submitButton'
    },
    selectDefaultRegion: {
      title: 'accountManagement_selectDefaultRegion_title',
      entity: 'accountManagement_selectDefaultRegion_entity',
      submitButton: 'accountManagement_selectDefaultRegion_submitButton'
    }
  },
  webhook: {
    title: 'webhook_title',
    tab: 'webhook_tab',
    list: 'webhook_list',
    retrigger: 'webhook_retrigger',
    addWebhookButton: 'webhook_addWebhook_button',
    addWebhook: {
      title: 'webhook_title',
      nameInput: 'webhook_name',
      urlInput: 'webhook_url',
      version: 'webhook_version',
      versionTitle: 'webhook_version_title',
      statusSubTitle: 'webhook_status_title',
      eventSelector: 'webhook_status_event_selector',
      eventEntity: 'webhook_event_entity',
      authConfigTitle: 'webhook_auth_config_title',
      authConfigTooltip: 'webhook_auth_config_tooltip',
      authConfigDropdown: 'webhook_auth_config_dropdown',
      cancelButton: 'webhook_cancel_button',
      submitButton: 'webhook_submit_button',
      webhookFormField: 'webhook_form_field',
      nameUrlCard: 'webhook_name_url_card',
      statusesCard: 'webhook_statuses_card',
      authConfigCard: 'webhook_auth_config_card'
    },
    statusSwitch: {
      toggleButton: 'webhook_status_toggle',
      accessLabel: 'webhook_status_label'
    },
    actionButtons: {
      deleteButton: 'rules_actionButtons_deleteButton',
      viewButton: 'rules_actionButtons_viewButton',
      edit: 'rules_actionButtons_edit'
    },
    viewWebhook: {
      viewWebhookPopup: 'rules_viewWebhook_popUp'
    }
  },
  warehouse: {
    accessSwitch: {
      toggleButton: 'warehouse_accessSwicth_toggleButton',
      label: 'warehouse_AccessSwitch_label'
    },
    actionButton: {
      edit: 'warehouse_actionButton_edit',
      view: 'warehouse_actionButton_view',
      details_popup: 'warehouse_actionButton_detailsPopup'
    },
    viewDetails: {
      name: 'warehouse_viewDetails_warehouseName',
      status: 'warehouse_viewDetails_status',
      email: 'warehouse_viewDetails_email',
      storeID: 'warehouse_viewDetails_storeId',
      areaCode: 'warehouse_viewDetails_areaCode',
      phone: 'warehouse_viewDetails_phoneNumber',
      city: 'warehouse_viewDetails_city',
      countryCode: 'warehouse_viewDetails_countryCode',
      addressHeader: 'warehouse_viewDetails_addressHeader',
      address: 'warehouse_viewDetails_address',
      consolidation: 'warehouse_viewDetails_consolidation'
    },
    create: {
      title: 'warehouse_create_title',
      name: 'warehouse_create_name',
      phone: 'warehouse_create_phone',
      email: 'warehouse_create_email',
      storeID: 'warehouse_create_storeID',
      city: 'warehouse_create_city',
      areaCode: 'warehouse_create_areaCode',
      country: 'warehouse_create_country',
      address: 'warehouse_create_address',
      orderConsolidationText: 'warehouse_create_orderConsolidationText',
      orderConsolidationRadioButton:
        'warehouse_create_orderConsolidationRadioButton',
      clearAllButton: 'warehouse_create_clearAllButton',
      submitButton: 'warehouse_create_submitButton'
    },
    list: {
      title: 'warehouse_list_title',
      create_button: 'warehouse_list_createButton',
      statusFilterText: 'warehouse_list_statusFIlterText',
      dropdown: 'warehouse_list_status_filter_dropdown',
      table: 'warehouse_list_table'
    }
  },
  getlog: {
    title: 'getlog_title',
    tab: 'getlog_tab',
    webhookLog: {
      searchBar: 'getlog_webhookLog_searchBar',
      table: 'getlog_webhookLog_table',
      refresh_button: 'getlog_webhookLog_refresh_button',
      noResult_image: 'getlog_webhooklog_noResult_image',
      actionButton: {
        view: 'getlog_webhookLog_actionButton_view',
        copy: 'getlog_webhookLog_actionButton_copy',
        jsonModal: 'getlog_webhookLog_actionButton_jsonModal'
      },
      jsonDisplayModal: {
        title: 'getlog_webhookLog_jsonDisplayModal_title',
        jsonConvertor: 'getlog_webhookLog_jsonDisplayModal_jsonConvertor'
      }
    },
    integrationLog: {
      searchBar: 'getlog_integrationLog_searchBar',
      logCard: 'getlog_integrationLog_logCard',
      jsonDetailCard: 'getlog_integrationLog_jsonDetailCard',
      apiDetailsCard: {
        eventName: 'getlog_integrationLog_apiDetailsCard_eventName',
        executedAt: 'getlog_integrationLog_apiDetailsCard_executedAt',
        view: 'getlog_integrationLog_apiDetailsCard_view',
        copy: 'getlog_integrationLog_apiDetailsCard_copy'
      },
      jsonDisplay: {
        eventName: 'getlog_integrationLog_jsonDisplay_eventName',
        closeIcon: 'getlog_integrationLog_jsonDisplay_closeIcon',
        jsonConvertor: 'getlog_integrationLog_jsonDIspkay_jsonConvertor'
      }
    }
  },
  dashboard: {
    title: 'dashboard_overview_title',
    tab: 'dashboard_overview_title',
    overview: {}
  }
};

export default testId;
