import { Navigate, RouteObject } from 'react-router-dom';

import { MainLayout } from '@components';
import { HOME, ORDERS } from '@constants/routes';

import AuthRoutes from '@features/authentication/routes';
import AccountSettingsRoutes from '@features/regions/routes';
import RuleRoutes from '@features/rules/routes';
import OrderRoutes from '@features/orders/routes';
import CourierRoutes from '@features/couriers/routes';
import UserRoutes from '@features/users/routes';
import RolesRoutes from '@features/roles/routes';
import WebhookRoutes from '@features/webhooks/routes';
import WarehouseRoutes from '@features/warehouse/routes';
import GetLogRoutes from '@features/getLog/routes';
import DashboardRoutes from '@features/dashboard/routes';
import AccessDeniedRoutes from '@features/components/access-denied-page/routes';

const ProtectedRoutes = () => {
  const hasViewGlobalOrdersPermission = JSON.parse(
    localStorage.getItem('hasViewGlobalOrdersPermission') as string
  );

  const protectedRoutes: RouteObject[] = [
    AuthRoutes,
    {
      path: HOME,
      element: (
        <Navigate
          to={
            hasViewGlobalOrdersPermission ? ORDERS.GLOBAL_ORDERS : ORDERS.ORDERS
          }
        />
      ) // Re-route from '/' to '/order-management'
    },
    {
      element: <MainLayout />, // Outlet in MainLayout will be replaced by matching element from below
      children: [
        ...AccountSettingsRoutes,
        RuleRoutes,
        OrderRoutes,
        ...CourierRoutes,
        ...UserRoutes,
        ...RolesRoutes,
        WebhookRoutes,
        ...WarehouseRoutes,
        ...GetLogRoutes,
        DashboardRoutes,
        AccessDeniedRoutes
      ]
    },
    { path: '*', element: <Navigate to={HOME} /> }
  ];

  return protectedRoutes;
};

export default ProtectedRoutes;
