import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ACCESS_DENIED } from '@constants/routes';

const useCheckPermission = () => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: any) => state.user);

  const checkPermission = (permission?: string[]) => {
    const permissions = currentUser?.role?.permissions?.map((p: any) => p.name);
    return !!permission?.some((p) => permissions?.includes(p));
  };

  const checkPageAccess = (permission: string[]) => {
    if (!checkPermission(permission)) {
      navigate(ACCESS_DENIED);
      return false;
    }
    return true;
  };

  return { checkPermission, checkPageAccess };
};

export default useCheckPermission;
