import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from '@components';
import { ToastType } from '@components/toast/Toast';
import testId from '@constants/testId';

const Notification = () => {
  const { alerts } = useSelector((state: any) => state.notification);
  const [alert, setAlert] = useState({
    type: '',
    message: '',
    description: ''
  });
  const [show, setShow] = useState(false);

  const hideToast = () => setShow(false);

  const showToast = () => setShow(true);

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      showToast();
      setTimeout(() => {
        hideToast();
      }, 3000);
    }
  }, [alerts]);

  return show ? (
    <div className="fixed inset-x-0 top-8 z-50 flex justify-center">
      <Toast
        type={alert.type as ToastType}
        title={alert.message}
        description={alert.description}
        data-testid={testId.notification.toast}
      />
    </div>
  ) : null;
};

export default Notification;
