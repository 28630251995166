import { Scalars, Maybe } from '@types';

export enum FileType {
  AWB_FORWARD_CREATE = 'AWB_FORWARD_CREATE',
  AWB_RETURN_CREATE = 'AWB_RETURN_CREATE',
  DROP_PINCODE = 'DROP_PINCODE',
  PICKUP_PINCODE = 'PICKUP_PINCODE',
  ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE',
  ADDRESS_MAPPER = 'ADDRESS_MAPPER_UPLOAD'
}

export enum ServiceType {
  DROP_PINCODE = 'DROP',
  PICKUP_PINCODE = 'PICKUP'
}

export enum FileUploadStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export type FileUploadedType = {
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  deletedAt: Maybe<Scalars['Date']>;
  errorReason: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fileName: Scalars['String'];
  fileType: FileType;
  entityId: Scalars['ID'];
  status: FileUploadStatus;
  successCount: Scalars['Int'];
  tenantId: Scalars['ID'];
  updatedAt: Maybe<Scalars['Date']>;
  updatedBy: Maybe<Scalars['ID']>;
};

export type SampleFileUrlResponse = {
  expiryInMinutes: Scalars['Int'];
  url: Scalars['String'];
};

export type UploadFileDetailsResponse = {
  fileId: Scalars['ID'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type FileDetails = {
  name: Scalars['String'];
  extension: Scalars['String'];
  size: Scalars['String'];
};

export type UploadHistoryTitlesType = {
  results: Scalars['String'];
};
