import { Scalars } from '@types';

export enum DataType {
  FLOAT = 'FLOAT',
  INT = 'INT',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN'
}

export enum FieldOperatorEnum {
  CONTAINS = 'CONTAINS',
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  IN = 'IN',
  LT = 'LT',
  LTE = 'LTE',
  NEG = 'NEG'
}

export enum FieldTypeEnum {
  PREDEFINED = 'PREDEFINED',
  CUSTOM = 'CUSTOM'
}

export enum BooleanEnum {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}
export type FieldOperator = {
  entityId: Scalars['ID'];
  operator: FieldOperatorEnum;
};

export type FieldType = {
  fieldOperators: FieldOperator[];
  entityId: Scalars['ID'];
  type: DataType;
};

export type Field = {
  dataType: DataType;
  displayName: Scalars['String'];
  fieldType: FieldTypeEnum;
  entityId: Scalars['ID'];
  name: Scalars['String'];
  createdAt?: Scalars['Date'];
  deletedAt?: Scalars['Date'];
  updatedAt?: Scalars['Date'];
  listOfValues?: Scalars['String'][];
  isMandatory?: Scalars['Boolean'];
};
