import { MutableRefObject, ReactNode, useRef } from 'react';
import clsx from 'clsx';

import Button from '@components/button/Button';
import ClickOutside from '@utils/clickOutside';
import { useTheme } from '@contexts/theme';

import Icon from '../../components/icon/Icon';

interface ModalProps {
  onCancel: () => void;
  isOpen: boolean;
  className?: string;
  children: ReactNode;
  isClickOutsideAllowed?: boolean;
  isCloseIconRequired?: boolean;
}
const Modal = ({
  onCancel,
  isOpen,
  className = '',
  children,
  isClickOutsideAllowed = true,
  isCloseIconRequired = true,
  ...props
}: ModalProps) => {
  const modalStyle = clsx({
    'fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center transition-opacity duration-300 ease-in-out':
      true,
    'opacity-100': isOpen,
    'hidden opacity-0': !isOpen
  });

  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { colors } = useTheme();

  const clickOutside = () => {
    if (isClickOutsideAllowed)
      ClickOutside({ ref: modalRef, handler: onCancel });
  };
  clickOutside();

  return (
    <div className={modalStyle} {...props}>
      {/* Modal Overlay */}
      <div className="absolute h-full w-full bg-black opacity-30" />

      {/* Modal Main Content */}
      <div
        className={clsx(
          'relative z-40 mx-auto rounded-10px bg-white  shadow-lg',
          className
        )}
        ref={modalRef}
      >
        {children}
        {/* Close icon */}
        {isCloseIconRequired && (
          <Button
            variant="icon"
            size="none"
            handleClick={onCancel}
            className="absolute right-2 top-2 mr-2 mt-2"
          >
            <Icon name="cross" size="extrasmall" stroke={colors.background90} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Modal;
