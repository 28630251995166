import { GETLOG } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const GetLog = lazy(() => import('@features/getLog/pages/GetLog'));

export const GetLogRoutes: RouteObject[] = [
  {
    path: GETLOG.GETLOG_MANAGEMENT,
    children: [{ path: '', element: <GetLog /> }]
  }
];

export default GetLogRoutes;
