module.exports = {
  '1px': '1px',
  '7px': '7px',
  '10px': '10px',
  '50px': '50px'
};

// Note: other available radius as per style guide
// 'sm': '4px',
// 'md': '6px',
// '3xl': '24px'
