import clsx from 'clsx';
import { useState, useEffect } from 'react';

import { Button, Dropdown, Input, MultiSelectDropdown } from '@components';
import Modal from '@layouts/modal/Modal';
import { Region, Role, ValidationError } from '@types';
import { validateWithRegexp } from '@utils/generic';
import { emailRegex, nameRegex } from '@constants/regexps';
import useTranslate from '@hooks/intl';
import testId from '@constants/testId';
import { useLazyGetAllRolesFilterQuery } from '@features/roles/queries/RoleQuery';
import { useGetAllRegionsFilterQuery } from '@features/regions/queries/RegionQuery';

type Option = { key: string; value: string; displayName: string };
interface AddUserProps {
  open: boolean;
  handleCancel: () => void;
  isAddingUser?: boolean;
  addUser: (
    name: string,
    role: string,
    email: string,
    regionIds: string[]
  ) => void;
}

const AddUser = ({
  open,
  addUser,
  handleCancel,
  isAddingUser
}: AddUserProps) => {
  const [getAllRoles] = useLazyGetAllRolesFilterQuery();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [role, setRole] = useState<Role>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [error, setError] = useState<ValidationError>({});
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([]);

  const { data: regionList } = useGetAllRegionsFilterQuery('');

  const regionListOptions = regionList?.map((region: Region) => ({
    key: region.entityId,
    value: region.regionCode,
    displayName: region.regionName,
    displayCode: region.regionCode
  }));
  const translate = useTranslate();

  const options = roles.map((roleObj) => ({
    key: roleObj.entityId,
    value: roleObj.name,
    displayName: roleObj.name
  }));

  const onCancel = () => {
    handleCancel();
  };

  const onSubmit = () => {
    const err: ValidationError = {};
    const regionIds = selectedRegions?.map((region) => region.key);
    if (name?.trim()?.length > 50)
      err.name = translate('users.addUser.name_max_char');
    else if (!validateWithRegexp(nameRegex, name))
      err.name = translate('users.addUser.name_error');
    else if (name.trim().length <= 1)
      err.name = translate('users.addUser.name_min_char');
    if (!validateWithRegexp(emailRegex, email))
      err.email = translate('users.addUser.email_error');
    if (!regionIds.length) err.region = translate('users.addUser.region_error');
    if (!role) err.role = translate('users.addUser.role_error');

    if (Object.keys(err).length > 0) setError(err);
    else addUser(name.trim(), role?.entityId || '', email, regionIds);
  };

  const handleGetAllRoles = async () => {
    const res = await getAllRoles('');
    if (res.data) setRoles(res.data);
  };

  useEffect(() => {
    handleGetAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={open} onCancel={handleCancel} isClickOutsideAllowed={false}>
      <div
        className="text-body-base-md py-4 px-6"
        data-testid={testId.users.addEditUser.title}
      >
        {translate('users.addUser.title')}
      </div>
      <hr className="mb-2" />

      <div className="w-[540px] px-6 pt-8">
        <Input
          label={translate('users.addUser.name')}
          name="addNames"
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
            setError({ ...error, name: '' });
          }}
          error={!!error.name}
          errorMessage={error.name}
          maxLength={50}
          data-testid={testId.users.addEditUser.nameInput}
        />
        <Input
          label={translate('users.addUser.email')}
          name="addEmail"
          type="text"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setError({ ...error, email: '' });
          }}
          className="mt-6"
          error={!!error.email}
          errorMessage={error.email}
          maxLength={50}
          data-testid={testId.users.addEditUser.emailInput}
        />
        <div className="mt-6 flex gap-4">
          <Dropdown
            title="Role"
            type="floating"
            dropdownStyle="w-1/2"
            options={options}
            selectedOption={options.find(
              (option) => option.key === (role?.entityId || '')
            )}
            onClick={(option) => {
              setRole(
                roles.find((roleObj) => roleObj.entityId === option.key) as Role
              );
              setError({ ...error, role: '' });
            }}
            error={!!error.role}
            errorMessage={error.role}
            testId={testId.users.addEditUser.roleDropdown}
            errorStyle="absolute"
          />
          <MultiSelectDropdown
            options={regionListOptions}
            onSelect={(regionsSelected) => {
              setSelectedRegions(regionsSelected);
              setError({ ...error, region: '' });
            }}
            selectedOptions={selectedRegions}
            className="w-1/2"
            multiSelectDropdownStyle="h-full"
            dropdownWidthStyle="w-full"
            placeholder={translate('users.addUser.region')}
            type="chip"
            error={!!error.region}
            errorMessage={error.region}
            testId={testId.users.addEditUser.region}
            errorStyle="absolute"
            chipStyle="w-fit truncate max-w-[125px]"
            selectAllRequired
          />
        </div>
        <div
          className={clsx(
            'flex pb-4',
            !!error.role || !!error.region ? 'mt-10' : 'mt-7'
          )}
        >
          <Button
            label={translate('users.addUser.cancel_button')}
            handleClick={onCancel}
            variant="outline"
            data-testid={testId.users.addEditUser.cancelButton}
          />
          <Button
            label={translate('users.addUser.submit_button')}
            handleClick={onSubmit}
            variant="primary"
            className="ml-3"
            data-testid={testId.users.addEditUser.submitButton}
            loading={isAddingUser}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddUser;
