import { useEffect, RefObject } from 'react';

type ClickOutsideProps = {
  ref: RefObject<HTMLElement>;
  handler: () => void;
};

const ClickOutside = (props: ClickOutsideProps) => {
  const { ref, handler } = props;
  const listener = (event: any) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    handler();
  };
  useEffect(() => {
    document.addEventListener('click', listener, true);

    return () => {
      document.removeEventListener('click', listener, true);
    };
  });
};

export default ClickOutside;
