import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Region } from '@types';

const initialState = {
  currentUserRegions: [] as Region[],
  currentRegion: {} as Region
};

export type RegionState = {
  currentUserRegions: Region[];
  currentRegion: Region;
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegions: (
      state,
      { payload: { regions } }: PayloadAction<{ regions: Region[] }>
    ) => ({
      ...state,
      currentUserRegions: regions
    }),
    setCurrentRegion: (
      state,
      { payload: { region } }: PayloadAction<{ region: Region }>
    ) => ({
      ...state,
      currentRegion: region
    })
  }
});
export const { setRegions, setCurrentRegion } = regionSlice.actions;
export default regionSlice.reducer;
