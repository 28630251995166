import { DateRange, Range, Calendar as DatePicker } from 'react-date-range';
import { useState, useEffect } from 'react';
import format from 'date-fns/format';
import Icon from '../icon/Icon';
import './CalendarStyle.scss';

interface CalendarProps {
  dateRange?: Date[];
  handleChange: (date: Date[]) => void;
  isDatePicker?: boolean;
}

const Calendar = ({ dateRange, handleChange, isDatePicker }: CalendarProps) => {
  const [dateSelected, setDateSelected] = useState<Date>();

  useEffect(() => {
    if (dateRange && dateRange[0]) {
      setDateSelected(dateRange[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);
  const rangeInput = {
    startDate: dateRange && dateRange[0],
    endDate: dateRange && dateRange[1],
    key: 'selection'
  };
  const onChange = (newDate: { [key: string]: Range }) => {
    const {
      selection: { startDate, endDate }
    } = newDate;
    if (startDate && endDate) {
      handleChange([startDate, endDate]);
    }
  };

  const getDate = (date?: Date) =>
    date ? `${format(date, 'dd/MM/yyyy')}` : new Date();

  const onDateSelected = (dateSel: Date) => {
    setDateSelected(dateSel);
    handleChange([dateSel, dateSel]);
  };

  return (
    <div className="m-3 w-72 flex-col">
      {isDatePicker ? (
        <DatePicker
          date={dateSelected}
          onChange={onDateSelected}
          showMonthAndYearPickers={false}
        />
      ) : (
        <DateRange
          onChange={onChange}
          editableDateInputs
          moveRangeOnFirstSelection={false}
          ranges={[rangeInput]}
          showMonthAndYearPickers={false}
          weekdayDisplayFormat="EEEEEE"
          weekStartsOn={1}
        />
      )}

      <div className=" flex h-10 w-full rounded-md border-2 border-solid border-background50 px-1 pt-1.5 text-center">
        <div className="text-body-base pl-1.5">
          {`${getDate(rangeInput.startDate)}`}
        </div>
        <Icon name="side-vector" size="large" className="ml-7 mr-2.5 pt-0.5" />
        <div className="text-body-base">
          {rangeInput.endDate && `${format(rangeInput.endDate, 'dd/MM/yyyy')}`}{' '}
        </div>
        <Icon name="calendar" size="large" className="my-4 ml-5 mt-0.5" />
      </div>
    </div>
  );
};

export default Calendar;
