import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@contexts/theme';
import Icon from '../icon/Icon';
import useBreadcrumbs from './useBreadcrumbs';

const Breadcrumbs = () => {
  const crumbs = useBreadcrumbs();
  const { colors } = useTheme();

  return (
    <div className="text-caption flex items-center bg-white px-10 pt-6 pb-3">
      {crumbs.map(({ name, path }, index) =>
        index + 1 === crumbs.length ? (
          <span key={name} className="text-background70">
            {name}
          </span>
        ) : (
          <Fragment key={name}>
            <Link
              to={path}
              className="text-primary"
              // onClick={(e) => handleClick(e, path)}
            >
              {name}
            </Link>
            <Icon
              name="chevron-right"
              className="mx-1"
              size="small"
              stroke={colors.background90}
            />
          </Fragment>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
