import clsx from 'clsx';

import { useTheme } from '@contexts/theme';

import Button from '../button/Button';
import Icon, { IconSize } from '../icon/Icon';
import CopyText from '../copy-text/CopyText';
import TextComponent from '../text-component/TextComponent';

export type ChipTypes =
  | 'DEFAULT'
  | 'PRIMARY'
  | 'SECONDARY'
  | 'DISABLED'
  | 'ERROR';

const labelStyles = {
  PRIMARY: 'text-body-base-bold text-primary',
  SECONDARY: 'text-xs',
  DEFAULT: 'text-sm text-black',
  DISABLED: 'text-xs',
  ERROR: 'text-xs'
};

interface ChipProps {
  label: string;
  onRemove?: () => void;
  className?: string;
  icon?: string;
  closeButtonSize?: IconSize;
  chipType?: ChipTypes;
  labelStyle?: string;
  isCopy?: boolean;
  textToCopy?: string;
  truncateText?: boolean;
}

const Chip: React.FC<ChipProps> = ({
  label,
  onRemove,
  className,
  icon,
  closeButtonSize = 'small',
  chipType = 'PRIMARY',
  labelStyle,
  isCopy = false,
  textToCopy = '',
  truncateText = false
}) => {
  const { colors } = useTheme();
  const chipClassName = `flex items-center justify-between gap-1 rounded-10px py-1 px-2 text-base ${className}`;
  const getChipColor = () => {
    switch (chipType) {
      case 'DEFAULT':
        return 'bg-white text-black border border-background50';
      case 'PRIMARY':
        return 'bg-primary10 text-primary border border-primary';
      case 'DISABLED':
        return 'bg-background10 text-background50';
      case 'ERROR':
        return 'bg-error10 text-error border border-error';
      case 'SECONDARY':
        return 'bg-secondary10 text-black';
      default:
        return '';
    }
  };

  const getIconColor = () => {
    switch (chipType) {
      case 'DEFAULT':
        return colors.black;
      case 'PRIMARY':
        return colors.primary;
      case 'DISABLED':
        return colors.background50;
      case 'ERROR':
        return colors.error;
      case 'SECONDARY':
        return colors.black;
      default:
        return '';
    }
  };

  return (
    <div
      className={clsx(`${isCopy && 'relative'}`, chipClassName, getChipColor())}
    >
      {icon && <Icon name={icon} size="small" />}
      {truncateText ? (
        <TextComponent
          label={label}
          testId={`chip_${label}`}
          className={clsx('truncate', labelStyle, labelStyles[chipType])}
        />
      ) : (
        <span className={clsx(labelStyle, labelStyles[chipType])}>{label}</span>
      )}

      {isCopy && (
        <span className="group/tooltip mr-2">
          <CopyText
            textToCopy={textToCopy}
            iconName="copy-1"
            fill="transparent"
          />
        </span>
      )}
      {onRemove && (
        <Button
          handleClick={onRemove}
          variant="icon"
          disabled={chipType === 'DISABLED'}
          className="hover:bg-transparent"
        >
          <Icon name="cross" size={closeButtonSize} stroke={getIconColor()} />
        </Button>
      )}
    </div>
  );
};

export default Chip;
