import { Scalars } from '@types';

const useChipsToDisplay = (
  data: Scalars['String'][],
  maxLength: number,
  bufferLength?: number
) => {
  let currentChipLength = 0;
  let indexOfVisibleChip = 0;

  bufferLength = bufferLength || 3;
  indexOfVisibleChip = data?.findIndex((item) => {
    currentChipLength += item.length + (bufferLength as number);
    return currentChipLength > maxLength;
  });

  if (data?.length === 1) {
    return {
      chipLength: 1,
      showMore: false
    };
  }
  switch (indexOfVisibleChip) {
    case -1:
      return {
        chipLength: data?.length,
        showMore: false
      };
    case 0:
      return {
        chipLength: indexOfVisibleChip + 1,
        showMore: true
      };
    default:
      return {
        chipLength: indexOfVisibleChip,
        showMore: true
      };
  }
};

export default useChipsToDisplay;
