import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PARTNER_ACCOUNT_TYPES } from '../constants';

const initialState = {
  partnerAcctType: PARTNER_ACCOUNT_TYPES.FORWARD,
  cappingUnsavedData: {}
};

interface CappingData {
  [key: string]: number;
}

export const courierPartnerSlice = createSlice({
  name: 'courierPartner',
  initialState,
  reducers: {
    setPartnerAccountType: (
      state,
      { payload: { accountType } }: PayloadAction<{ accountType: string }>
    ) => ({
      ...state,
      partnerAcctType: accountType
    }),
    addCappingDataUnsaved: (
      state,
      { payload: { data } }: PayloadAction<{ data: CappingData }>
    ) => ({
      ...state,
      cappingUnsavedData: { ...state.cappingUnsavedData, ...data }
    }),
    setCappingDataUnsaved: (
      state,
      { payload: { data } }: PayloadAction<{ data: CappingData }>
    ) => ({
      ...state,
      cappingUnsavedData: { ...data }
    })
  }
});
export const {
  setPartnerAccountType,
  addCappingDataUnsaved,
  setCappingDataUnsaved
} = courierPartnerSlice.actions;
export default courierPartnerSlice.reducer;
