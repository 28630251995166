import useTranslate from '@hooks/intl';
import { useTheme } from '@contexts/theme';
import { Scalars } from '@types';
import { useState } from 'react';
import Button from '@components/button/Button';
import Icon, { IconSize } from '@components/icon/Icon';
import { Tooltip } from '@components/tool-tip/Tooltip';

interface CopyTextProps {
  textToCopy: Scalars['String'];
  className?: Scalars['String'];
  toolTipText?: Scalars['String'];
  label?: Scalars['String'];
  labelStyle?: Scalars['String'];
  fill?: Scalars['String'];
  iconSize?: IconSize;
  iconName?: Scalars['String'];
  stroke?: Scalars['String'];
}
const CopyText = ({
  textToCopy,
  className,
  toolTipText,
  label,
  labelStyle = '',
  fill,
  iconSize = 'large',
  iconName = 'copy',
  stroke
}: CopyTextProps) => {
  const translate = useTranslate();
  const { colors } = useTheme();
  const [isCopied, setCopied] = useState(false);
  const onCopyClick = (event: Event) => {
    event.stopPropagation();
    // only work in https/localhost not in http alias domain
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    }
  };

  return (
    <Button
      variant="transparent"
      handleClick={onCopyClick}
      className={className}
      size="none"
    >
      <div className="group/tooltip">
        <div className="flex items-center">
          <Icon
            name={iconName}
            fill={fill || colors.background90}
            size={iconSize}
            stroke={stroke || colors.grey}
            className={label ? 'mr-2' : ''}
          />
          {label && <p className={labelStyle}>{label}</p>}
        </div>
        <Tooltip
          text={
            isCopied
              ? translate('component.copyText.copied')
              : toolTipText || translate('component.copyText.copy')
          }
          className={label && 'left-1/2'}
        />
      </div>
    </Button>
  );
};
export default CopyText;
