import { ReactNode } from 'react';

import Modal from './Modal';
import Button, { ButtonLabel } from '../../components/button/Button';

interface AlertModalProps {
  open: boolean;
  title: string;
  message?: string | ReactNode;
  alertButtonlabel: string;
  handleCancel: () => void;
}
const AlertModal = ({
  open,
  title,
  message,
  alertButtonlabel,
  handleCancel
}: AlertModalProps) => (
  <Modal isOpen={open} onCancel={handleCancel}>
    <div className="min-w-[416px] max-w-md py-6 pl-6 pr-9">
      <div className="text-body-base-semi mb-1.5 whitespace-pre-wrap break-normal pb-4">
        {title}
      </div>
      <div className="text-body whitespace-pre-wrap break-words pb-8 text-background50">
        {message}
      </div>
      <div className="flex">
        <Button handleClick={handleCancel} size="none" variant="primary">
          <ButtonLabel
            variant="primary"
            label={alertButtonlabel}
            className="px-4 py-2"
          />
        </Button>
      </div>
    </div>
  </Modal>
);

export default AlertModal;
