import { useEffect, useRef } from 'react';

import { BarChartProps, ToolTipListProps } from './types';
import { drawBarChartToolTip, getMaxValue, getUpperLimit } from './helpers';
import ChartLegendText from './ChartLegendText';
import './styles.scss';
import {
  drawChartCategories,
  drawGraphGrid,
  drawRect,
  getTooltipValues
} from './helpers/barChart';

const BarChart = ({
  chartData,
  tooltipHeaders,
  showSecondaryValue = true,
  barChartData
}: BarChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const tooltipCanvasRef = useRef<HTMLCanvasElement>(null);
  const toolTipList = useRef<ToolTipListProps[]>([]);
  const pixelToDraw = 1;
  const contextPadding = 50;

  const maxValue = getMaxValue(chartData, showSecondaryValue);
  const maxCapping = getUpperLimit(maxValue);

  const {
    primaryText,
    primaryColor,
    secondaryText,
    secondaryColor,
    showLegendText = false,
    rectWidth = 24,
    rectMargin = 100,
    verticalTitle = ''
  } = barChartData;

  const drawFixedBarChart = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height - 50;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        const chartHeight = ctxHeight - contextPadding * 2;

        drawGraphGrid(
          context,
          chartHeight,
          contextPadding,
          ctxWidth,
          maxCapping,
          verticalTitle
        );

        chartData.map((item, index) => {
          const xStart = index * rectMargin + contextPadding * 2;
          const heightFromTop = chartHeight + contextPadding;
          drawChartCategories(
            context,
            xStart,
            item.name,
            rectWidth,
            rectMargin,
            heightFromTop
          );

          if (item.value) {
            const startPoint =
              heightFromTop - (item.value * chartHeight) / maxCapping;

            drawRect(
              context,
              primaryColor,
              xStart,
              startPoint,
              rectWidth,
              (item.value * chartHeight) / maxCapping
            );
          }
          if (item.secondaryValue && secondaryColor) {
            const startPointSecondary =
              heightFromTop - (item.secondaryValue * chartHeight) / maxCapping;
            drawRect(
              context,
              secondaryColor,
              xStart + rectWidth / 2,
              startPointSecondary,
              rectWidth,
              (item.secondaryValue * chartHeight) / maxCapping
            );
          }
          return null;
        });
      }
    }
  };

  const drawAnimatedBarChart = () => {
    const canvas = canvasRef.current;
    let animationFrameId: any;
    if (canvas) {
      canvas.style.width = '100%';
      canvas.width = canvas.offsetWidth;
      const context = canvas.getContext('2d');
      if (context) {
        toolTipList.current = [];
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height - 50;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        const chartHeight = ctxHeight - contextPadding * 2;

        drawGraphGrid(
          context,
          chartHeight,
          contextPadding,
          ctxWidth,
          maxCapping,
          verticalTitle
        );

        toolTipList.current = getTooltipValues(
          chartData,
          tooltipHeaders,
          rectMargin,
          contextPadding,
          chartHeight,
          maxCapping,
          rectWidth,
          showSecondaryValue,
          secondaryColor
        );

        chartData.map((item, index) => {
          let frameCount = 0;
          const totalFrame = 600;
          const xStart = index * rectMargin + contextPadding * 2;
          const heightFromTop = chartHeight + contextPadding;

          drawChartCategories(
            context,
            xStart,
            item.name,
            rectWidth,
            rectMargin,
            heightFromTop
          );

          // Barchart Value Rectangle
          // Timeout will use when 2 consecutive Bars are drawing on same path
          const render = () => {
            [...Array(15)].forEach(() => {
              const barHeight = (item.value * chartHeight) / maxCapping;
              const startPoint =
                heightFromTop - barHeight * (frameCount / totalFrame);
              setTimeout(
                () =>
                  drawRect(
                    context,
                    primaryColor,
                    xStart,
                    frameCount === totalFrame
                      ? startPoint
                      : startPoint - pixelToDraw,
                    rectWidth,
                    pixelToDraw
                  ),
                item.secondaryValue && item.secondaryValue > item.value
                  ? 100
                  : 0
              );
              if (showSecondaryValue && secondaryColor && item.secondaryValue) {
                const secondaryBarHeight =
                  (item.secondaryValue * chartHeight) / maxCapping;
                const startPointSecondary =
                  heightFromTop -
                  secondaryBarHeight * (frameCount / totalFrame);

                setTimeout(
                  () => {
                    drawRect(
                      context,
                      secondaryColor,
                      xStart + rectWidth / 2,
                      startPointSecondary,
                      rectWidth,
                      pixelToDraw
                    );
                  },
                  item.secondaryValue > item.value ? 0 : 100
                );
              }
              frameCount += 1;
            });

            if (frameCount < totalFrame) {
              animationFrameId = window.requestAnimationFrame(() => render());
            }
          };
          if (item.value) requestAnimationFrame(() => render());
          return null;
        });
      }
    }
    return animationFrameId;
  };

  const handleMouseMove = (event: any) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const tooltipCanvas = tooltipCanvasRef.current;
      if (tooltipCanvas)
        drawBarChartToolTip(toolTipList.current, tooltipCanvas, mouseX, mouseY);
    }
  };

  const width = secondaryText
    ? chartData.length * rectMargin + 100
    : chartData.length * rectMargin + 100;

  useEffect(() => {
    const animationFrameId = drawAnimatedBarChart();
    setTimeout(() => drawFixedBarChart(), 2000);
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(chartData)]);

  return (
    <div
      className="bar-chart-modal relative flex w-full overflow-y-hidden overflow-x-scroll"
      style={{ height: showLegendText ? 500 : 450 }}
    >
      <div className="absolute h-[450px] w-full" style={{ minWidth: width }}>
        <canvas
          ref={canvasRef}
          height={450}
          onMouseMove={(e) => handleMouseMove(e)}
        />
        <canvas
          ref={tooltipCanvasRef}
          className="absolute "
          style={{ zIndex: 4 }}
          onMouseMove={(e) => handleMouseMove(e)}
        />
        {showLegendText && (
          <div className="mt-4 flex justify-center gap-4">
            <ChartLegendText text={primaryText} color={primaryColor} />
            {secondaryText && secondaryColor && (
              <ChartLegendText text={secondaryText} color={secondaryColor} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarChart;
