import { END_POINTS } from '@config';
import { baseApi } from '@core/baseApi';
import {
  HTTPResponse,
  Role,
  Scalars,
  UpdateRole,
  UpdateRoleRequest
} from '@types';

export const extendedBaseQuery = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoles: builder.query<HTTPResponse<Role>, any>({
      query: () => ({
        url: `${END_POINTS.ROLES.GET_ROLES}`,
        method: 'GET'
      })
    }),
    getAllRolesFilter: builder.query<HTTPResponse<Role>, any>({
      query: () => ({
        url: `${END_POINTS.ROLES.GET_ROLES_FILTER}`,
        method: 'GET'
      })
    }),
    getRoleById: builder.query<HTTPResponse<Role>, Scalars['ID']>({
      query: (id) => ({
        url: `${END_POINTS.ROLES.GET_ROLE(id)}`,
        method: 'GET'
      })
    }),
    getAllEntities: builder.query<HTTPResponse<Role>, any>({
      query: () => ({
        url: `${END_POINTS.ROLES.GET_ENTITIES}`,
        method: 'GET'
      })
    }),
    updateRole: builder.mutation<HTTPResponse<Role>, UpdateRoleRequest>({
      query: (reqBody) => ({
        url: `${END_POINTS.ROLES.UPDATE_ROLE_BY_ID(reqBody.id)}`,
        method: 'PUT',
        body: reqBody.body
      })
    }),
    createRole: builder.mutation<HTTPResponse<Role>, UpdateRole>({
      query: (reqBody) => ({
        url: END_POINTS.ROLES.CREATE_ROLE,
        method: 'POST',
        body: reqBody
      })
    }),
    deleteRole: builder.mutation<any, Scalars['ID']>({
      query: (reqBody) => ({
        url: END_POINTS.ROLES.DELETE_ROLE(reqBody),
        method: 'DELETE'
      })
    })
  })
});

export const {
  useLazyGetAllRolesQuery,
  useLazyGetAllRolesFilterQuery,
  useGetAllRolesFilterQuery,
  useGetAllRolesQuery,
  useLazyGetRoleByIdQuery,
  useGetAllEntitiesQuery,
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useDeleteRoleMutation
} = extendedBaseQuery;
