import {
  DateRangeFilterConfig,
  Scalars,
  TableFilterConfigType,
  TableFilterTypes
} from '@types';
import FilterTypeSelector from './FilterTypeSelector';
import FilterList from './FilterList';

interface FilterPopupProps {
  filterState: TableFilterConfigType;
  popupDirection: string;
  selectedFilterKey: Scalars['String'];
  updateFilterState: (filterState: TableFilterConfigType) => void;
  handleApply: () => void;
  clearFilters: () => void;
  setSelectedFilterKey: (key: string) => void;
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  filterState,
  popupDirection,
  selectedFilterKey,
  setSelectedFilterKey,
  clearFilters,
  handleApply,
  updateFilterState
}) => {
  const currentFilter = filterState[selectedFilterKey];

  return (
    <div
      className={`absolute z-10 mt-4 flex max-h-[490px] justify-between rounded-md bg-white shadow-mediumBlur ${
        popupDirection === 'left' && 'right-0'
      } ${
        currentFilter?.type === TableFilterTypes.DATE_RANGE &&
        (currentFilter as DateRangeFilterConfig)?.customDateOptions
          ? 'w-[750px]'
          : 'w-[650px]'
      }`}
    >
      <FilterList
        filterState={filterState}
        selectedFilterKey={selectedFilterKey}
        setSelectedFilterKey={setSelectedFilterKey}
        handleApply={handleApply}
        clearFilters={clearFilters}
      />
      <FilterTypeSelector
        selectedFilterKey={selectedFilterKey}
        filterState={filterState}
        setFilterState={updateFilterState}
      />
    </div>
  );
};

export default FilterPopup;
