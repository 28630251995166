import clsx from 'clsx';
import { useState, useEffect } from 'react';

import { Button, Dropdown, Input, MultiSelectDropdown } from '@components';
import Modal from '@layouts/modal/Modal';
import { Region, Role, User, ValidationError } from '@types';
import useTranslate from '@hooks/intl';
import testId from '@constants/testId';
import { nameRegex } from '@constants/regexps';
import { validateWithRegexp } from '@utils/generic';
import { useGetAllRolesFilterQuery } from '@features/roles/queries/RoleQuery';
import { useGetAllRegionsFilterQuery } from '@features/regions/queries/RegionQuery';

type Option = { key: string; value: string; displayName: string };
interface EditUserProps {
  open: boolean;
  user: User;
  handleCancel: () => void;
  editUser: (name: string, role: string, regionIds: string[]) => void;
  isEditingUser?: boolean;
}

const EditUser = ({
  open,
  editUser,
  handleCancel,
  user,
  isEditingUser
}: EditUserProps) => {
  const [name, setName] = useState<string>(user.name);
  const [error, setError] = useState<ValidationError>({});
  const translate = useTranslate();
  const [role, setRole] = useState<Role>();
  const [roles, setRoles] = useState<Role[]>([]);

  const { data: regionList } = useGetAllRegionsFilterQuery('');
  const { data: rolesList } = useGetAllRolesFilterQuery('');

  const [selectedRegions, setSelectedRegions] = useState<Option[]>([]);
  const [regionListOptions, setRegionListOptions] = useState<Option[]>([]);

  useEffect(() => {
    const regionOptions = regionList?.map((region: Region) => ({
      key: region.entityId,
      value: region.regionCode,
      displayName: region.regionName,
      displayCode: region.regionCode
    }));
    const regions = regionOptions?.filter((elem: Option) =>
      user.regions?.find(({ entityId }) => elem.key === entityId)
    );
    setSelectedRegions(regions);
    setRegionListOptions(regionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionList]);

  useEffect(() => {
    setRoles(rolesList);
    setRole(
      rolesList?.find(
        (roleObj: Role) => roleObj.entityId === user.role.entityId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesList]);

  const options = roles?.map((roleObj) => ({
    key: roleObj.entityId,
    value: roleObj.name,
    displayName: roleObj.name
  }));

  const onCancel = () => {
    handleCancel();
  };

  const onSubmit = () => {
    const err: ValidationError = {};
    const regionIds = selectedRegions?.map((region) => region.key);
    if (name?.trim()?.length > 50)
      err.name = translate('users.addUser.name_max_char');
    else if (!validateWithRegexp(nameRegex, name))
      err.name = translate('users.editUser.name_error');
    else if (name?.trim().length <= 1)
      err.name = translate('users.editUser.name_min_char');
    if (!regionIds?.length)
      err.region = translate('users.editUser.region_error');
    if (!role) err.role = translate('users.editUser.role_error');

    if (Object.keys(err).length > 0) setError(err);
    else if (role) editUser(name.trim(), role.entityId, regionIds);
  };

  return (
    <Modal
      isOpen={open}
      onCancel={handleCancel}
      className="fixed"
      isClickOutsideAllowed={false}
    >
      <div
        className="text-body-base-md py-4 px-6"
        data-testid={testId.users.addEditUser.title}
      >
        {translate('users.editUser.title')}
      </div>
      <hr className="mb-2" />

      <div className="w-[540px] px-6 pt-6">
        <Input
          label={translate('users.editUser.name')}
          name="editName"
          type="text"
          value={name}
          inputDataStyle="font-normal text-black"
          maxLength={50}
          onChange={(event) => {
            setName(event.target.value);
            setError({ ...error, name: '' });
          }}
          error={!!error.name}
          errorMessage={error.name}
          data-testid={testId.users.addEditUser.nameInput}
        />
        <Input
          label={translate('users.editUser.email')}
          name="email"
          type="text"
          value={user.email}
          className="mt-6"
          inputDataStyle="font-normal text-background70"
          disabled
          data-testid={testId.users.addEditUser.emailInput}
        />
        <div className="mt-6 flex gap-4">
          <Dropdown
            title="Role"
            type="floating"
            dropdownStyle="w-1/2"
            options={options}
            selectedOption={options?.find(
              (option) => option.key === (role?.entityId || '')
            )}
            onClick={(option) => {
              setRole(
                roles?.find(
                  (roleObj) => roleObj.entityId === option.key
                ) as Role
              );
              setError({ ...error, role: '' });
            }}
            error={!!error.role}
            errorMessage={error.role}
            testId={testId.users.addEditUser.roleDropdown}
            dropdownMenuStyle="whitespace-normal"
            errorStyle="absolute"
          />
          <MultiSelectDropdown
            options={regionListOptions}
            onSelect={(regionsSelected) => {
              setSelectedRegions(regionsSelected);
              setError({ ...error, region: '' });
            }}
            selectedOptions={selectedRegions}
            className="ml-2 w-1/2"
            multiSelectDropdownStyle="h-full"
            dropdownWidthStyle="w-full"
            placeholder={translate('users.addUser.region')}
            type="chip"
            testId={testId.users.addEditUser.region}
            error={!!error.region}
            errorMessage={error.region}
            errorStyle="absolute"
            chipStyle="w-fit truncate max-w-[125px]"
            selectAllRequired
          />
        </div>
        <div
          className={clsx(
            'flex pb-4',
            !!error.role || !!error.region ? 'mt-10' : 'mt-7'
          )}
        >
          <Button
            label={translate('users.editUser.cancel_button')}
            handleClick={onCancel}
            variant="outline"
            data-testid={testId.users.addEditUser.cancelButton}
          />
          <Button
            label={translate('users.editUser.submit_button')}
            handleClick={onSubmit}
            variant="primary"
            className="ml-3"
            data-testid={testId.users.addEditUser.submitButton}
            loading={isEditingUser}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditUser;
