import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { WAREHOUSE } from '@constants/routes';

const Warehouse = lazy(
  () => import('@features/warehouse/pages/warehouseList/Warehouse')
);
const CreateWarehouse = lazy(
  () => import('@features/warehouse/pages/create-warehouse/CreateWarehouse')
);
export const WarehouseRoutes: RouteObject[] = [
  {
    path: WAREHOUSE.WAREHOUSE_MANAGEMENT,
    children: [
      { path: '', element: <Warehouse /> },
      { path: WAREHOUSE.CREATE_WAREHOUSE, element: <CreateWarehouse /> },
      { path: WAREHOUSE.EDIT_WAREHOUSE, element: <CreateWarehouse /> }
    ]
  }
];

export default WarehouseRoutes;
