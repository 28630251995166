import { useDispatch } from 'react-redux';
import { createAlert } from '@features/components/notification/notificationSlice';

const useNotify = () => {
  const dispatch = useDispatch();
  const createSuccessAlert = (message: string, description?: string) =>
    dispatch(
      createAlert({
        alert: {
          message,
          description,
          type: 'success'
        }
      })
    );
  const createErrorAlert = (message: string, description?: string) =>
    dispatch(
      createAlert({
        alert: {
          message,
          description,
          type: 'error'
        }
      })
    );
  const createDefaultAlert = (message: string, description?: string) =>
    dispatch(
      createAlert({
        alert: {
          message,
          description,
          type: 'default'
        }
      })
    );
  return { createSuccessAlert, createErrorAlert, createDefaultAlert };
};

export default useNotify;
