import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ACCESS_DENIED } from '@constants/routes';

const AccessDenied = lazy(
  () => import('@features/components/access-denied-page/AccessDeniedPage')
);

export const AccessDeniedRoutes: RouteObject = {
  path: ACCESS_DENIED,
  children: [
    {
      path: '',
      element: <AccessDenied />
    }
  ]
};

export default AccessDeniedRoutes;
