import React, { useState, useMemo, useEffect } from 'react';

import { Option, Scalars, TableFilterConfigType } from '@types';
import useTranslate from '@hooks/intl';

import Checkbox from '../../checkbox/Checkbox';
import SearchBar from '../../search-bar/SearchBar';

type SelectorCheckboxProps = {
  options: Option[];
  handleFilterSelection: (filter: TableFilterConfigType) => void;
  searchEnabled?: Scalars['Boolean'];
  filterState: TableFilterConfigType;
  name: Scalars['String'];
  noResultsText?: Scalars['String'];
  searchPlaceholder?: Scalars['String'];
};

const SelectorCheckbox: React.FC<SelectorCheckboxProps> = ({
  options,
  handleFilterSelection,
  searchEnabled = false,
  filterState,
  name,
  noResultsText,
  searchPlaceholder,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState<Scalars['String']>('');
  const [selectAll, setSelectAll] = useState<Scalars['Boolean']>(false);

  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState<string[]>([]);

  const translate = useTranslate();
  const displayOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
          option.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [searchTerm, options]
  );
  const findIndex = (item: Option) =>
    selectedCheckBoxes.findIndex((checkbox) => checkbox === item.value);

  const handleChange = () => {
    handleFilterSelection({
      ...filterState,
      [name]: {
        ...filterState[name],
        options,
        selectedOptionsCount: options.filter((option) => option.selected).length
      }
    });
  };

  useEffect(() => {
    let selectAllTemp = true;
    setSearchTerm('');
    options.map((option) => {
      if (!option.selected) selectAllTemp = false;
      return '';
    });
    setSelectAll(selectAllTemp);
  }, [options]);

  const setSelected = (
    checkboxElement: Option,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedState = e.target.checked;
    if (checkedState) {
      if (findIndex(checkboxElement) < 0) {
        setSelectedCheckBoxes((preValue) => [
          ...preValue,
          checkboxElement.value
        ]);
      }
    } else {
      const index = findIndex(checkboxElement);
      if (index > -1) {
        setSelectedCheckBoxes((preValue) =>
          preValue.filter((item) => item !== checkboxElement.value)
        );
      }
    }
  };

  useEffect(() => {
    let selectedCount = 0;
    displayOptions.map((element) => {
      if (element.selected === true) selectedCount += 1;
      return '';
    });
    if (selectedCount === displayOptions.length) setSelectAll(true);
    else {
      setSelectAll(false);
      if (selectedCount === 0 && selectedCheckBoxes.length > 0)
        setSelectedCheckBoxes([]);
    }
  }, [selectedCheckBoxes, displayOptions, selectAll]);

  return (
    <div className="w-1/2" {...props}>
      <div className="top-4">
        <div className="my-6 px-4">
          {searchEnabled && (
            <SearchBar
              className="max-w-fit"
              placeholder={searchPlaceholder}
              onSearch={(searchData) => setSearchTerm(searchData)}
            />
          )}
        </div>
        {displayOptions.length > 0 ? (
          <>
            <Checkbox
              checkboxSize="small"
              label="Select All"
              checked={selectAll}
              onChange={(e) => {
                displayOptions.forEach((option) => {
                  option.selected = e.target.checked;
                  setSelected(option, e);
                  handleChange();
                });
                setSelectAll(!selectAll);
              }}
              className="mb-6 cursor-pointer px-4"
              labelStyle="text-body leading-5 cursor-pointer truncate"
            />
            <div className="h-0.5 w-full border-t border-background10 " />
          </>
        ) : (
          <div className="m-auto text-center">
            {noResultsText || translate('component.filter.noSearchStatus')}
          </div>
        )}
      </div>
      <div className="max-h-[340px] overflow-auto px-4">
        {displayOptions.map((option) => (
          <Checkbox
            checkboxSize="small"
            label={option.name}
            htmlKey={option.value}
            handleChange={(e) => {
              option.selected = e.target.checked;
              if (!option.selected) setSelectAll(false);
              setSelected(option, e);
              handleChange();
            }}
            key={option.value}
            checked={option.selected}
            checkboxStyle="my-3"
            labelStyle="text-body leading-5 cursor-pointer truncate"
            className="cursor-pointer"
          />
        ))}
      </div>
    </div>
  );
};

export default SelectorCheckbox;
