import { useState } from 'react';
import { Button, Input } from '@components';
import Modal from '@layouts/modal/Modal';
import useTranslate from '@hooks/intl';
import { User } from '@types';
import { nameRegex } from '@constants/regexps';
import { validateWithRegexp } from '@utils/generic';
import testId from '@constants/testId';

interface EditUserDetailsProps {
  open: boolean;
  handleCancel: () => void;
  editUserName: (name: string) => void;
  userDetails: User;
  isEditingUserName?: boolean;
}

const EditUserDetails = ({
  open,
  handleCancel,
  editUserName,
  userDetails,
  isEditingUserName,
  ...props
}: EditUserDetailsProps) => {
  const [userName, setUserName] = useState<string>(userDetails?.name);
  const [error, setError] = useState<string>('');
  const translate = useTranslate();

  const onEditUserName = async () => {
    let err: string = '';
    if (userName?.trim()?.length > 50)
      err = translate('users.addUser.name_max_char');
    else if (!validateWithRegexp(nameRegex, userName))
      err = translate('users.editUser.name_error');
    else if (userName?.trim().length <= 1)
      err = translate('users.editUser.name_min_char');
    if (err) setError(err);
    else editUserName(userName);
  };

  return (
    <Modal isOpen={open} onCancel={handleCancel} {...props}>
      <div
        className="py-4 px-6 text-base font-medium"
        data-testid={testId.myAccount.editUserDetails.title}
      >
        {translate('myAccount.editUserDetails.title')}
      </div>
      <hr className="mb-2" />

      <div className="w-[424px] px-6 pt-6">
        <Input
          label={translate('myAccount.editUserDetails.name')}
          name="userName"
          type="text"
          value={userName}
          inputDataStyle="font-normal text-black"
          maxLength={50}
          onChange={(event) => {
            setUserName(event.target.value);
            setError('');
          }}
          autoFocus
          error={!!error}
          errorMessage={error}
          data-testid={testId.myAccount.editUserDetails.nameInput}
        />

        <div className="mt-8 flex gap-3 pb-4">
          <Button
            label={translate('myAccount.editUserDetails.cancel_button')}
            handleClick={handleCancel}
            variant="outline"
            size="small"
            data-testid={testId.myAccount.editUserDetails.cancelButton}
          />
          <Button
            label={translate('myAccount.editUserDetails.save_button')}
            handleClick={onEditUserName}
            variant="primary"
            size="small"
            loading={isEditingUserName}
            data-testid={testId.myAccount.editUserDetails.submitButton}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditUserDetails;
