import clsx from 'clsx';

import { Icon } from '@components';
import { useTheme } from '@contexts/theme';

import { SideNavItem } from './SideNavBar.config';

interface CollapseNavSectionInterface {
  item: SideNavItem;
  checkIsPathActive: (path: string) => boolean;
  sideBarState: (isSidebarOpen: boolean) => void;
  onClickSideBarIcon: (selectedIconName: string) => void;
  expandSideBar: boolean;
}

const CollapseSideNav = ({
  item,
  checkIsPathActive,
  sideBarState,
  expandSideBar,
  onClickSideBarIcon
}: CollapseNavSectionInterface) => {
  const { colors } = useTheme();
  const { icon, childPaths } = item;

  const isMainNavItemActive = childPaths
    ? !!childPaths?.some((childPath) => checkIsPathActive(childPath.path))
    : checkIsPathActive(item.path);

  return (
    <div
      className={clsx(
        'items-center rounded !p-2.5',
        isMainNavItemActive ? '!bg-primary' : ' hover:bg-background90'
      )}
      onClick={() => {
        sideBarState(!expandSideBar);
        onClickSideBarIcon(icon || '');
      }}
      role="presentation"
    >
      {icon ? (
        <div className="flex items-center justify-center">
          <Icon
            name={icon}
            fill={isMainNavItemActive ? colors.white : colors.background30}
            size="extralarge"
            className="flex items-center justify-center"
          />
        </div>
      ) : null}
    </div>
  );
};
export default CollapseSideNav;
