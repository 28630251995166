import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { HOME } from '@constants/routes';
import useCheckPermission from '@hooks/checkPermission';
import { ReactComponent as AppLogo } from '@assets/logo/logo_full.svg';
import { ReactComponent as CollapsedAppLogo } from '@assets/logo/logo_collapsed.svg';

import ClickOutside from '@utils/clickOutside';
import AlertUnsaved from '@features/components/alert-unsaved/AlertUnsaved';
import useCallbackPrompt from '@hooks/useCallBackPromt';
import { SideNavItem, sideNavItems } from './SideNavBar.config';
import NavSection from './NavSection';
import CollapseSideNav from './CollapseSideNav';

const SideNavBar = () => {
  const { pathname: currentPath } = useLocation();
  const navigate = useNavigate();

  const checkIfPathMatchesCurrentPath = useCallback(
    (path: string) => {
      if (!path) {
        return false;
      }
      return currentPath.startsWith(path);
    },
    [currentPath]
  );

  const [expandSidenav, setExpandSideNav] = useState<boolean>(false);

  const [selectedCollapsedItem, setSelectedCollapsedItem] = useState({
    id: '',
    nameKey: '',
    path: ''
  });

  const sidebarRef = useRef() as MutableRefObject<HTMLDivElement>;

  const { isChangesUnsaved } = useSelector((state: any) => state.user);

  const handleNavigate = (path: any) => {
    navigate(path);
  };

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isChangesUnsaved);

  const handleClickSideBarIcon = (selectedIcon: string) => {
    let selectedItem: SideNavItem = { id: '', nameKey: '', path: '' };
    sideNavItems.map((item) => {
      if (item.icon === selectedIcon) {
        selectedItem = item;
      }
      setSelectedCollapsedItem(selectedItem);
      return '';
    });
  };

  ClickOutside({
    ref: sidebarRef,
    handler: () => setExpandSideNav(false)
  });

  const { checkPermission } = useCheckPermission();

  const getPermittedPaths = (navItem: SideNavItem) => {
    const permittedChildPaths = navItem?.childPaths?.filter((path) =>
      checkPermission(path.permissions)
    );
    return permittedChildPaths;
  };

  const permittedSideNavItems = sideNavItems?.filter((navItem) => {
    if (navItem?.childPaths) return getPermittedPaths(navItem)?.length;
    return checkPermission(navItem?.permissions);
  });

  return (
    <>
      <div
        ref={sidebarRef}
        aria-label="Sidebar"
        id="side-nav-bar"
        className={clsx(
          'absolute z-[100] flex h-screen justify-center overflow-y-scroll bg-background'
        )}
        onMouseEnter={() => setExpandSideNav(true)}
        onMouseLeave={() => setExpandSideNav(false)}
      >
        <div
          className={` overflow-x-hidden py-5 px-2.5 transition-all duration-200 ease-in-out ${
            expandSidenav ? 'w-72' : 'w-20'
          }`}
        >
          {expandSidenav ? (
            <div className="flex justify-center">
              <Link to={HOME}>
                <AppLogo className="mx-auto mb-12 h-6" />
              </Link>
            </div>
          ) : (
            <div className="flex justify-center">
              <Link to={HOME}>
                <CollapsedAppLogo className="mx-auto mb-12 h-6" />
              </Link>
            </div>
          )}
          <div className="space-y-4">
            {expandSidenav
              ? permittedSideNavItems.map((navItem) => (
                  <NavSection
                    key={navItem.id}
                    item={navItem}
                    checkIsPathActive={checkIfPathMatchesCurrentPath}
                    handleNavigate={handleNavigate}
                    selectedItem={selectedCollapsedItem}
                    childPaths={getPermittedPaths(navItem)}
                  />
                ))
              : permittedSideNavItems.map((navItem) => (
                  <CollapseSideNav
                    key={navItem.id}
                    item={navItem}
                    checkIsPathActive={checkIfPathMatchesCurrentPath}
                    sideBarState={(isSideBarOpen) =>
                      setExpandSideNav(isSideBarOpen)
                    }
                    expandSideBar={expandSidenav}
                    onClickSideBarIcon={handleClickSideBarIcon}
                  />
                ))}
          </div>
        </div>
      </div>
      {showPrompt && (
        <AlertUnsaved
          cancelNavigation={cancelNavigation}
          confirmNavigation={confirmNavigation}
        />
      )}
    </>
  );
};

export default SideNavBar;
