import { useState, useRef, MutableRefObject, useEffect } from 'react';
import clsx from 'clsx';

import ClickOutside from '@utils/clickOutside';
import { timeFormat } from '@utils/generic';
import { getFormattedTime } from '@features/couriers/helpers';

import Button from '../button/Button';
import TimeUnitInput from './TimeUnitInput';
import './styles.scss';

const MAX_HR = 23;
const MAX_MIN = 59;

export type Time = {
  hour: number;
  min: number;
};
interface TimePickerProps {
  handleChange: (time: Time) => void;
  timeSelected?: Time;
  disabled?: boolean;
  label?: string;
}

const TimePicker = ({
  handleChange,
  timeSelected,
  disabled = false,
  label
}: TimePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [hour, setHour] = useState<number>(timeSelected?.hour || 0);
  const [min, setMin] = useState<number>(timeSelected?.min || 0);

  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (timeSelected) {
      const time = getFormattedTime(timeSelected);
      setValue(time);
    }
  }, [timeSelected]);

  const onCancel = () => {
    setOpen(false);
  };

  const onApply = () => {
    const time = `${timeFormat(hour)} : ${timeFormat(min)}`;
    setValue(time);
    setOpen(false);
    handleChange({
      hour,
      min
    });
  };

  ClickOutside({ ref: dropdownRef, handler: onCancel });
  return (
    <div ref={dropdownRef} className="relative mr-8 w-[270px]">
      <div
        className={clsx({
          'flex h-12 flex-row rounded-md border bg-transparent': true,
          'border-primary': open
        })}
        onClick={() => setOpen((prevState) => !prevState)}
        role="presentation"
      >
        {value ? (
          <>
            <span
              className={clsx(
                'absolute top-[3px] left-1 z-10 origin-[0] -translate-y-4 bg-white px-2 text-sm',
                open === true ? 'text-primary' : 'text-background50'
              )}
            >
              {label}
            </span>
            <span
              className={`text-body pt-3 pl-4 ${
                disabled && 'text-background70'
              }`}
            >
              {value}
            </span>
          </>
        ) : (
          <span className="text-body pt-3 pl-4 text-background70">{label}</span>
        )}
      </div>
      {open && (
        <div className="absolute z-20 mt-3 rounded-xl  border border-background10  bg-white py-4 pl-5 shadow-md">
          <div className=" flex flex-row items-center ">
            <TimeUnitInput
              setFinalValue={(hr) => setHour(hr)}
              max={MAX_HR}
              initialValue={timeSelected?.hour}
            />
            :
            <TimeUnitInput
              setFinalValue={(minitues) => setMin(minitues)}
              max={MAX_MIN}
              initialValue={timeSelected?.min}
              className="ml-8"
            />
          </div>
          <div className="mt-7 flex">
            <Button label="Cancel" handleClick={onCancel} variant="secondary" />
            <Button
              label="Apply"
              handleClick={onApply}
              variant="primary"
              className="ml-[14px] mr-[21px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
