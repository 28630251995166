import { useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

import { ReactComponent as DragIcon } from '@components/icon/icons/draggable.svg';
import Button from '../button/Button';
import Icon from '../icon/Icon';

export interface DragMeProps {
  id: any;
  children?: any;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: () => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DragMe: React.FC<DragMeProps> = ({
  id,
  children,
  index,
  moveCard,
  handleDelete
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when,
      // the mouse has crossed half of the items height

      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(previewRef);
  drag(ref);
  preview(previewRef);

  return (
    <div
      ref={previewRef}
      className="group/drag my-2.5 flex rounded-md border border-background10 bg-white p-4"
      style={{ opacity: isDragging ? 0 : 1 }}
      data-handler-id={handlerId}
    >
      <span ref={ref} className="flex cursor-grab pr-2 align-middle">
        <DragIcon className="group-hover/drag:visible invisible m-auto" />
      </span>
      <input
        className="mr-4 flex h-9 w-9 rounded-md border border-background10 px-2 text-center outline-none"
        onChange={() => null}
        value={index + 1}
        readOnly
      />
      {children}
      <Button handleClick={handleDelete} variant="icon">
        <Icon name="trash" />
      </Button>
    </div>
  );
};
