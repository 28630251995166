import React, { useEffect, useState } from 'react';
import { Option, Scalars, TableFilterConfigType } from '@types';
import { RadioButton } from '@components/index';

type SelectorRadioProps = {
  options: Option[];
  handleFilterSelection: (filter: TableFilterConfigType) => void;
  filterState: TableFilterConfigType;
  name: Scalars['String'];
};

const SelectorRadio: React.FC<SelectorRadioProps> = ({
  options,
  handleFilterSelection,
  filterState,
  name
}) => {
  const [selectedRadio, setSelectedRadio] = useState<Option>(
    options.filter((item) => item.selected === true)[0]
  );

  const handleChange = (option: Option) => {
    options.map((item) => {
      item.selected = item.value === option.value;
      return null;
    });

    handleFilterSelection({
      ...filterState,
      [name]: {
        ...filterState[name],
        options,
        selectedOptionsCount: 1
      }
    });
  };

  useEffect(() => {
    const selectedOption = options.find((item) => item.selected);
    if (selectedOption) {
      setSelectedRadio(selectedOption);
    }
  }, [options]);

  return (
    <div className="w-1/2">
      <div className="my-6 max-h-[340px] overflow-auto px-4">
        {options.map((option) => (
          <RadioButton
            isSelected={option.value === selectedRadio?.value}
            value={name}
            label={option.name}
            onClick={() => {
              setSelectedRadio(option);
              handleChange(option);
            }}
            key={option.value}
            labelStyle="text-body leading-5 cursor-pointer truncate py-2"
          />
        ))}
      </div>
    </div>
  );
};

export default SelectorRadio;
