import { Scalars } from '@types';

import { useState, useEffect, ReactElement } from 'react';
import { Tooltip } from '../tool-tip/Tooltip';

interface TextComponentProps {
  label: Scalars['String'] | Scalars['Int'] | ReactElement;
  testId: Scalars['String'];
  className?: Scalars['String'];
  tooltipStyle?: Scalars['String'];
  displayTooltipAtBottom?: Scalars['Boolean'];
}

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

const TextComponent = ({
  label,
  testId,
  className,
  tooltipStyle,
  displayTooltipAtBottom = false
}: TextComponentProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const screenSize = useScreenSize();
  const isEllipsisActive = (element: HTMLElement | null) => {
    if ((element?.offsetWidth || 0) < (element?.scrollWidth || 0)) {
      setShowTooltip(true);
    } else setShowTooltip(false);
  };

  useEffect(() => {
    if (testId) {
      const div = document.getElementById(testId);
      isEllipsisActive(div);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testId, screenSize.height, screenSize.width]);

  return (
    <div className={`group/tooltip ${className}`} id={testId}>
      {label}
      {showTooltip && (
        <Tooltip
          text={label.toString()}
          className={tooltipStyle}
          position={displayTooltipAtBottom ? 'bottom' : 'top'}
        />
      )}
    </div>
  );
};

export default TextComponent;
