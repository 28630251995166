import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import Notification from '@features/components/notification/Notification';

import { store } from '@core/store';
import { Button } from '@components';

import LocaleProvider from './locale';
import { ThemeProvider } from './theme';

const ErrorFallback = () => (
  <div
    className="flex h-screen w-screen flex-col items-center justify-center"
    role="alert"
  >
    <h2 className="text-lg font-semibold">Oops, something went wrong!</h2>
    <Button
      className="mt-4"
      label="Refresh"
      handleClick={() => window.location.assign(window.location.origin)}
      size="small"
    />
  </div>
);

type AppProviderProps = {
  children: ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ThemeProvider>
      <Provider store={store}>
        <HelmetProvider>
          <LocaleProvider>
            <Notification />
            <Router>{children}</Router>
          </LocaleProvider>
        </HelmetProvider>
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>
);

export default AppProvider;
