import { authApi } from '@core/baseApi';
import { END_POINTS } from '@config';
import {
  ResetPasswordRequest,
  LoginRequest,
  SetPasswordRequest,
  Scalars,
  User,
  HTTPResponse
} from '@types';

const extendedBaseQuery = authApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<HTTPResponse<null>, LoginRequest>({
      query: (credentials) => ({
        url: END_POINTS.AUTH.LOGIN,
        method: 'POST',
        body: credentials
      })
    }),
    logout: builder.mutation<HTTPResponse<null>, any>({
      query: () => ({
        url: END_POINTS.AUTH.LOGOUT,
        method: 'POST'
      })
    }),
    validateToken: builder.query<HTTPResponse<null>, Scalars['String']>({
      query: (token) => ({
        url: END_POINTS.AUTH.VALIDATE_TOKEN,
        method: 'GET',
        headers: {
          'x-auth-token': token
        }
      })
    }),
    initiateResetPassword: builder.mutation<
      HTTPResponse<null>,
      Scalars['String']
    >({
      query: (email) => ({
        url: END_POINTS.USER.INITIATE_RESET_PASSWORD,
        method: 'POST',
        body: {
          email
        }
      })
    }),
    resetPassword: builder.mutation<HTTPResponse<User>, ResetPasswordRequest>({
      query: (formBody) => ({
        url: END_POINTS.USER.RESET_PASSWORD,
        method: 'POST',
        headers: {
          'x-auth-token': formBody.token
        },
        body: {
          password: formBody.password,
          confirmPassword: formBody.confirmPassword
        }
      })
    }),
    setPassword: builder.mutation<HTTPResponse<User>, SetPasswordRequest>({
      query: (formBody) => ({
        url: END_POINTS.USER.SET_PASSWORD,
        method: 'POST',
        headers: {
          'x-auth-token': formBody.token
        },
        body: {
          password: formBody.password,
          confirmPassword: formBody.confirmPassword
        }
      })
    })
  })
});

export const {
  useInitiateResetPasswordMutation,
  useResetPasswordMutation,
  useLoginMutation,
  useSetPasswordMutation,
  useLogoutMutation,
  useValidateTokenQuery
} = extendedBaseQuery;
