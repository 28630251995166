import { Scalars } from '@types';
import {
  ChartData,
  DoughnutChartDataProps,
  DoughnutChartTooltipProps,
  ToolTipListProps,
  TooltipValue
} from '../types';

export const getDoughnutChartValues = (chartData: ChartData[]) => {
  let startPoint = 0;
  let sumOfValues = 0;
  const tempList: DoughnutChartDataProps[] = [];

  chartData.map((item) => {
    sumOfValues += item.value;
    return null;
  });
  chartData.map((item) => {
    const endPoint = startPoint + item.value / sumOfValues;
    tempList.push({
      ...item,
      startAt: startPoint,
      endAt: endPoint
    });
    startPoint = endPoint;
    return null;
  });
  return tempList;
};

export const getSumOfItems = (chartData: ChartData[]) => {
  let sumOfValues = 0;
  chartData.map((item) => {
    sumOfValues += item.value;
    return null;
  });
  return sumOfValues;
};

export const getMaxValue = (
  chartData: ChartData[],
  showSecondaryValue: boolean
) => {
  let maxValue = 0;
  chartData.map((item) => {
    if (maxValue < item.value) maxValue = item.value;
    if (
      showSecondaryValue &&
      item.secondaryValue &&
      item.secondaryValue > maxValue
    )
      maxValue = item.secondaryValue;
    return null;
  });
  return maxValue;
};

export const getUpperLimit = (maxValue: number) => {
  const upperLimitList: number[] = [
    10, 50, 100, 200, 500, 1000, 2000, 5000, 10000
  ];
  let limitValue: number = 0;
  let limitFlag: boolean = false;
  upperLimitList.map((value) => {
    if (value >= maxValue && !limitFlag) {
      limitValue = value;
      limitFlag = true;
    }
    return null;
  });
  if (limitValue === 0) {
    limitValue = Math.ceil(maxValue / 10000) * 10000;
  }
  return limitValue;
};

export const getCustomDoughnutChartValues = (
  chartData: ChartData[],
  primaryColor: Scalars['String'],
  secondaryColor: Scalars['String']
) => {
  const tempList: DoughnutChartDataProps[] = [];
  let activeCount = 0;
  const totalCount = chartData.length;
  chartData.map((item) => {
    if (item.status) activeCount += 1;
    return null;
  });

  const activePercentage = (activeCount / totalCount) * 0.8 + 0.1;

  tempList.push({
    name: 'Active',
    value: activeCount,
    fillColor: primaryColor,
    startAt: 0,
    endAt: activePercentage
  });
  tempList.push({
    name: '',
    value: 0,
    fillColor: 'transparent',
    startAt: activePercentage,
    endAt: activeCount === 0 ? activePercentage : activePercentage + 0.02
  });
  tempList.push({
    name: 'Inactive',
    value: totalCount - activeCount,
    fillColor: secondaryColor,
    startAt: activeCount === 0 ? activePercentage : activePercentage + 0.02,
    endAt: 1
  });

  return tempList;
};

export const getTooltipWidth = (
  ctx: CanvasRenderingContext2D,
  tooltipValue: TooltipValue
) => {
  let maxWidth = 0;
  Object.values(tooltipValue).map((item) => {
    const textWidth =
      ctx.measureText(item.text).width + ctx.measureText(item.value).width;
    if (maxWidth < textWidth) maxWidth = textWidth;
    return null;
  });
  return maxWidth + 10;
};

export const drawBarChartToolTip = (
  toolTipList: ToolTipListProps[],
  tooltipCanvas: any, // HTMLCanvasElement
  mouseX: Scalars['Int'],
  mouseY: Scalars['Int']
) => {
  let hit = false;
  toolTipList.map((item) => {
    if (tooltipCanvas) {
      if (
        item.xStart < mouseX &&
        item.xEnd > mouseX &&
        item.yStart < mouseY &&
        item.yEnd > mouseY
      ) {
        const ctx = tooltipCanvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
          ctx.font = '500 12px Manrope';
          const tooltipWidth = getTooltipWidth(ctx, item.value);
          const textHeight = 40 + (Object.keys(item.value).length - 1) * 30;

          tooltipCanvas.style.left = (mouseX - tooltipWidth / 2)
            .toString()
            .concat('px');
          tooltipCanvas.style.top =
            mouseY - textHeight > 20
              ? (mouseY - textHeight - 20).toString().concat('px')
              : (mouseY + 20).toString().concat('px');

          // 1: Rectangle size
          // Set canvas height corresponding to the rectangle size
          tooltipCanvas.width = tooltipWidth + 40;
          tooltipCanvas.height = textHeight + 10;

          // 2: Rounded Rectangle with shadow
          ctx.beginPath();
          ctx.fillStyle = '#FFF';
          ctx.strokeStyle = '#000';
          ctx.shadowColor = '#000';
          ctx.shadowBlur = 2;
          ctx.shadowOffsetX = 1;
          ctx.shadowOffsetY = 1;
          ctx.roundRect(0, 0, tooltipWidth + 20, textHeight, 5);
          ctx.fill();

          // 3: Tooltip Arrow
          ctx.beginPath();
          ctx.moveTo(tooltipWidth / 2 - 8, textHeight);
          ctx.lineTo(tooltipWidth / 2, textHeight + 10);
          ctx.lineTo(tooltipWidth / 2 + 8, textHeight);
          ctx.closePath();
          ctx.fillStyle = '#FFF';
          ctx.fill();

          // 4: Text to Fill
          // 4.1 : Line 1 Text
          ctx.beginPath();
          ctx.moveTo(10, 10);
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'left';
          ctx.font = '400 12px Manrope';
          ctx.fillText(item.value.line1?.text || '', 10, 25);

          // 4.2 : Line 1 Value
          if (item.value.line1?.value) {
            ctx.beginPath();
            ctx.font = '500 12px Manrope';
            ctx.textAlign = 'right';
            ctx.fillText(
              item.value.line1.value.toString(),
              tooltipWidth + 10,
              25
            );
          }

          // 4.3 : Line 2 Text
          if (item.value.line2) {
            ctx.beginPath();
            ctx.textAlign = 'left';
            ctx.font = '400 12px Manrope';
            ctx.fillText(item.value.line2.text || '', 10, 55);
          }

          // 4.4 : Line 2 value
          if (item.value.line2?.value) {
            ctx.beginPath();
            ctx.font = '500 12px Manrope';
            ctx.textAlign = 'right';
            ctx.fillText(
              item.value.line2?.value?.toString() || '',
              tooltipWidth + 10,
              55
            );
          }
          // 4.5 : Line 3 Text
          if (item.value.line3) {
            ctx.beginPath();
            ctx.textAlign = 'left';
            ctx.font = '400 12px Manrope';
            ctx.fillText(item.value.line3.text || '', 10, 85);
          }

          // 4.6 : Line 3 value
          if (item.value.line3?.value) {
            ctx.beginPath();
            ctx.font = '500 12px Manrope';
            ctx.textAlign = 'right';
            ctx.fillText(
              item.value.line3?.value?.toString() || '',
              tooltipWidth + 10,
              85
            );
          }
          hit = true;
        }
      }
      if (!hit) {
        // Clear canvas if not hit
        tooltipCanvas.style.left = '-200px';
        const ctx = tooltipCanvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
        }
      }
    }

    return null;
  });
  //
};

export const drawDoughnutChartToolTip = (
  tooltipCanvas: any, // HTMLCanvasElement
  tooltipData: DoughnutChartTooltipProps,
  mouseX: number,
  mouseY: number
) => {
  const ctx = tooltipCanvas.getContext('2d');
  if (ctx) {
    ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
    ctx.font = '500 12px Manrope';
    const tooltipWidth =
      ctx.measureText(tooltipData.text).width +
      ctx.measureText(tooltipData.value).width +
      10;
    const textHeight = 40;

    tooltipCanvas.style.left = (mouseX - tooltipWidth / 2)
      .toString()
      .concat('px');
    tooltipCanvas.style.top = (mouseY - textHeight - 10)
      .toString()
      .concat('px');
    // 1: Rectangle size
    // Set canvas height corresponding to the rectangle size
    tooltipCanvas.width = tooltipWidth + 40;
    tooltipCanvas.height = textHeight + 10;

    // 2: Rounded Rectangle with shadow
    ctx.beginPath();
    ctx.fillStyle = '#FFF';
    ctx.strokeStyle = '#000';
    ctx.shadowColor = '#000';
    ctx.shadowBlur = 2;
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 1;
    ctx.roundRect(0, 0, tooltipWidth + 20, textHeight, 5);
    ctx.fill();

    // 3: Tooltip Arrow
    ctx.beginPath();
    ctx.moveTo(tooltipWidth / 2 - 8, textHeight);
    ctx.lineTo(tooltipWidth / 2, textHeight + 10);
    ctx.lineTo(tooltipWidth / 2 + 8, textHeight);
    ctx.closePath();
    ctx.fill();

    // 4: Text to Fill
    // 4.1 : Line 1 Text
    ctx.beginPath();
    ctx.moveTo(10, 10);
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.fillStyle = '#000';
    ctx.textAlign = 'left';
    ctx.font = '400 12px Manrope';
    ctx.fillText(tooltipData.text || '', 10, 25);

    // 4.2 : Line 1 Value
    if (tooltipData.value) {
      ctx.beginPath();
      ctx.textAlign = 'right';
      ctx.font = '500 12px Manrope';
      ctx.fillText(tooltipData.value, tooltipWidth + 10, 25);
    }
    if (!tooltipData.text) {
      // Clear canvas if no text to display
      tooltipCanvas.style.left = '-200px';
      ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
    }
  }
};
