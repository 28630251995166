import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthLayout } from '@components';
import { AUTH } from '@constants/routes';

const Login = lazy(() => import('@features/authentication/pages/login/Login'));
const SetPassword = lazy(
  () => import('@features/authentication/pages/set-password/SetPassword')
);
const ResetPasswordPage = lazy(
  () => import('@features/authentication/pages/reset-password/ResetPassword')
);
const ConfirmPasswordPage = lazy(
  () => import('@features/authentication/pages/reset-password/ConfirmPassword')
);

export const AuthRoutes: RouteObject = {
  element: <AuthLayout />, // Outlet in AuthLayout will be replaced by matching element from below
  children: [
    { path: AUTH.LOGIN, element: <Login /> },
    {
      path: AUTH.SET_PASSWORD,
      element: <SetPassword />
    },
    {
      path: AUTH.RESET_PASSWORD,
      children: [
        {
          path: '',
          element: <ResetPasswordPage />
        },
        {
          path: AUTH.CONFIRM_RESET,
          element: <ConfirmPasswordPage />
        }
      ]
    }
  ]
};

export default AuthRoutes;
