import { baseApi } from '@core/baseApi';
import { END_POINTS } from '@config';
import {
  Scalars,
  Region,
  CreateRegionRequest,
  UpdateRegionRequest,
  HTTPResponse,
  TimeZone
} from '@types';

export const extendedBaseQuery = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRegions: builder.query<
      HTTPResponse<Region[]>,
      Scalars['QueryString']
    >({
      query: (queryString) => ({
        url: `${END_POINTS.REGIONS.GET_ALL_REGIONS}/${queryString}`,
        method: 'GET'
      })
    }),
    getAllRegionsFilter: builder.query<
      HTTPResponse<Region[]>,
      Scalars['QueryString']
    >({
      query: () => ({
        url: `${END_POINTS.REGIONS.GET_ALL_REGIONS_FILTER}`,
        method: 'GET'
      })
    }),
    updateRegion: builder.mutation<HTTPResponse<Region>, UpdateRegionRequest>({
      query: (reqBody) => ({
        url: END_POINTS.REGIONS.UPDATE_REGION(reqBody.id),
        method: 'PATCH',
        body: reqBody.body
      })
    }),
    createRegion: builder.mutation<HTTPResponse<Region>, CreateRegionRequest>({
      query: (reqBody) => ({
        url: END_POINTS.REGIONS.CREATE_REGION,
        method: 'POST',
        body: reqBody
      })
    }),
    getAllTimeZones: builder.query<HTTPResponse<TimeZone[]>, any>({
      query: () => ({
        url: `${END_POINTS.REGIONS.GET_ALL_TIMEZONES}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useLazyGetAllRegionsQuery,
  useGetAllRegionsQuery,
  useGetAllRegionsFilterQuery,
  useUpdateRegionMutation,
  useCreateRegionMutation,
  useGetAllTimeZonesQuery
} = extendedBaseQuery;
