import { useSelector, useDispatch } from 'react-redux';
import useTranslate from '@hooks/intl';

import { Button, Modal } from '@components';
import { setIsChangesUnsaved } from '@features/users/slices/userSlice';

interface AlertUnsavedProps {
  cancelNavigation: any;
  confirmNavigation: any;
}

const AlertUnsaved = ({
  cancelNavigation,
  confirmNavigation
}: AlertUnsavedProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { isChangesUnsaved } = useSelector((state: any) => state.user);

  const onLeave = () => {
    dispatch(setIsChangesUnsaved(false));
    confirmNavigation();
  };

  return (
    <Modal isOpen={isChangesUnsaved} onCancel={cancelNavigation}>
      <div className="p-5">
        <div className="text-h5"> {translate('alertUnsaved.title')} </div>
        <div className="text-body-base my-8">
          {translate('alertUnsaved.description')}
        </div>
        <div className="mb-2 flex">
          <Button
            label={translate('alertUnsaved.cancel')}
            handleClick={cancelNavigation}
            variant="outline"
          />
          <Button
            label={translate('alertUnsaved.confirm')}
            handleClick={onLeave}
            variant="primary"
            className="ml-3"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AlertUnsaved;
