import { useEffect, useState } from 'react';

import { regionTimeFormat } from '@utils/dateFormat';

interface RegionClockProps {
  currentRegion: any;
}

const RegionClock = ({ currentRegion }: RegionClockProps) => {
  const [timeOffSetValue, setTimeOffSetValue] = useState<string>('');
  const setFormattedCurrentTime = () => {
    const dateTime = new Date();
    const formattedTime = `(${regionTimeFormat(
      dateTime,
      currentRegion?.zone || 'UTC'
    )} ${currentRegion?.abbreviation || 'UTC'})`;
    setTimeOffSetValue(formattedTime);
  };

  useEffect(() => {
    setFormattedCurrentTime();
    const timer = setInterval(() => {
      setFormattedCurrentTime();
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion?.zone]);

  return <span>{timeOffSetValue}</span>;
};

export default RegionClock;
