import { DASHBOARD } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Dashboard = lazy(() => import('@features/dashboard/pages/DashBoard'));
const GlobalDashboard = lazy(
  () => import('@features/dashboard/pages/GlobalDashboard')
);

export const DashboardRoutes: RouteObject = {
  path: DASHBOARD.DASHBOARD,
  children: [
    {
      path: DASHBOARD.REGION_DASHBOARD,
      element: <Dashboard />
    },
    {
      path: DASHBOARD.GLOBAL_DASHBOARD,
      element: <GlobalDashboard />
    }
  ]
};

export default DashboardRoutes;
