import clsx from 'clsx';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DraggableRow, TableRow } from './TableRow';
import { TableHead } from './TableHead';
import { DraggableTableProps, TableData, TableProps } from './types';

export const Table: React.FC<TableProps> = ({
  data = [],
  columns = [],
  className = '',
  tableDataStyle = '',
  tableStyle = '',
  emptyState,
  ...props
}) => {
  const columnKeys = columns.map((col) => col.key);

  return (
    <div
      className={clsx(
        'relative flex max-w-full flex-col rounded-lg ',
        className
      )}
      {...props}
    >
      <div className="inline-block w-full align-middle">
        <div
          className={clsx(
            'min-w-full rounded-lg border bg-white md:max-w-[855px]',
            tableStyle
          )}
        >
          <table className="min-w-full divide-y divide-background10">
            <TableHead colData={columns} />
            <tbody className="divide-y divide-background10">
              {data.length === 0 && emptyState ? (
                <td colSpan={columns.length}>{emptyState}</td>
              ) : (
                data.map((rowData) => (
                  <TableRow
                    tableData={rowData}
                    colKeys={columnKeys}
                    key={rowData.id}
                    tableDataStyle={tableDataStyle}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const ReorderableTable: React.FC<DraggableTableProps> = ({
  data = [],
  columns = [],
  className = '',
  enableDrag = true,
  handleReorder,
  tableDataStyle = '',
  ...props
}) => {
  const columnKeys = columns.map((col) => col.key);

  const renderRow = (rowData: TableData, index: number, dragEnabled: boolean) =>
    dragEnabled ? (
      <DraggableRow
        key={rowData.id}
        index={index}
        id={rowData.id}
        moveCard={handleReorder}
        tableData={rowData}
        colKeys={columnKeys}
        tableDataStyle={tableDataStyle}
      />
    ) : (
      <TableRow
        tableData={rowData}
        colKeys={columnKeys}
        key={rowData.id}
        tableDataStyle={tableDataStyle}
      />
    );
  return (
    <div
      className={clsx('relative flex flex-col rounded-lg bg-white', className)}
      {...props}
    >
      <div className="inline-block w-full align-middle">
        <div className="overflow-hidden rounded-lg border">
          <table className="min-w-full divide-y divide-background10">
            <TableHead colData={columns} showDragIcon={enableDrag} />
            <DndProvider backend={HTML5Backend}>
              <tbody className="divide-y divide-background10">
                {data.map((rowData, i) => renderRow(rowData, i, enableDrag))}
              </tbody>
            </DndProvider>
          </table>
        </div>
      </div>
    </div>
  );
};
