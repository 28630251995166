import clsx from 'clsx';
import {
  ComponentPropsWithRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useTheme } from '@contexts/theme';
import Icon from '@components/icon/Icon';
import Button from '@components/button/Button';

interface SearchBarProps extends ComponentPropsWithRef<'input'> {
  className?: string;
  name?: string;
  onSearch: (data: string) => void;
  isActive?: boolean;
  error?: boolean;
  errorStyle?: string;
  errorMessage?: string;
  searchBarStyle?: string;
  onClick?: () => void;
  clearSearchData?: boolean;
  value?: string;
  onKeyPress?: (e: any) => void;
  onClearIconAction?: () => void;
}

const inputStyles = {
  common:
    'appearance-none rounded-10px border bg-white h-10 pl-10 pr-9 text-body focus:outline-none w-full hover:border-background90 caret-primary',
  error: '!border-error'
};

const SearchBar = forwardRef((props: SearchBarProps, ref) => {
  const {
    className = '',
    name,
    onChange,
    value,
    onSearch,
    isActive,
    errorStyle = '',
    errorMessage = '',
    error = false,
    searchBarStyle,
    onClick,
    clearSearchData,
    onKeyPress,
    onClearIconAction,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const { colors } = useTheme();
  const [searchData, setSearchData] = useState('');
  const inputClassNames = clsx(
    inputStyles.common,
    error ? inputStyles.error : ''
  );

  const clearInputField = () => {
    setSearchData('');
    onSearch('');
    onClearIconAction?.();
  };

  const handleChange = (val: any) => {
    if (onChange) {
      onChange(val);
    }
  };
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onSearch(searchData);
    }
  };

  useEffect(() => {
    if (clearSearchData === true) setSearchData('');
  }, [clearSearchData]);

  useImperativeHandle(ref, () => ({
    getSearchValue() {
      return searchData;
    }
  }));

  useEffect(() => {
    setSearchData(value as string);
  }, [value]);

  return (
    <div className={clsx('relative h-10 w-96', className)}>
      <input
        id={name}
        name={name}
        onChange={(e) => {
          handleChange(e);
          setSearchData(e.target.value);
        }}
        value={searchData}
        {...rest}
        className={clsx(
          inputClassNames,
          searchBarStyle,
          (isActive || isFocused) && 'border-primary outline-none'
        )}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 300)}
        onKeyPress={(event) => {
          onKeyPress?.(event);
          handleKeyPress(event);
        }}
        onClick={onClick}
      />
      <div
        className="absolute top-[50%] translate-y-[-50%] px-4 py-2"
        onClick={onClick}
        role="presentation"
      >
        <Icon name="search" stroke={colors.background50} />
      </div>
      {searchData?.length ? (
        <Button
          variant="icon"
          className="absolute right-1 top-0 mx-1 mt-[0.55rem]"
          handleClick={clearInputField}
        >
          <Icon name="x-circle" stroke={colors.background50} />
        </Button>
      ) : null}
      {errorMessage && (
        <p
          className={clsx(
            'text-caption mt-2 flex items-center text-error',
            errorStyle
          )}
        >
          <Icon name="info-circle" className="mr-1" />
          {errorMessage}
        </p>
      )}
    </div>
  );
});

export default SearchBar;
