import { useState, useRef, MutableRefObject, useEffect } from 'react';
import clsx from 'clsx';

import ClickOutside from '@utils/clickOutside';
import { Scalars, DropDownOption as Option } from '@types';
import Input from '@components/input/Input';
import { useTheme } from '@contexts/theme';
import useTranslate from '@hooks/intl';

import Button from '../button/Button';

import Icon from '../icon/Icon';

export type DropdownType = 'default' | 'floating';
interface DropdownProps {
  title?: Scalars['String'];
  options: Option[];
  selectedOption?: Option | null;
  onClick: (item: Option) => void;
  testId?: Scalars['String'];
  type: DropdownType;
  dropdownStyle?: Scalars['String'];
  dropdownMenuStyle?: Scalars['String'];
  inputStyle?: Scalars['String'];
  disabled?: Scalars['Boolean'];
  placeholder?: Scalars['String'];
  error?: boolean;
  errorMessage?: string;
  errorStyle?: string;
  required?: Scalars['Boolean'];
  enableSearch?: Scalars['Boolean'];
  noResultText?: Scalars['String'];
}

const dropdownInputStyle = {
  default: {
    wrapper:
      'peer w-full rounded-10px border p-2.5 focus:outline-none focus:ring-0 disabled:border-background3 border-background30 focus:border-primary hover:enabled:border-background90 text-body',
    error: 'border-error focus:border-error'
  }
};

const Dropdown = ({
  options,
  title,
  selectedOption,
  onClick,
  testId,
  type,
  dropdownStyle,
  dropdownMenuStyle,
  inputStyle,
  disabled,
  placeholder,
  error = false,
  errorMessage = '',
  errorStyle,
  enableSearch = false,
  noResultText,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const translate = useTranslate();

  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const closeDropdown = () => {
    setOpen(false);
  };
  const onSelect = (selectedValue: Option) => {
    onClick(selectedValue);
    setOpen((prevState) => !prevState);
    if (enableSearch) setSearchValue(selectedValue.displayName);
  };
  ClickOutside({ ref: dropdownRef, handler: closeDropdown });
  const { colors } = useTheme();

  const filteredOptions: Option[] = searchValue
    ? options.filter((option: Option) =>
        option.displayName?.toLowerCase().includes(searchValue.toLowerCase())
      )
    : options;

  useEffect(() => {
    if (enableSearch) setSearchValue(selectedOption?.displayName || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const getDisplayValue = () => {
    if (selectedOption) {
      if (open) {
        return searchValue;
      }
      return selectedOption?.displayName;
    }
    if (!selectedOption) {
      if (open) return searchValue;
    }
    return '';
  };

  const displayValue = enableSearch
    ? getDisplayValue()
    : selectedOption?.displayName || '';

  const getPlaceholder = () => {
    if (enableSearch) {
      if (selectedOption?.displayName) return selectedOption?.displayName;
      return placeholder;
    }
    return placeholder;
  };

  return (
    <div className={dropdownStyle} {...props}>
      <div ref={dropdownRef} className="relative" data-testid={testId}>
        {enableSearch && (
          <span className="absolute top-[50%] z-10 flex translate-y-[-50%] px-4 py-2">
            <Icon name="search" stroke={colors.background50} />
          </span>
        )}
        {type === 'floating' ? (
          <div className="relative">
            <Input
              label={title}
              name={title}
              type="text"
              value={displayValue}
              onClick={() => {
                if (enableSearch) setSearchValue('');
                setOpen((prevState) => !prevState);
              }}
              disabled={disabled}
              autoComplete="off"
              error={error}
              placeholder={getPlaceholder()}
              inputDataStyle={clsx(
                disabled ? 'cursor-default' : 'cursor-pointer truncate pr-10',
                enableSearch && 'pl-10'
              )}
              {...props}
              readOnly={!enableSearch}
              onChange={(e) => {
                if (enableSearch) setSearchValue(e.target.value);
              }}
            />
          </div>
        ) : (
          <input
            type="text"
            name="generic"
            value={displayValue}
            onClick={() => {
              if (enableSearch) setSearchValue('');
              setOpen((prevState) => !prevState);
            }}
            className={clsx(
              dropdownInputStyle.default.wrapper,
              disabled ? 'cursor-default' : 'cursor-pointer truncate pr-10',
              error && dropdownInputStyle.default.error,
              enableSearch && 'pl-10',
              inputStyle
            )}
            disabled={disabled}
            autoComplete="off"
            placeholder={getPlaceholder()}
            readOnly={!enableSearch}
            onChange={(e) => {
              if (enableSearch) setSearchValue(e.target.value);
            }}
          />
        )}
        <Button
          variant="icon"
          handleClick={() => {
            if (enableSearch) setSearchValue('');
            setOpen((prevState) => !prevState);
          }}
          className={clsx(
            'absolute right-0 top-[50%] mr-3 translate-y-[-50%]',
            disabled && 'cursor-default'
          )}
          data-testid={`${testId}_btn`}
          disabled={disabled}
        >
          <Icon
            name="chevron-down"
            size="small"
            className={`${open ? 'rotate-180' : ''}`}
            stroke={open ? colors.primary : colors.background90}
          />
        </Button>
        {open && (
          <div
            className={clsx(
              'absolute z-[30] mt-3 max-h-48 w-full overflow-y-auto rounded-md bg-white shadow-dropdownShadow',
              filteredOptions?.length !== 0 ? 'p-2' : null,
              dropdownMenuStyle
            )}
          >
            {filteredOptions?.length > 0 ? (
              filteredOptions?.map((option, index) => {
                const isSelected = selectedOption?.key === option?.key;
                const selectedStyle = 'text-primary';
                const notSelectedStyle = 'text-black';
                return (
                  <div
                    onClick={() => onSelect(option)}
                    className={clsx(
                      !option?.displayElement
                        ? 'text-body !block h-9 truncate rounded-10px p-2 !text-start !align-middle font-normal hover:bg-primary10'
                        : '',
                      isSelected ? selectedStyle : notSelectedStyle
                    )}
                    key={option.key}
                    data-testid={`${testId}_option_${index}`}
                    role="presentation"
                  >
                    {option?.displayElement
                      ? option?.displayElement
                      : option?.displayName}
                  </div>
                );
              })
            ) : (
              <div className="text-body m-auto py-2 text-center">
                {noResultText || translate('component.dropdown.noResultText')}
              </div>
            )}
          </div>
        )}
      </div>
      {errorMessage && (
        <p
          className={clsx(
            'text-caption mt-2 flex items-center text-error',
            errorStyle
          )}
        >
          <Icon name="info-circle" className="mr-1" />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
