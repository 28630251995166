import { ReactElement } from 'react';

import useTranslate from '@hooks/intl';

interface NoResultFoundProps {
  img: string;
  title?: string;
  description?: string | ReactElement;
}

const NoResultFound = ({ img, title, description }: NoResultFoundProps) => {
  const translate = useTranslate();

  return (
    <div className="m-auto flex w-full flex-col items-center justify-center">
      <img
        src={img}
        alt={translate('noResults.altText')}
        className="h-[300px] w-[300px]"
      />
      <div className="text-h3">{title}</div>
      <div className="text-body-base-md mt-4 text-center text-background70">
        {description}
      </div>
    </div>
  );
};

export default NoResultFound;
