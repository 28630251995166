export const getStartOfDay = (date: Date) =>
  new Date(date.setHours(0, 0, 0, 0));
export const getEndOfDay = (date: Date) =>
  new Date(date.setHours(23, 59, 59, 999));

export const getPrevDate = (difference: number) => {
  const date = new Date();
  return new Date(
    new Date(date.getTime() - difference * 24 * 60 * 60 * 1000).setHours(
      0,
      0,
      0,
      0
    )
  );
};
export const getLastWeekStart = () => {
  const d = new Date();
  const day = d.getDay();
  // Start of week : Monday
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return getStartOfDay(new Date(d.setDate(diff - 7)));
};
export const getLastWeekEnd = () => {
  const d = new Date();
  const day = d.getDay();
  // Start of week : Monday
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return getEndOfDay(new Date(d.setDate(diff - 1)));
};

export const startOfLastMonth = () => {
  const d = new Date();
  return getStartOfDay(new Date(d.getFullYear(), d.getMonth() - 1, 1));
};

export const endOfLastMonth = () => {
  const d = new Date();
  return getEndOfDay(new Date(d.getFullYear(), d.getMonth(), 0));
};

export const calculateDateDifferenceInDays = (
  startDate: Date,
  endDate: Date
): number => {
  const differenceInMs = Math.abs(endDate.getTime() - startDate.getTime());
  return differenceInMs / (1000 * 60 * 60 * 24);
};
