import { useState } from 'react';
import { clsx } from 'clsx';
import { Button } from '@components';
import Modal from '@layouts/modal/Modal';
import useTranslate from '@hooks/intl';
import { Region, User, ValidationError } from '@types';
import useNotify from '@hooks/notify';
import testId from '@constants/testId';
import MyRegionsCard from './MyRegionsCard';
import { useUpdateSelfDetailsMutation } from '../../queries/MyAccountQuery';
import { getSortedRegions } from '../../helpers/index';

export type DefaultRegionSizeVariantType =
  | 'extraSmall'
  | 'small'
  | 'medium'
  | 'large';
export interface UpdateDefaultRegionResponse {
  data: User;
  error?: ValidationError;
}
interface EditUserDetailsProps {
  open: boolean;
  handleCancel: () => void;
  onAddedDefaultRegion: (response: UpdateDefaultRegionResponse) => void;
  userDetails: User;
}

const SelectDefaultRegion = ({
  open,
  handleCancel,
  onAddedDefaultRegion,
  userDetails
}: EditUserDetailsProps) => {
  const { createSuccessAlert, createErrorAlert } = useNotify();
  const translate = useTranslate();
  const [updateDefaultRegion, { isLoading }] = useUpdateSelfDetailsMutation();
  const sortedRegions = getSortedRegions(userDetails);
  const [selectedRegionId, setSelectedRegionId] = useState<string>('');

  const regionsLength = userDetails.regions.length;
  const getRegionSize = (): DefaultRegionSizeVariantType => {
    if (regionsLength <= 2) return 'extraSmall';
    if (regionsLength <= 4) return 'small';
    if (regionsLength <= 6) return 'medium';
    return 'large';
  };
  const regionLengthWiseSize: DefaultRegionSizeVariantType = getRegionSize();

  const onSaveDefaultRegion = async () => {
    if (selectedRegionId.length > 0) {
      const res = await updateDefaultRegion({
        body: { defaultRegionId: selectedRegionId }
      });
      if ('data' in res) {
        createSuccessAlert(
          translate('myAccount.addDefaultRegion.success_message')
        );
        onAddedDefaultRegion(res);
      } else if ('error' in res) createErrorAlert(res.error?.data);
    }
  };

  const regionVariantModalStyle = {
    extraSmall: 'w-[503px] h-[260px]',
    small: 'w-[503px] h-[360px]',
    medium: 'w-[800px] h-[360px]',
    large: 'w-[1060px] h-[360px]'
  };

  const regionVariantColumnsStyle = {
    extraSmall: 'grid-cols-2 pt-0',
    small: 'grid-cols-2 h-3/5',
    medium: 'grid-cols-3 h-3/5',
    large: 'grid-cols-4 h-3/5'
  };

  return (
    <Modal
      isOpen={open}
      onCancel={handleCancel}
      isCloseIconRequired={false}
      isClickOutsideAllowed={false}
      className="z-50"
    >
      <div
        className={clsx(
          'items-center',
          regionVariantModalStyle[regionLengthWiseSize]
        )}
      >
        <div
          className="text-body-base-md py-4 px-6 leading-6"
          data-testid={testId.myAccount.selectDefaultRegion.title}
        >
          {translate('myAccount.addDefaultRegion.title')}
        </div>
        <hr className="mb-2.5" />
        <div
          className={clsx(
            'grid w-full items-center justify-center gap-2 overflow-auto pt-4 pl-7',
            regionVariantColumnsStyle[regionLengthWiseSize]
          )}
        >
          {sortedRegions?.map((item: Region, index: number) => (
            <div className="mr-6 flex" key={item.entityId}>
              <MyRegionsCard
                region={item}
                className="mb-4"
                selectedRegionId={selectedRegionId}
                setSelectedRegionId={setSelectedRegionId}
                data-testid={`${testId.myAccount.selectDefaultRegion.entity}_${index}`}
              />
            </div>
          ))}
        </div>
        <div className="absolute inset-x-0 bottom-0 z-50 ml-4 flex rounded-10px bg-white pb-5">
          <Button
            label={translate('myAccount.addDefaultRegion.save_button')}
            handleClick={onSaveDefaultRegion}
            variant="primary"
            size="medium"
            className="ml-3 h-10"
            loading={isLoading}
            data-testid={testId.myAccount.selectDefaultRegion.submitButton}
            disabled={!selectedRegionId.length}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectDefaultRegion;
