export const ACCOUNT_SETUP_CONFIG = [
  {
    title: 'accountConfig.credentials.title',
    description: 'accountConfig.credentials.description',
    path: 'credentials',
    icon: 'lock',
    key: 'credential'
  },
  {
    title: 'accountConfig.serviceability.title',
    description: 'accountConfig.serviceability.description',
    path: 'serviceability',
    icon: 'time-circle',
    key: 'serviceability'
  }
];

export const PARTNER_ACCOUNT_TYPES = {
  FORWARD: 'Forward',
  REVERSE: 'Reverse',
  EXCHANGE: 'Exchange'
};

export const COURIER_TABS = {
  PARTNER: '1',
  ACCOUNTS: '2'
};

export const ACCOUNTS_TABLE_COLUMNS = [
  { key: 'name', title: 'Courier Partner Account' },
  { key: 'partner', title: 'Courier Partner' },
  {
    key: 'capping',
    title: 'Capping',
    showInfoIcon: true,
    infoMssg:
      'Capping is not mandatory. If it is not added, allocations will be infinite, otherwise will reset every 24 hours'
  },
  {
    key: 'allocated',
    title: 'Allocated',
    showInfoIcon: true,
    infoMssg: 'Number of orders allocated for the day'
  },
  { key: 'status', title: 'Status' }
];

export const ADDRESS_MAPPER_TABLE_COLUMNS = (abbreviation: string) => [
  { key: 'slNo', title: 'Sl No' },
  { key: 'partner', title: 'Courier Partner' },
  { key: 'addressMapper', title: 'Address Mapper' },
  { key: 'lastUpdatedAt', title: `Last Updated At (${abbreviation})` },
  { key: 'actions', title: 'Actions' }
];

export default ACCOUNT_SETUP_CONFIG;
