import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery
} from '@features/users/queries/UsersQuery';
import {
  extractRegionCodeFromUrl,
  validateRegionCode,
  redirectToRegionURL
} from '@features/regions/helpers/helpers';
import { SelectDefaultRegion } from '@features/users/components';
import { setCurrentUser } from '@features/users/slices/userSlice';
import {
  setRegions,
  setCurrentRegion
} from '@features/regions/slices/regionSlice';

import { Dropdown, Profile, Icon } from '@components';
import { GlobalPages } from '@constants/core';
import testId from '@constants/testId';
import UserPermissionTypes from '@constants/permissions';

import { User, Region, ValidationError, DropDownOption } from '@types';

import RegionClock from './RegionClock';

const headerStyles = {
  mainHeaderContainer:
    'h-[60px] w-full main-header-container flex justify-between items-center',
  appLogo: 'flex w-1/6.5 p-5',
  actionsSection:
    'action-wrapper w-full h-[60px] flex justify-end items-center border-b border-l pr-[45px]',
  regionSection: 'flex justify-between items-center',
  regionName: 'text-body mr-4',
  regionCode: 'font-bold'
};

const MainHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: any) => state.user);

  const [isGlobalPage, setGlobal] = useState<Boolean>(true);
  const [showEditUserDetailsModal, setShowEditUserDetailsModal] =
    useState<boolean>(false);
  const { currentRegion } = useSelector((state: any) => state.region);

  const {
    data: currentUserResp,
    isFetching,
    isSuccess
  } = useGetCurrentUserQuery();
  const [fetchCurrentUser] = useLazyGetCurrentUserQuery();

  const extractedRegionCode = extractRegionCodeFromUrl();
  const initiateRegionSetup = (data: User) => {
    const { defaultRegion, regions } = data;
    const isRegionCodeValid = validateRegionCode(
      extractedRegionCode,
      regions || []
    );
    if (regions?.length > 0) {
      const fallbackRegion = regions[0];
      const isDefaultRegionValid =
        defaultRegion &&
        validateRegionCode(defaultRegion.regionCode, regions || []);
      const selectedRegion = regions.find(
        (region: Region) =>
          region.regionCode === extractedRegionCode?.toUpperCase()
      );
      if (isRegionCodeValid && selectedRegion) {
        // Note: If extracted region code is valid.
        dispatch(setCurrentRegion({ region: selectedRegion }));
      } else if (isDefaultRegionValid && defaultRegion) {
        // Note: If valid default region is present for user
        redirectToRegionURL(
          defaultRegion.regionCode,
          false,
          extractedRegionCode
        );
      } else if (!isRegionCodeValid && extractedRegionCode) {
        // Note: If user entered some invalid string in place of region code.
        redirectToRegionURL(
          fallbackRegion.regionCode,
          false,
          extractedRegionCode
        );
      } else if (defaultRegion) {
        // Note: If there is no region code entered by user in url.
        dispatch(setCurrentRegion({ region: fallbackRegion }));
        redirectToRegionURL(fallbackRegion.regionCode, true);
      }
      dispatch(setRegions({ regions: data.regions }));
    }
  };

  const checkIfGlobalRoute = () =>
    GlobalPages.some((page) => location.pathname?.includes(page));

  useEffect(() => {
    if (!isFetching && isSuccess) {
      const permissions =
        currentUserResp?.role?.permissions?.map((p: any) => p.name) || [];
      const hasViewGlobalOrdersPermission = permissions.includes(
        UserPermissionTypes.GLOBAL_VIEW_ORDERS
      );
      localStorage.setItem(
        'hasViewGlobalOrdersPermission',
        JSON.stringify(hasViewGlobalOrdersPermission)
      );
      dispatch(setCurrentUser({ user: currentUserResp }));
      initiateRegionSetup(currentUserResp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserResp]);

  useEffect(() => {
    setGlobal(checkIfGlobalRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const regionsLength = currentUser?.regions?.length;
    if (currentUser) {
      if (!currentUser.defaultRegion && regionsLength)
        setShowEditUserDetailsModal(true);
    }
  }, [currentUser]);

  const updatedRegionsOptions = (currentUser?.regions || [])
    ?.map((region: Region) => ({
      key: region.regionCode,
      displayName: `${region.regionCode} - ${region.regionName}`,
      value: region.regionCode
    }))
    ?.sort((a: any, b: any) => a?.key?.localeCompare(b?.key));

  const selectedRegionItem = updatedRegionsOptions?.find(
    (optionItem: any) => optionItem.key === currentRegion?.regionCode
  );

  const modifiedSelectedRegionOption = {
    key: selectedRegionItem?.key,
    value: selectedRegionItem,
    displayName: ''
  };

  const handleRegionChange = (optionItem: DropDownOption) => {
    if (currentUser && currentRegion)
      redirectToRegionURL(optionItem.key, false, currentRegion.regionCode);
  };

  const onAddedDefaultRegion = async (response: {
    data: User;
    error?: ValidationError;
  }) => {
    if (!extractedRegionCode.length) {
      // Note: If extracted code is empty, redirect to selected default region
      const { defaultRegion } = response.data;
      if (defaultRegion) {
        dispatch(setCurrentRegion({ region: defaultRegion }));
        redirectToRegionURL(defaultRegion.regionCode, true);
      }
    }
    setShowEditUserDetailsModal(false);
    fetchCurrentUser();
  };

  return (
    <div className={headerStyles.mainHeaderContainer}>
      <div className={headerStyles.actionsSection}>
        {isGlobalPage && updatedRegionsOptions?.length > 0 && (
          <Icon name="global-region" size="medium" className="mr-2" />
        )}
        {updatedRegionsOptions?.length > 0 && (
          <div className={headerStyles.regionSection}>
            <div
              data-testid={testId.mainHeader.region.regionName}
              className={headerStyles.regionName}
            >
              {isGlobalPage ? (
                <>
                  <span className="pr-2">Global</span>
                  <RegionClock currentRegion={currentRegion} />
                </>
              ) : (
                <>
                  <span className={headerStyles.regionCode}>
                    {selectedRegionItem?.key || ''}
                  </span>
                  <span className="pr-2">
                    {currentRegion?.regionName
                      ? `-${currentRegion.regionName}`
                      : 'Region'}
                  </span>
                  <RegionClock currentRegion={currentRegion} />
                </>
              )}
            </div>
            {!isGlobalPage && (
              <Dropdown
                title="Region"
                type="default"
                dropdownStyle="!mt-0 !-ml-2"
                inputStyle="w-[20px] border-none focus: !p-0 mr-3"
                dropdownMenuStyle="w-[160px] right-[0px]"
                options={updatedRegionsOptions}
                onClick={handleRegionChange}
                testId={testId.mainHeader.region.regionDropdown}
                selectedOption={modifiedSelectedRegionOption}
              />
            )}
          </div>
        )}
        {currentUser && <Profile name={currentUser.name} />}
        {showEditUserDetailsModal && (
          <SelectDefaultRegion
            open={showEditUserDetailsModal}
            handleCancel={() => {
              setShowEditUserDetailsModal(false);
            }}
            onAddedDefaultRegion={onAddedDefaultRegion}
            userDetails={currentUser as User}
          />
        )}
      </div>
    </div>
  );
};

export default MainHeader;
