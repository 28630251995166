import { Scalars } from '@types';

export enum TableFilterTypes {
  CHECKBOX = 'CHECKBOX',
  DATE_RANGE = 'DATE_RANGE',
  RADIO = 'RADIO',
  DATE_PICKER = 'DATE_PICKER'
}

export type Option = {
  name: Scalars['String'];
  value: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type DateType = {
  key: Scalars['String'];
  value: Scalars['Date'];
};

export type CheckboxFilterConfig = {
  name: string;
  key: string;
  type: TableFilterTypes;
  selectedOptionsCount?: number;
  enableSearch?: Scalars['Boolean'];
  noResultsText?: Scalars['String'];
  options?: Option[];
  defaultFilter?: Scalars['String'][];
};

export type RadioFilterConfig = {
  name: string;
  key: string;
  type: TableFilterTypes;
  enableSearch?: Scalars['Boolean'];
  noResultsText?: Scalars['String'];
  options?: Option[];
  defaultFilter?: Scalars['String'];
};

export interface CustomDateTypes {
  displayName: Scalars['String'];
  key: Scalars['String'];
  startDate?: Scalars['Date'];
  toDate?: Scalars['Date'];
  isSelected?: Scalars['Boolean'];
}

export type DateRangeFilterConfig = {
  name: string;
  key: string;
  type: TableFilterTypes;
  isDateSelected?: Scalars['Boolean'];
  fromDate: DateType;
  toDate: DateType;
  customDateOptions?: CustomDateTypes;
  defaultFilter?: {
    fromDate: DateType;
    toDate: DateType;
  };
};

export type TableFilterConfig =
  | CheckboxFilterConfig
  | DateRangeFilterConfig
  | RadioFilterConfig;

export type TableFilterConfigType = {
  [key: string]: TableFilterConfig;
};

export type AppliedFilterType = {
  [key: string]: any;
};
