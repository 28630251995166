import { useState } from 'react';

import { ConfirmationModal, IconButton } from '@components';
import { Scalars, User } from '@types';
import useNotify from '@hooks/notify';
import useTranslate from '@hooks/intl';
import useCheckPermission from '@hooks/checkPermission';
import UserPermissionTypes from '@constants/permissions';
import testId from '@constants/testId';

import EditUser from '../edit-user/EditUser';
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
  useLazyGetCurrentUserQuery
} from '../../queries/UsersQuery';

const AccessButtons = ({
  user,
  updateUsersList,
  isCurrentUser
}: {
  user: User;
  updateUsersList: () => void;
  isCurrentUser?: boolean;
}) => {
  const { checkPermission } = useCheckPermission();

  const [fetchCurrentUser] = useLazyGetCurrentUserQuery();
  const [showEditUser, setShowEditUser] = useState<Scalars['Boolean']>(false);
  const [showDeletetUser, setShowDeleteUser] =
    useState<Scalars['Boolean']>(false);
  const translate = useTranslate();

  const [updateUser, { isLoading: isEditingUser }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();

  const { createSuccessAlert, createErrorAlert } = useNotify();

  const onEditUser = async (
    name: Scalars['String'],
    role: Scalars['String'],
    regionIds: string[]
  ) => {
    const res = await updateUser({
      id: user.entityId,
      body: {
        name,
        roleId: role,
        regionIds
      }
    });
    if ('data' in res) {
      updateUsersList();
      if (isCurrentUser) fetchCurrentUser(); // this is to update header region dropdown and profile
      setShowEditUser(false);
      createSuccessAlert(translate('users.actionButton.update_alert'));
    } else if ('error' in res) createErrorAlert(res.error?.data?.message);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteUser = async () => {
    const res = await deleteUser(user.entityId);
    if ('data' in res) {
      updateUsersList();
      setShowDeleteUser(false);
      createSuccessAlert(translate('users.actionButton.delete_alert'));
    } else if ('error' in res) createErrorAlert(res.error?.data?.message);
  };

  // eslint-disable-next-line no-nested-ternary
  return (
    <>
      <div className="flex gap-3">
        {checkPermission([UserPermissionTypes.UPDATE_USER]) && (
          <IconButton
            handleClick={() => setShowEditUser(true)}
            data-testid={testId.users.actionButtons.editButton}
            iconName="edit"
            toolTipText={translate('users.actionButton.edit')}
          />
        )}

        {checkPermission([UserPermissionTypes.DELETE_USER]) && (
          <IconButton
            handleClick={() => setShowDeleteUser(true)}
            disabled={isCurrentUser}
            data-testid={testId.users.actionButtons.deleteButton}
            iconName="trash"
            toolTipText={translate('users.actionButton.delete_button')}
          />
        )}
      </div>
      {showEditUser && (
        <EditUser
          user={user}
          open={showEditUser}
          editUser={onEditUser}
          handleCancel={() => setShowEditUser(false)}
          isEditingUser={isEditingUser}
        />
      )}
      {showDeletetUser && (
        <ConfirmationModal
          open={showDeletetUser}
          title={translate('users.actionButton.delete_title')}
          message={translate('users.actionButton.delete_message', {
            name: `${user.name.trim()}`
          })}
          cancelButtonlabel={translate(
            'users.actionButton.delete.cancel_button'
          )}
          confirmButtonlabel={translate(
            'users.actionButton.delete.delete_button'
          )}
          handleCancel={() => setShowDeleteUser(false)}
          handleConfirm={() => onDeleteUser()}
          actionLoading={isDeletingUser}
        />
      )}
    </>
  );
};

export default AccessButtons;
