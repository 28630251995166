import { useEffect, useState } from 'react';
import clsx from 'clsx';

import useTranslate from '@hooks/intl';
import { useTheme } from '@contexts/theme';
import { Icon } from '@components';

import { SideNavItem } from './SideNavBar.config';

interface NavSectionInterface {
  item: SideNavItem;
  checkIsPathActive: (path: string) => boolean;
  handleNavigate: (path: string) => void;
  selectedItem?: SideNavItem;
  childPaths?: SideNavItem[];
}

const NavSection: React.FC<NavSectionInterface> = ({
  item,
  checkIsPathActive,
  handleNavigate,
  selectedItem,
  childPaths
}) => {
  const { nameKey, icon } = item;

  const translate = useTranslate();
  const { colors } = useTheme();

  const isMainNavItemActive = childPaths
    ? !!childPaths?.some((childPath) => checkIsPathActive(childPath.path))
    : checkIsPathActive(item.path);

  const [isOpen, setIsOpen] = useState<boolean>(isMainNavItemActive);
  const toggleNavSection = () => setIsOpen(!isOpen);

  const navWrapperStyles = clsx({
    'rounded-md': true,
    'bg-background90': isMainNavItemActive
  });

  const navSectionHeader = clsx({
    'text-body flex cursor-pointer rounded px-8 py-2.5 text-background30 hover:bg-background90':
      true,
    'text-body-semi !bg-primary !text-white': isMainNavItemActive
  });
  useEffect(() => {
    if (selectedItem?.id === item.id) toggleNavSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, item]);

  return (
    <div className={navWrapperStyles}>
      <div
        className={navSectionHeader}
        onClick={() =>
          childPaths ? toggleNavSection() : handleNavigate(item.path)
        }
        role="presentation"
      >
        {icon ? (
          <Icon
            name={icon}
            fill={isMainNavItemActive ? colors.white : colors.background30}
            size="extralarge"
            className="mr-2.5"
          />
        ) : null}
        <div className="flex w-full items-center justify-between">
          {translate(nameKey)}
          {isOpen && childPaths ? (
            <Icon
              name="chevron-top"
              size="extrasmall"
              stroke={colors.white}
              className="ml-3 mr-1"
            />
          ) : null}
        </div>
      </div>
      {childPaths && (
        <div
          className={`ml-12 ${
            isOpen ? 'my-1  h-auto py-2' : 'h-0 overflow-hidden'
          }`}
        >
          {childPaths?.map((childPath) => (
            <div
              className={`cursor-pointer border-l border-background30 py-2.5 pl-3 hover:text-white ${
                checkIsPathActive(childPath.path)
                  ? 'text-body-semi text-white'
                  : 'text-body text-background30'
              }`}
              key={childPath.id}
              onClick={() => handleNavigate(childPath.path)}
              role="presentation"
            >
              {translate(childPath.nameKey)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavSection;
