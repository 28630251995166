import clsx from 'clsx';
import { useState } from 'react';

import SearchBar from '../search-bar/SearchBar';

interface DoubleSearchbarProps {
  className?: string;
  onSearchLeft: (searchText: string) => void;
  onSearchRight: (searchText: string) => void;

  leftPlaceholder?: string;
  rightPlaceholder?: string;
  clearSearchData?: boolean;
  onClick: () => void;
  onLeftClearIconAction?: () => void;
  onRightClearIconAction?: () => void;
}
const MultiSearchbar = ({
  className,
  onSearchLeft,
  onSearchRight,
  onClick,

  leftPlaceholder,
  rightPlaceholder,
  clearSearchData,
  onLeftClearIconAction,
  onRightClearIconAction
}: DoubleSearchbarProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [leftSearchText, setLeftSearchText] = useState<string>('');
  const [rightSearchText, setRightSearchText] = useState<string>('');

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onSearchLeft(leftSearchText);
      onSearchRight(rightSearchText);
    }
  };

  return (
    <div
      className={clsx(
        'flex w-full rounded-10px border border-background10 hover:border-background90',
        isFocused && 'border-primary',
        className
      )}
      role="presentation"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setTimeout(() => setIsFocused(false), 300)}
      onKeyPress={(e) => handleKeyPress(e)}
    >
      <SearchBar
        onSearch={() => onSearchLeft(leftSearchText)}
        className="w-1/2"
        searchBarStyle={clsx('rounded-none rounded-l-10px border-0')}
        onClick={onClick}
        onChange={(e) => setLeftSearchText(e.target.value)}
        value={leftSearchText}
        placeholder={leftPlaceholder}
        onClearIconAction={() => {
          onLeftClearIconAction?.();
          setLeftSearchText('');
        }}
        clearSearchData={clearSearchData}
      />
      <div
        className="mt-0.5 border-[0.5px]"
        onClick={onClick}
        role="presentation"
      />
      <SearchBar
        onSearch={() => onSearchRight(rightSearchText)}
        className="w-1/2"
        searchBarStyle={clsx('rounded-none rounded-r-10px border-0')}
        onClick={onClick}
        onChange={(e) => setRightSearchText(e.target.value)}
        value={rightSearchText}
        placeholder={rightPlaceholder}
        onClearIconAction={() => {
          onRightClearIconAction?.();
          setRightSearchText('');
        }}
        clearSearchData={clearSearchData}
      />
    </div>
  );
};

export default MultiSearchbar;
