/* eslint-disable jsx-a11y/control-has-associated-label */
import { TooltipIcon } from '@components/tool-tip/Tooltip';
import { TableCol } from './types';

export const TableHead = ({
  colData,
  showDragIcon = false
}: {
  colData: TableCol[];
  showDragIcon?: boolean;
}) => (
  <thead className="bg-borderGrey/10">
    <tr>
      {showDragIcon && <th key="draggable" />}
      {colData.map((col) => (
        <th
          key={col.key}
          scope="col"
          className="text-body-semi px-6 py-3 text-left"
        >
          <span className="flex items-center gap-2">
            {col.title}
            {col.showInfoIcon && col.infoMssg && (
              <TooltipIcon text={col.infoMssg} />
            )}
          </span>
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHead;
