import {
  CustomFields,
  FormField,
  RuleCondition,
  RulePartnerRequest,
  RuleType,
  Scalars,
  TimeSlot
} from '@types';

export enum UpdateStatusOperation {
  ACTIVATE = 'ACTIVATION',
  DEACTIVATE = 'DEACTIVATION',
  UPDATE_DATE = 'UPDATE_DATE'
}

// auth
export type LoginRequest = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SetPasswordRequest = {
  password?: Scalars['String'];
  confirmPassword?: Scalars['String'];
  token?: Scalars['String'];
};

export type ResetPasswordRequest = SetPasswordRequest;

export type ValidationError = {
  [key: string]: string;
};

// user

export type UpdateUserStatus = {
  operation: UpdateStatusOperation;
};

export type UpdateAccountStatus = {
  operation: UpdateStatusOperation;
};

export type UpdateUser = {
  name: Scalars['String'];
  roleId: Scalars['String'];
  regionIds: string[];
};

export type UpdateUserStatusRequest = {
  body: UpdateUserStatus;
  id: Scalars['ID'];
};

export type UpdateAccountStatusRequest = {
  body: UpdateAccountStatus;
  id: Scalars['ID'];
};

export type UpdateUserRequest = {
  body: UpdateUser;
  id: Scalars['ID'];
};

export type CreateUserRequest = {
  email: Scalars['String'];
  name: Scalars['String'];
  roleId: Scalars['String'];
  regionIds: string[];
};

export type PasswordComponents = {
  minChar: Scalars['Boolean'];
  upperChar: Scalars['Boolean'];
  lowerChar: Scalars['Boolean'];
  specialChar: Scalars['Boolean'];
};

// rules

export type RuleOperationRequest = {
  operation: Scalars['String'];
};

export type UpdateRuleStatusRequest = {
  id: Scalars['ID'];
  body: RuleOperationRequest;
};

export type UpdateRuleBody = {
  rulePartner: RulePartnerRequest[];
  priority: Scalars['Int'];
  ruleCondition: Partial<RuleCondition>[];
  ruleName: Scalars['String'];
  ruleType: RuleType;
};

export type UpdateRuleRequest = {
  id: Scalars['ID'];
  body: UpdateRuleBody;
};

export type CreateRuleRequest = {
  rulePartner: RulePartnerRequest[];
  ruleCondition: Partial<RuleCondition>[];
  ruleName: Scalars['String'];
  ruleType: RuleType;
  priority: Scalars['Int'];
};

export type CreateCustomFieldRequest = {
  dataType: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateCustomFieldRequest = {
  dataType: Scalars['String'];
  name: Scalars['String'];
  entityId: Scalars['ID'];
};

export type UpdateRulePriority = {
  entityId: Scalars['ID'];
  priority: Scalars['Int'];
};

export type CreateCourierPartner = {
  partnerId: Scalars['ID'];
  accountCode: Scalars['String'];
  deliveryType: Scalars['String'];
};

export type UploadFileDetailsRequest = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  fileUploadType: Scalars['String'];
  parentEntity?: Scalars['String'];
};

export type UpdateFile = {
  contentType: Scalars['String'];
  url: Scalars['String'];
  data: BinaryData;
};

export type CreateRegionRequest = {
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
  zone: Scalars['String'];
  abbreviation: Scalars['String'];
  offset: Scalars['String'];
  isDomesticShippingOnly: Scalars['Boolean'];
};

export type UpdateRegion = {
  regionName: Scalars['String'];
  isDomesticShippingOnly: Scalars['Boolean'];
};

export type UpdateRegionRequest = {
  id: Scalars['ID'];
  body: UpdateRegion;
};

export type TimelineReportRequest = {
  awbs: Scalars['String'][];
  referenceNos: Scalars['String'][];
  orderIds: Scalars['String'][];
  createdDateFrom: Scalars['Date'];
  createdDateTo: Scalars['Date'];
  deliverDateFrom: Scalars['Date'];
  deliverDateTo: Scalars['Date'];
  pickupDateFrom: Scalars['Date'];
  pickupDateTo: Scalars['Date'];
  courierPartnerIds: Scalars['String'][] | undefined;
  statuses: Scalars['String'][] | undefined;
  notStatuses: Scalars['String'][] | undefined;
};

export type GenerateReportRequest = {
  awbs: Scalars['String'][] | undefined;
  courierPartnerIds: Scalars['String'][] | undefined;
  createdDateFrom: Scalars['Date'];
  createdDateTo: Scalars['Date'];
  customFields: CustomFields;
  deliverDateFrom: Scalars['Date'];
  deliverDateTo: Scalars['Date'];
  mailTo: Scalars['String'];
  orderIds: Scalars['String'][] | undefined;
  pickupDateFrom: Scalars['Date'];
  pickupDateTo: Scalars['Date'];
  referenceNos: Scalars['String'][] | undefined;
  reportName: Scalars['String'];
  statuses: Scalars['String'][] | undefined;
};

export type UpdateSelfDetails = {
  defaultRegionId?: Scalars['ID'];
  name?: Scalars['String'];
};

export type UpdateSelfDetailsRequest = {
  body: UpdateSelfDetails;
};

export type UpdateOrderStatus = {
  awb: Scalars['String'];
  remark: Scalars['String'];
  shipmentId: Scalars['ID'];
  status: Scalars['String'];
  statusUpdatedAt: Scalars['Date'];
};

export type UpdateAccountCapping = {
  maxCapping: Scalars['Int'];
};

export type UpdateAccountRequest = {
  body: UpdateAccountCapping;
  id: Scalars['ID'];
};

export type GetPartnerStatusRequest = {
  partnerCode: Scalars['String'];
  queryString: Scalars['String'];
};

export type PartnerStatusMappingRequest = {
  entityId?: Scalars['String'];
  boxatyStatus?: Scalars['String'];
  courierPartnerStatus?: Scalars['String'];
  description?: Scalars['String'];
};

export type UpdatePartnerStatusMappingRequest = {
  body: PartnerStatusMappingRequest[];
  partnerCode: Scalars['String'];
  queryString: Scalars['String'];
};

export type GetCredentialsRequest = {
  id: Scalars['ID'];
  queryString: Scalars['String'];
};

export type UpdateAccountCredentialsRequest = {
  credentials: FormField[];
  partnerCode: Scalars['String'];
  partnerAccountId: Scalars['String'];
};

export type OrderStatausResponse = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type RecommendationFieldType = {
  [key: string]:
    | Scalars['String']
    | Scalars['Float']
    | Scalars['Int']
    | Scalars['Boolean'];
};

export type UpdateTestRecommendation = {
  [key: string]:
    | Scalars['String']
    | Scalars['Float']
    | Scalars['Int']
    | Scalars['Boolean']
    | RecommendationFieldType;
};

export type TimeSlotRequest = {
  id: Scalars['ID'];
  accountId?: Scalars['ID'];
  body?: TimeSlot;
};

export type UpdateTimeSlotStatus = {
  operation: UpdateStatusOperation;
};

export type UpdateTimeSlotStatusRequest = {
  id: Scalars['ID'];
  accountId?: Scalars['ID'];
  body: UpdateTimeSlotStatus;
};

export type ShipmentLabelDownloadLinkResponse = {
  url: Scalars['String'];
  expiryInMinutes: Scalars['Int'];
};

export type QueryParams = {
  [key: string]: any;
};
