import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery
} from '@reduxjs/toolkit/dist/query/react';

import { API_URL, tags, THREEPL_URL, WEBHOOK_URL } from '@config';
import { extractRegionCodeFromUrl } from '@features/regions/helpers/helpers';

const fetchQ = fetchBaseQuery({
  baseUrl: `${API_URL}/`,
  prepareHeaders(headers) {
    return headers;
  },
  credentials: 'include'
});

// Will be replaced once api gateway implementation is completed
const fetchWebhook = fetchBaseQuery({
  baseUrl: `${WEBHOOK_URL}/`,
  prepareHeaders(headers) {
    return headers;
  },
  credentials: 'include'
});

const fetch3pl = fetchBaseQuery({
  baseUrl: `${THREEPL_URL}/`,
  prepareHeaders(headers) {
    return headers;
  },
  credentials: 'include'
});

const fetchWebhookRegionQ = fetchBaseQuery({
  baseUrl: `${WEBHOOK_URL}/`,
  prepareHeaders(headers) {
    const regionCode = extractRegionCodeFromUrl();
    if (regionCode) headers.set('x-region-code', regionCode?.toUpperCase());
    return headers;
  },
  credentials: 'include'
});

const fetch3plRegionQ = fetchBaseQuery({
  baseUrl: `${THREEPL_URL}/`,
  prepareHeaders(headers) {
    const regionCode = extractRegionCodeFromUrl();
    if (regionCode) headers.set('x-region-code', regionCode?.toUpperCase());
    return headers;
  },
  credentials: 'include'
});

const fetchRegionQ = fetchBaseQuery({
  baseUrl: `${API_URL}/`,
  prepareHeaders(headers) {
    const regionCode = extractRegionCodeFromUrl();
    if (regionCode) headers.set('x-region-code', regionCode?.toUpperCase());
    return headers;
  },
  credentials: 'include'
});

const baseQueryIncludedRegion: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchRegionQ(args, api, extraOptions);
  return result;
};

const baseQueryExcludedRegion: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchQ(args, api, extraOptions);
  return result;
};

const authQuery: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchQ(args, api, extraOptions);
  return result;
};

// Will be replaced once api gateway implementation is completed
const baseWebhookQueryExcludedRegion: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchWebhook(args, api, extraOptions);
  return result;
};

const baseWebhookQuery: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchWebhookRegionQ(args, api, extraOptions);
  return result;
};

const base3plQueryExcludedRegion: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetch3pl(args, api, extraOptions);
  return result;
};

const base3plQuery: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetch3plRegionQ(args, api, extraOptions);
  return result;
};

// Will be replaced once api gateway implementation is completed
export const baseWebhookApiExcludedRegion = createApi({
  reducerPath: 'global_webhook',
  baseQuery: baseWebhookQueryExcludedRegion,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const base3plApiExcludedRegion = createApi({
  reducerPath: 'global_3pl',
  baseQuery: base3plQueryExcludedRegion,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const base3plApi = createApi({
  reducerPath: '3pl',
  tagTypes: tags,
  baseQuery: base3plQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const baseWebhookApi = createApi({
  reducerPath: 'webhook',
  tagTypes: tags,
  baseQuery: baseWebhookQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryIncludedRegion,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: authQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false
});

export const baseApiExcludedRegion = createApi({
  reducerPath: 'global_region',
  baseQuery: baseQueryExcludedRegion,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});
