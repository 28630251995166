import { CourierPartner, Maybe, Scalars } from '@types';

export enum DeliveryType {
  FORWARD = 'FORWARD',
  REVERSE = 'REVERSE',
  EXCHANGE = 'EXCHANGE'
}
export type PartnerAccount = {
  accountCode: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  entityId: Scalars['ID'];
  partner: CourierPartner;
  status: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
  updatedBy: Scalars['String'];
  deliveryType: Scalars['String'];
  maxCapping: Scalars['Int'];
  globalAllocation: Scalars['Int'];
  regionCode?: Scalars['String'];
};
export type Partner = {
  entityId: Scalars['ID'];
  imageUrl: Scalars['String'];
  isForwardSupports: Scalars['Boolean'];
  isReverseSupports: Scalars['Boolean'];
  name: Scalars['String'];
  partnerCode: Scalars['String'];
};
export type ServiceablePartner = {
  accountCode: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  deliveryType: DeliveryType;
  entityId: Scalars['ID'];
  maxCapping: Scalars['Int'];
  partner: Partner;
  status: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
};

export type TimeSlot = {
  intervalStart: Scalars['String'];
  intervalEnd: Scalars['String'];
  status?: Scalars['Boolean'];
};

export type TimeSlotResponse = {
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  entityId: Scalars['ID'];
  intervalStart: Scalars['String'];
  intervalEnd: Scalars['String'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
  partnerAccount: ServiceablePartner;
};
