import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ROLE } from '@constants/routes';

const RolesList = lazy(() => import('@features/roles/pages/roles/RolesList'));
const ViewRole = lazy(() => import('@features/roles/pages/roles/ViewRole'));
const EditRole = lazy(() => import('@features/roles/pages/roles/EditRole'));

export const RolesRoutes: RouteObject[] = [
  {
    path: ROLE.ROLE_MANAGEMENT,
    children: [
      {
        path: '',
        element: <RolesList />
      },
      {
        path: ROLE.EDIT_ROLE,
        element: <EditRole />
      },
      {
        path: ROLE.CREATE_ROLE,
        element: <EditRole />
      },
      {
        path: ROLE.VIEW_ROLE,
        element: <ViewRole />
      }
    ]
  }
];
export default RolesRoutes;
