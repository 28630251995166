import type { FC } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Scalars } from '@types';
import { validateWithRegexp } from '@utils/generic';
import { ruleCappingRegex } from '@constants/regexps';
import { TooltipIcon } from '@components/tool-tip/Tooltip';
import useTranslate from '@hooks/intl';

import { DragMe } from './DragMe';

export interface Item {
  key: Scalars['String'];
  displayName: Scalars['String'];
  value: any;
}
type CapType = { card: Item; cappingValue?: string };

export interface DraggableContainerProps {
  items: Item[];
  title: string;
  handleReorder: (startIndex: number, endIndex: number) => void;
  handleDelete: (index: number) => void;
  handleCapping: (cappingValue: string, card: Item) => void;
  cappingValues: CapType[];
}

export const DraggableContainer: FC<DraggableContainerProps> = ({
  items,
  title,
  handleReorder,
  handleDelete,
  handleCapping,
  cappingValues,
  ...props
}) => {
  const translate = useTranslate();
  const renderCard = (card: Item, index: number) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.value === '' ||
        validateWithRegexp(ruleCappingRegex, event.target.value)
      ) {
        handleCapping(event.target.value, card);
      }
    };

    const cappingInput = cappingValues?.find(
      (data) => data.card.key === card.key
    )?.cappingValue;

    return (
      <DragMe
        key={card.key}
        index={index}
        id={card.key}
        moveCard={handleReorder}
        handleDelete={() => handleDelete(index)}
      >
        <span className="flex items-center justify-center">
          {card.displayName}
        </span>
        <input
          className="ml-auto mr-4 flex h-9 w-[95px] rounded-md border border-background10 px-2 text-center outline-none"
          onChange={onChange}
          value={cappingInput ? Number(cappingInput) : ''}
        />
      </DragMe>
    );
  };

  return (
    <DndProvider backend={HTML5Backend} {...props}>
      <div className="w-5/12 rounded-md bg-[#FAFAFA] p-4">
        <div className="flex">
          <span className="text-body-md pb-2.5 text-background70">{title}</span>
          <span className="relative ml-auto mr-10 flex pr-3">
            <span className="text-body-md mr-1.5 text-background70">
              {translate('rules.createRule.partnerAccounts.capping')}
            </span>
            <TooltipIcon
              text={translate(
                'rules.createRule.partnerAccounts.capping_tooltip'
              )}
              className="w-[220px]"
            />
          </span>
        </div>
        {items.map((item, i) => renderCard(item, i))}
      </div>
    </DndProvider>
  );
};
