import {
  ChartData,
  ToolTipListProps,
  TooltipHeaders,
  TooltipValue
} from '../types';

export const drawRect = (
  ctx: any,
  rectColor: string,
  startX: number,
  startY: number,
  width: number,
  height: number
) => {
  ctx.fillStyle = rectColor;
  ctx.beginPath();
  ctx.roundRect(startX, startY, width, height, [0, 0, 0, 0]);
  ctx.fill();
};

export const drawGraphGrid = (
  context: any,
  chartHeight: number,
  contextPadding: number,
  ctxWidth: number,
  maxCapping: number,
  verticalTitle = ''
) => {
  const lineHeight = chartHeight / 5;

  if (verticalTitle) {
    // y axis text
    context.beginPath();
    context.save();
    context.fillStyle = '#354453';
    context.textAlign = 'center';
    context.translate(10, chartHeight / 2);
    context.font = '600 12px Manrope';
    context.textAlign = 'right';
    context.rotate(-Math.PI / 2);
    context.fillText(verticalTitle, 0, 0, 200);
    context.restore();
  }

  [...Array(6)].forEach((_, index) => {
    context.beginPath();
    context.fillStyle = '#354453';
    context.fillText(
      ((maxCapping * (5 - index)) / 5).toString(),
      contextPadding - 30,
      contextPadding + lineHeight * index + 3
    );

    context.moveTo(contextPadding, contextPadding + lineHeight * index);
    context.lineTo(ctxWidth, contextPadding + lineHeight * index);
    context.strokeStyle = index === 5 ? '#BBC0C5' : '#E8EAEB';
    context.stroke();
  });
};

const getUtilizationPercentage = (
  primaryValue: number,
  secondaryValue?: number
): string => {
  if (secondaryValue) {
    if (primaryValue && secondaryValue > 0) {
      return `${Math.ceil((secondaryValue * 100) / primaryValue)} %`;
    }
    return '';
  }
  return '0 %';
};

export const getTooltipValues = (
  chartData: ChartData[],
  tooltipHeaders: TooltipHeaders,
  rectMargin: number,
  contextPadding: number,
  chartHeight: number,
  maxCapping: number,
  rectWidth: number,
  showSecondaryValue: boolean,
  secondaryColor?: string
) => {
  const tempList: ToolTipListProps[] = [];
  chartData.map((item, index) => {
    const tooltipValue: TooltipValue = {
      ...(tooltipHeaders.line1 && {
        line1: {
          text: tooltipHeaders.line1,
          value: item.value?.toString()
        }
      }),
      ...(tooltipHeaders.line2 && {
        line2: {
          text: tooltipHeaders.line2,
          value: item.secondaryValue?.toString()
        }
      }),
      // todo: update value according to input type % or sum or like that
      ...(tooltipHeaders.line3 && {
        line3: {
          text: tooltipHeaders.line3,
          value: getUtilizationPercentage(item.value, item.secondaryValue)
        }
      })
    };

    // Store values to tooltip array
    tempList.push({
      value: tooltipValue,
      xStart: index * rectMargin + contextPadding * 2,
      yStart:
        chartHeight + contextPadding - (item.value * chartHeight) / maxCapping,
      xEnd: index * rectMargin + contextPadding * 2 + rectWidth,
      yEnd: chartHeight + contextPadding
    });

    if (showSecondaryValue && secondaryColor && item.secondaryValue) {
      // Push secondary value to tooltip array
      tempList.push({
        value: tooltipValue,
        xStart: index * rectMargin + contextPadding * 2 + rectWidth / 2,
        yStart:
          chartHeight +
          contextPadding -
          (item.secondaryValue * chartHeight) / maxCapping,
        xEnd: index * rectMargin + contextPadding * 2 + rectWidth * 1.5,
        yEnd: chartHeight + contextPadding
      });
    }

    return null;
  });

  return tempList;
};

export const drawChartCategories = (
  context: any,
  xStart: number,
  text: string,
  rectWidth: number,
  rectMargin: number,
  height: number
) => {
  // item name on bottom
  context.beginPath();
  context.save();
  context.fillStyle = '#354453';
  context.textAlign = 'center';
  context.translate(xStart + rectWidth, height + 20);
  context.font = '400 12px Manrope';
  context.textAlign = 'right';
  context.rotate(-Math.PI / 4);
  context.fillText(text, 0, 0, rectMargin);
  context.restore();
  // pointer for name on bar chart
  context.moveTo(xStart + rectWidth / 2, height);
  context.lineTo(xStart + rectWidth / 2, height + 5);
  context.strokeStyle = '#D3D3D3';
  context.stroke();
};
