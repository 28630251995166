import React from 'react';

import Button from '@components/button/Button';
import Chip from '@components/chip/Chip';
import { TableFilterConfig, TableFilterTypes } from '@types';
import useTranslate from '@hooks/intl';

const FILTERS_TO_SHOW = 2;

interface FilterChipsProps {
  resetFilters: () => void;
  onRemoveFilter: (value: TableFilterConfig) => void;
  selectedFilters: TableFilterConfig[];
  toggleFilterPopup: () => void;
}

const FilterChips: React.FC<FilterChipsProps> = ({
  resetFilters,
  onRemoveFilter,
  selectedFilters,
  toggleFilterPopup
}) => {
  const translate = useTranslate();
  return (
    <>
      {selectedFilters?.slice(0, FILTERS_TO_SHOW)?.map((filter) => (
        <Chip
          label={filter.name}
          onRemove={() => {
            onRemoveFilter(filter);
          }}
          className="ml-2 max-w-[200px] py-3"
          labelStyle="truncate"
          icon={filter.type === TableFilterTypes.DATE_RANGE ? 'calendar' : ''}
          chipType="SECONDARY"
          closeButtonSize="extrasmall"
          key={filter.name}
        />
      ))}
      {!!selectedFilters?.length && (
        <>
          {selectedFilters.length > FILTERS_TO_SHOW && (
            <div className="border-r-2 px-2">
              <Button
                handleClick={toggleFilterPopup}
                variant="transparent"
                label={`+ ${selectedFilters.length - FILTERS_TO_SHOW} more`}
                className="text-primary"
                size="none"
              />
            </div>
          )}
          <div className="px-2">
            <Button
              handleClick={resetFilters}
              variant="transparent"
              label={translate('component.filter.reset_Filter')}
              className="text-primary hover:text-primary70"
              size="none"
            />
          </div>
        </>
      )}
    </>
  );
};

export default FilterChips;
