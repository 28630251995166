import { Dispatch, SetStateAction } from 'react';
import { Checkbox } from '@components/index';
import { Region } from '@types';
import useTranslate from '@hooks/intl';

interface MyRegionsCardProp {
  region: Region;
  selectedRegionId: string;
  setSelectedRegionId: Dispatch<SetStateAction<string>>;
  className?: string;
  onUpdateDefaultRegion?: (checkedValue: string) => void;
  defaultRegion?: Region;
}

const MyRegionsCard: React.FC<MyRegionsCardProp> = ({
  region,
  selectedRegionId,
  setSelectedRegionId,
  className,
  onUpdateDefaultRegion,
  defaultRegion,
  ...props
}) => {
  const translate = useTranslate();

  const isCurrentCheckboxChecked = selectedRegionId
    ? region.entityId === selectedRegionId
    : region.entityId === defaultRegion?.entityId;

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedState = event.target.checked;
    const checkedValue = event.target.value;
    if (checkedState) {
      setSelectedRegionId(checkedValue);
      if (onUpdateDefaultRegion) onUpdateDefaultRegion(checkedValue);
    }
  };

  return (
    <div
      className={`${
        isCurrentCheckboxChecked ? 'bg-primary10' : 'bg-white'
      } relative mb-6 flex h-20 w-56 items-center rounded-10px border-2 ${className}`}
    >
      {isCurrentCheckboxChecked && (
        <div className="absolute top-2 right-2 rounded-7px bg-white px-1 text-xs text-background70">
          <span> {translate('myAccount.default')}</span>
        </div>
      )}
      <div className="flex h-full w-full px-4">
        <Checkbox
          checkboxSize="large"
          checked={isCurrentCheckboxChecked}
          value={region.entityId}
          onChange={(event) => onChange(event)}
          {...props}
        />
        <div className="flex items-center justify-center truncate">
          <span className="mr-1 font-bold">{region.regionCode}</span>
          <div className="truncate"> {`- ${region.regionName}`}</div>
        </div>
      </div>
    </div>
  );
};

export default MyRegionsCard;
