import { useState, useEffect } from 'react';
import { useTheme } from '@contexts/theme';
import './styles.scss';
import { IconButton } from '../button/Button';

enum TimeInputTypes {
  ADD = 'ADD',
  SUB = 'SUB'
}

interface TimeUnitInputProps {
  setFinalValue: (value: number) => void;
  max?: number;
  className?: string;
  initialValue?: number;
}

const TimeUnitInput: React.FC<TimeUnitInputProps> = ({
  setFinalValue,
  max = 59,
  className = '',
  initialValue
}) => {
  const [value, setValue] = useState<string>('00');
  const { colors } = useTheme();
  useEffect(() => {
    setFinalValue(Number(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (initialValue) setValue(String(initialValue)?.padStart(2, '0'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChangeValue = (type: TimeInputTypes) => {
    if (type === TimeInputTypes.ADD) {
      if (Number(value) >= max) setValue('00');
      else
        setValue((prevValue) =>
          String(Number(prevValue) + 1)?.padStart(2, '0')
        );
    }

    if (type === TimeInputTypes.SUB) {
      if (Number(value) <= 0) setValue(String(Number(max)));
      else
        setValue((prevValue) =>
          String(Number(prevValue) - 1)?.padStart(2, '0')
        );
    }
  };

  return (
    <div className={className}>
      <div className="mr-8 flex flex-col items-center">
        <IconButton
          handleClick={() => onChangeValue(TimeInputTypes.ADD)}
          iconName="chevron-top"
          stroke={colors.background70}
        />
        <div className="my-2">{value}</div>
        <IconButton
          handleClick={() => {
            onChangeValue(TimeInputTypes.SUB);
          }}
          iconName="chevron-down"
          stroke={colors.background70}
        />
      </div>
    </div>
  );
};

export default TimeUnitInput;
