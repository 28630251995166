import { TableFilterTypes } from '@types';
import SelectorCalender from './SelectorCalender';
import SelectorCheckbox from './SelectorCheckbox';
import SelectorRadio from './SelectorRadio';

interface FilterTypeSelectorProps {
  filterState: any;
  setFilterState: any;
  selectedFilterKey: string;
}

const FilterTypeSelector: React.FC<FilterTypeSelectorProps> = ({
  filterState,
  selectedFilterKey,
  setFilterState
}) => {
  const openedFilter = filterState[selectedFilterKey];

  switch (openedFilter?.type) {
    case TableFilterTypes.CHECKBOX:
      return (
        <SelectorCheckbox
          handleFilterSelection={setFilterState}
          options={openedFilter.options}
          name={openedFilter.key}
          key={openedFilter.key}
          filterState={filterState}
          searchEnabled={openedFilter.enableSearch}
          noResultsText={openedFilter.noResultsText}
          searchPlaceholder={openedFilter.searchPlaceholder}
        />
      );
    case TableFilterTypes.DATE_RANGE:
      return (
        <SelectorCalender
          handleFilterSelection={setFilterState}
          fromDate={openedFilter.fromDate}
          toDate={openedFilter.toDate}
          filterState={filterState}
          name={openedFilter.key}
        />
      );
    case TableFilterTypes.RADIO:
      return (
        <SelectorRadio
          handleFilterSelection={setFilterState}
          options={openedFilter.options}
          name={openedFilter.key}
          filterState={filterState}
        />
      );
    case TableFilterTypes.DATE_PICKER:
      return (
        <SelectorCalender
          handleFilterSelection={setFilterState}
          fromDate={openedFilter.fromDate}
          toDate={openedFilter.toDate}
          filterState={filterState}
          name={openedFilter.key}
          isDatePicker
        />
      );
    default:
      return null;
  }
};

export default FilterTypeSelector;
