import {
  ALLOCATION,
  COURIER,
  HOME,
  MY_ACCOUNT,
  ORDERS,
  REGION,
  ROLE,
  USER,
  WEBHOOK,
  WAREHOUSE,
  GETLOG,
  DASHBOARD
} from '@constants/routes';

export type Breadcrumb = { path: string; name: string };

export const breadcrumbsMap: Map<string, Breadcrumb[]> = new Map([
  [
    DASHBOARD.REGION_DASHBOARD,
    [
      { name: 'Home', path: HOME },
      { name: 'Region Dashboard', path: DASHBOARD.REGION_DASHBOARD }
    ]
  ],
  [
    DASHBOARD.GLOBAL_DASHBOARD,
    [
      { name: 'Home', path: HOME },
      { name: 'Global Dashboard', path: DASHBOARD.GLOBAL_DASHBOARD }
    ]
  ],
  [
    USER.USER_MANAGEMENT,
    [
      { name: 'Home', path: HOME },
      { name: 'Users', path: USER.USER_MANAGEMENT }
    ]
  ],
  [
    ROLE.ROLE_MANAGEMENT,
    [
      { name: 'Home', path: HOME },
      { name: 'Roles', path: ROLE.ROLE_MANAGEMENT }
    ]
  ],
  [
    ROLE.VIEW_ROLE,
    [
      { name: 'Home', path: HOME },
      { name: 'Roles', path: ROLE.ROLE_MANAGEMENT },
      { name: 'View Role', path: ROLE.VIEW_ROLE }
    ]
  ],
  [
    ROLE.CREATE_ROLE,
    [
      { name: 'Home', path: HOME },
      { name: 'Roles', path: ROLE.ROLE_MANAGEMENT },
      { name: 'Add Role', path: ROLE.CREATE_ROLE }
    ]
  ],
  [
    ROLE.EDIT_ROLE,
    [
      { name: 'Home', path: HOME },
      { name: 'Roles', path: ROLE.ROLE_MANAGEMENT },
      { name: 'Edit Role', path: ROLE.EDIT_ROLE }
    ]
  ],
  [
    REGION.REGION_MANAGEMENT,
    [
      { name: 'Home', path: HOME },
      { name: 'Regions', path: REGION.REGION_MANAGEMENT }
    ]
  ],
  [
    COURIER.PARTNERS,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS }
    ]
  ],
  [
    COURIER.PARTNER_ACCOUNT,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS },
      { name: 'Configured Accounts', path: COURIER.PARTNER_ACCOUNT }
    ]
  ],
  [
    COURIER.PARTNER_ACCOUNT_DETAIL,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS },
      { name: 'Configured Accounts', path: COURIER.PARTNER_ACCOUNT },
      { name: 'Account Configuration', path: COURIER.PARTNER_ACCOUNT_DETAIL }
    ]
  ],
  [
    COURIER.PARTNER_ACCOUNT_SERVICEABILITY,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS },
      { name: 'Configured Accounts', path: COURIER.PARTNER_ACCOUNT },
      {
        name: 'Account Configuration',
        path: COURIER.PARTNER_ACCOUNT_DETAIL
      },
      {
        name: 'Serviceability',
        path: COURIER.PARTNER_ACCOUNT_SERVICEABILITY
      }
    ]
  ],
  [
    COURIER.PARTNER_ACCOUNT_CREDENTIALS,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS },
      { name: 'Configured Accounts', path: COURIER.PARTNER_ACCOUNT },
      {
        name: 'Account Configuration',
        path: COURIER.PARTNER_ACCOUNT_DETAIL
      },
      {
        name: 'Credentials',
        path: COURIER.PARTNER_ACCOUNT_CREDENTIALS
      }
    ]
  ],
  [
    COURIER.CREATE_COURIER_ACCOUNT,
    [
      { name: 'Home', path: HOME },
      { name: 'Integrations', path: COURIER.PARTNERS },
      { name: 'Configured Accounts', path: COURIER.PARTNER_ACCOUNT },
      { name: 'Add a Courier Account', path: COURIER.CREATE_COURIER_ACCOUNT }
    ]
  ],

  [
    COURIER.CHECK_SERVICEABILITY,
    [
      { name: 'Home', path: HOME },
      { name: 'Check Serviceability', path: COURIER.CHECK_SERVICEABILITY }
    ]
  ],
  [
    COURIER.ADDRESS_MAPPER,
    [
      { name: 'Home', path: HOME },
      { name: 'Address Mapper', path: COURIER.ADDRESS_MAPPER }
    ]
  ],
  [
    COURIER.ADDRESS_MAPPER_UPLOAD,
    [
      { name: 'Home', path: HOME },
      { name: 'Address Mapper', path: COURIER.ADDRESS_MAPPER },
      { name: 'Upload', path: COURIER.ADDRESS_MAPPER_UPLOAD }
    ]
  ],
  [
    COURIER.STATUS_MAPPING,
    [
      { name: 'Home', path: HOME },
      { name: 'Status Mapping', path: COURIER.STATUS_MAPPING }
    ]
  ],
  [
    ORDERS.ORDERS,
    [
      { name: 'Home', path: HOME },
      { name: 'Track', path: ORDERS.ORDERS }
    ]
  ],
  [
    ORDERS.GLOBAL_ORDERS,
    [
      { name: 'Home', path: HOME },
      { name: 'Global Track', path: ORDERS.GLOBAL_ORDERS }
    ]
  ],
  [
    ORDERS.GLOBAL_GENERATE_REPORT,
    [
      { name: 'Home', path: HOME },
      { name: 'Global Track', path: ORDERS.GLOBAL_ORDERS },
      { name: 'Generate Report', path: ORDERS.GLOBAL_GENERATE_REPORT }
    ]
  ],
  [
    ORDERS.GLOBAL_ORDER_DETAILS,
    [
      { name: 'Home', path: HOME },
      { name: 'Global Track', path: ORDERS.GLOBAL_ORDERS },
      { name: 'Order Details', path: ORDERS.GLOBAL_ORDER_DETAILS }
    ]
  ],
  [
    ORDERS.ORDER_DETAILS,
    [
      { name: 'Home', path: HOME },
      { name: 'Track', path: ORDERS.ORDERS },
      { name: 'Order Details', path: ORDERS.ORDER_DETAILS }
    ]
  ],
  [
    ORDERS.GENERATE_REPORT,
    [
      { name: 'Home', path: HOME },
      { name: 'Track', path: ORDERS.ORDERS },
      { name: 'Generate Report', path: ORDERS.GENERATE_REPORT }
    ]
  ],
  [
    ALLOCATION.RULE,
    [
      { name: 'Home', path: HOME },
      { name: 'Rules', path: ALLOCATION.RULE }
    ]
  ],
  [
    ALLOCATION.CREATE_RULE,
    [
      { name: 'Home', path: HOME },
      { name: 'Rules', path: ALLOCATION.RULE },
      { name: 'Add Rule', path: ALLOCATION.CREATE_RULE }
    ]
  ],
  [
    ALLOCATION.EDIT_RULE,
    [
      { name: 'Home', path: HOME },
      { name: 'Rules', path: ALLOCATION.RULE },
      { name: 'Edit Rule', path: ALLOCATION.EDIT_RULE }
    ]
  ],
  [
    COURIER.RECOMMENDATION,
    [
      { name: 'Home', path: HOME },
      { name: 'Recommendation', path: COURIER.RECOMMENDATION }
    ]
  ],
  [
    ORDERS.BULK_ORDER_UPLOAD,
    [
      { name: 'Home', path: HOME },
      { name: 'Upload', path: ORDERS.BULK_ORDER_UPLOAD }
    ]
  ],
  [
    MY_ACCOUNT,
    [
      { name: 'Home', path: HOME },
      { name: 'My Account', path: MY_ACCOUNT }
    ]
  ],
  [
    ORDERS.MANUAL_STATUS_UPDATE,
    [
      { name: 'Home', path: HOME },
      { name: 'Status Update', path: ORDERS.MANUAL_STATUS_UPDATE }
    ]
  ],
  [
    WEBHOOK.WEBHOOK,
    [
      { name: 'Home', path: HOME },
      { name: 'Webhooks', path: WEBHOOK.WEBHOOK }
    ]
  ],
  [
    WEBHOOK.ADD_WEBHOOK,
    [
      { name: 'Home', path: HOME },
      { name: 'Webhooks', path: WEBHOOK.WEBHOOK },
      { name: 'Add Webhook', path: WEBHOOK.ADD_WEBHOOK }
    ]
  ],
  [
    WEBHOOK.EDIT_WEBHOOK,
    [
      { name: 'Home', path: HOME },
      { name: 'Webhooks', path: WEBHOOK.WEBHOOK },
      { name: 'Edit Webhook', path: WEBHOOK.EDIT_WEBHOOK }
    ]
  ],
  [
    WAREHOUSE.WAREHOUSE_MANAGEMENT,
    [
      { name: 'Home', path: HOME },
      { name: 'Warehouse', path: WAREHOUSE.WAREHOUSE_MANAGEMENT }
    ]
  ],
  [
    WAREHOUSE.CREATE_WAREHOUSE,
    [
      { name: 'Home', path: HOME },
      { name: 'Warehouse', path: WAREHOUSE.WAREHOUSE_MANAGEMENT },
      { name: 'Add Warehouse', path: WAREHOUSE.CREATE_WAREHOUSE }
    ]
  ],
  [
    WAREHOUSE.EDIT_WAREHOUSE,
    [
      { name: 'Home', path: HOME },
      { name: 'Warehouse', path: WAREHOUSE.WAREHOUSE_MANAGEMENT },
      { name: 'Edit Warehouse', path: WAREHOUSE.EDIT_WAREHOUSE }
    ]
  ],
  [
    GETLOG.GETLOG_MANAGEMENT,
    [
      { name: 'Home', path: HOME },
      { name: 'Getlog', path: GETLOG.GETLOG_MANAGEMENT }
    ]
  ]
]);
