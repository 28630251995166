const dateFormat = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  }).format(date);
  return formattedDate;
};

export const dateFormatMonth = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    timeZone
  }).format(date);
  return formattedDate;
};

export const dateFormatMonthShort = (date: Date) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short'
  }).format(date);
  return formattedDate;
};

export const dateFormatHyphen = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
    timeZone
  }).format(date);
  return formattedDate?.replace(/\//g, '-');
};

export const dateFormatFullYear = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
    timeZone
  }).format(date);
  return formattedDate?.replace(/,/g, '');
};

export const regionTimeFormat = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  }).format(date);
  return formattedDate?.replace(/,/g, '');
};

export const nextDate = (date: string | Date, days: number = 1): Date =>
  new Date(new Date(date).getTime() + 1000 * 60 * 60 * 24 * days);

export const UTCDateFormat = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  }).format(date);
  return formattedDate?.replace(/,/g, '');
};

export const dateFormatWithSecond = (date: Date, timeZone: string) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone
  }).format(date);
  return formattedDate?.replace(/\//g, '-')?.replace(/,/g, ' ');
};

export const convertStringToFormattedDate = (date: String) => {
  // input string format dd-MM-yyyy HH:mm:ss
  // Adding slash for conact instead of - for safari support
  const dateParts = date.split('-');
  const formattedDate = new Date(
    dateParts?.[1]
      .concat('/', dateParts?.[0])
      .concat('/', dateParts?.[2])
      .concat(' GMT')
  );
  return formattedDate;
};

export const convertDateToString = (date: Date) => {
  // Export in YYYY-MM-DD
  const dateMonth =
    date.getMonth() > 9 ? date.getMonth() : `0${date.getMonth()}`;
  const dateDay = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  return `${date.getFullYear()}-${dateMonth}-${dateDay}`;
};

export const FormattedFilterDate = (fromDate: Date, toDate: Date) => {
  const formattedFromDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short'
  }).format(fromDate);
  const formattedToDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short'
  }).format(toDate);
  return `${formattedFromDate} - ${formattedToDate}`;
};

export default dateFormat;
