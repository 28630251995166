/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { ReactNode, SyntheticEvent, useState } from 'react';
import useTranslate from '@hooks/intl';
import Checkbox from '@components/checkbox/Checkbox';
import Icon from '@components/icon/Icon';
import { useTheme } from '@contexts/theme';

export type AccordionType = 'default' | 'step' | 'selector' | 'custom';

export interface AccordionProps {
  open: boolean;
  title?: string;
  children?: any;
  type?: AccordionType;
  stepCompleted?: boolean;
  itemsSelected?: number;
  className?: string;
  handleCheckBoxChange?: (e: any) => void;
  accordionBodyStyle?: string;
  checked?: boolean;
  customHeader?: ReactNode;
  customHeaderStyle?: string;
  onOpenAccordionStyle?: string;
}

export interface AccordionBodyProps {
  isOpen: boolean;
  children: any;
  accordionBodyStyle?: string;
  onOpenAccordionStyle?: string;
}

interface AccordionHeaderProps {
  title?: string;
  type?: AccordionType;
  isOpen: boolean;
  stepCompleted?: boolean;
  itemsSelected?: number;
  toggleAccordion: () => void;
  handleCheckBoxChange?: (e: any) => void;
  checked?: boolean;
  customHeader?: ReactNode;
  customHeaderStyle?: string;
}

const AccordionHeader = ({
  toggleAccordion,
  title,
  type = 'default',
  stepCompleted,
  isOpen,
  itemsSelected = 0,
  handleCheckBoxChange,
  checked,
  customHeader,
  customHeaderStyle
}: AccordionHeaderProps) => {
  const onCheckboxChange = (e: SyntheticEvent) => {
    if (handleCheckBoxChange) {
      handleCheckBoxChange(e);
    }
  };
  const translate = useTranslate();
  const { colors } = useTheme();
  switch (type) {
    case 'step':
      return (
        <div
          onClick={toggleAccordion}
          className="relative flex w-full cursor-pointer items-center py-7"
        >
          <Icon
            name="chevron-right"
            className={`${isOpen ? 'rotate-90' : ''} col-span-1 ml-2`}
            stroke={colors.background90}
          />
          <span className="text-body-base-bold col-auto pl-3.5">{title}</span>
          <Icon
            name="circle-tick"
            size="large"
            className="absolute right-0 mt-1"
            fill={stepCompleted ? '#389E0D' : '#D2D5DD'}
          />
        </div>
      );
    case 'selector':
      return (
        <>
          <div
            onClick={toggleAccordion}
            className="relative flex w-full cursor-pointer items-center py-7"
          >
            <Checkbox
              handleChange={onCheckboxChange}
              onClick={(e) => e.stopPropagation()}
              checkboxSize="large"
              checked={checked}
            />
            <span className="text-body-semi">{title}</span>
            <span
              className={clsx(
                'text-body-md absolute right-6 mr-4 rounded px-2 py-1',
                itemsSelected && itemsSelected > 0
                  ? 'bg-primary10 text-primary'
                  : 'bg-background10 text-background'
              )}
            >
              {itemsSelected} {translate('component.accordion.itemsSelected')}
            </span>
            <Icon
              name="chevron-right"
              className={`${
                isOpen ? '-rotate-90' : 'rotate-90'
              } absolute right-0 ml-2`}
              stroke={colors.background90}
            />
          </div>
          <div className="flex-col">
            {isOpen === true ? (
              <div className="border-t border-background10" />
            ) : null}
          </div>
        </>
      );
    case 'custom':
      return (
        <div
          onClick={toggleAccordion}
          className={`cursor-pointer ${
            isOpen && 'border-b border-background10 '
          } ${customHeaderStyle}`}
        >
          <Icon
            name="chevron-right"
            className={`${isOpen ? 'rotate-90' : ''}`}
            stroke={isOpen ? colors.primary : colors.background90}
          />
          {customHeader}
        </div>
      );
    default:
      return (
        <div
          onClick={toggleAccordion}
          className="relative flex w-full cursor-pointer items-center py-7"
        >
          <Icon
            name="chevron-right"
            className={`${isOpen ? 'rotate-90' : ''} ml-2`}
            stroke={colors.background90}
          />
          <span className="text-body-base-bold pl-3.5">{title}</span>
        </div>
      );
  }
};

const AccordionBody: React.FC<AccordionBodyProps> = ({
  children,
  isOpen,
  onOpenAccordionStyle,
  accordionBodyStyle
}) => (
  <div
    className={` cursor-default transition ease-in-out ${
      isOpen ? `h-auto ${onOpenAccordionStyle}` : 'h-0 overflow-hidden'
    } ${accordionBodyStyle}`}
  >
    {children}
  </div>
);

const Accordion: React.FC<AccordionProps> = ({
  open,
  title,
  children,
  type = 'default',
  stepCompleted,
  itemsSelected,
  className,
  handleCheckBoxChange,
  accordionBodyStyle,
  checked,
  customHeader,
  customHeaderStyle,
  onOpenAccordionStyle,
  ...props
}) => {
  const [expand, setExpand] = useState<boolean>(open);

  const toggleAccordion = () => setExpand(!expand);

  return (
    <div
      className={`cursor-default rounded-10px border border-background10 px-4 ${className}`}
      {...props}
    >
      <AccordionHeader
        toggleAccordion={toggleAccordion}
        title={title}
        type={type}
        isOpen={expand}
        stepCompleted={stepCompleted}
        itemsSelected={itemsSelected}
        handleCheckBoxChange={handleCheckBoxChange}
        checked={checked}
        customHeader={customHeader}
        customHeaderStyle={customHeaderStyle}
      />
      <AccordionBody
        isOpen={expand}
        accordionBodyStyle={accordionBodyStyle}
        onOpenAccordionStyle={onOpenAccordionStyle}
      >
        {children}
      </AccordionBody>
    </div>
  );
};

export default Accordion;
