import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import useTranslate from '@hooks/intl';
import Spinner from '@components/spinner/Spinner';
import ConfirmationModal from '@layouts/modal/ConfirmationModal';

export type SwitchVariant = 'light' | 'dark';

export interface SwitchInterface {
  handleClick: (val: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  variant?: SwitchVariant;
  label?: string;
  requireModal?: boolean;
  displayName?: string;
}
const Switch: React.FC<SwitchInterface> = ({
  handleClick,
  value = true,
  disabled = false,
  loading = false,
  className,
  variant = 'light',
  label,
  requireModal = false,
  displayName,
  ...props
}) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const translate = useTranslate();

  const toggleSwitch = () => {
    if (!disabled && !loading) {
      if (requireModal) {
        if (!value) handleClick(!value);
        else setShowConfirmationModal(true);
      } else {
        handleClick(!value);
      }
    }
  };

  const containerClassNames = 'flex h-6 items-center justify-center';
  const buttonClassNames = clsx(
    'flex h-6 w-10 cursor-pointer items-center rounded-3xl p-1',
    disabled && 'cursor-not-allowed opacity-50'
  );

  const buttonVariantStyles = {
    dark: value ? 'bg-primary' : 'bg-background90',
    light: value ? 'bg-primary10' : 'bg-background10'
  };

  const switchVariantStyles = {
    dark: value ? 'translate-x-4 bg-primary10' : 'bg-white',
    light: value ? 'translate-x-4 bg-primary' : 'bg-background50'
  };

  const switchClassNames = 'h-4 w-4 rounded-3xl duration-300 ease-in-out';

  return loading ? (
    <div className="w-10">
      <Spinner />
    </div>
  ) : (
    <div className={clsx(containerClassNames, className)}>
      <button
        type="button"
        className={clsx(buttonClassNames, buttonVariantStyles[variant])}
        onClick={toggleSwitch}
        {...props}
      >
        <div className={clsx(switchClassNames, switchVariantStyles[variant])} />
      </button>
      <span
        className={clsx(
          'text-body-md pl-2.5',
          value ? 'text-background90' : 'text-background70'
        )}
      >
        {label}
      </span>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          title={translate('component.switch.modal_title')}
          message={
            <FormattedMessage
              id="delete_message"
              defaultMessage={translate('component.switch.modal_desc')}
              values={{
                name: <strong className="text-black">{displayName}</strong>
              }}
            />
          }
          cancelButtonlabel={translate('component.switch.cancel_button')}
          confirmButtonlabel={translate('component.switch.confirm_button')}
          handleCancel={() => setShowConfirmationModal(false)}
          handleConfirm={() => {
            handleClick(!value);
            setShowConfirmationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Switch;
