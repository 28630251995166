import { createContext, ReactNode, useContext, useMemo } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

// Giving type as any here since type generated by tailwindcss is not proper
type Theme = any;

const ThemeContext = createContext<Theme>({});

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const themeValue = useMemo(
    () => ({ colors: fullConfig?.theme?.colors ?? {} }),
    []
  );

  return (
    <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { ThemeProvider, useTheme };
