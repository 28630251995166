import { FieldOperatorEnum, Field, Scalars } from '@types';

export enum JoinCondition {
  AND = 'AND',
  OR = 'OR'
}

export enum RuleType {
  FILTERED = 'FILTERED',
  ORDERED = 'ORDERED'
}

export enum RuleStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type RuleCondition = {
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  field: Field;
  fieldOperator: FieldOperatorEnum;
  fieldValue: Scalars['String'];
  entityId: Scalars['ID'];
  joinCondition: JoinCondition;
  position: Scalars['Int'];
  updatedAt: Scalars['Date'];
  updatedBy: Scalars['ID'];
};

export type RulePartner = {
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  deletedAt: Scalars['Date'];
  entityId: Scalars['ID'];
  partnerAccountCode: Scalars['ID'];
  priority: Scalars['Int'];
  updatedAt: Scalars['Date'];
  updatedBy: Scalars['ID'];
};

export type Rule = {
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  deletedAt: Scalars['Date'];
  entityId: Scalars['ID'];
  priority: Scalars['Int'];
  ruleConditions: RuleCondition[];
  ruleName: Scalars['String'];
  rulePartners: RulePartner[];
  status: Scalars['Boolean'];
  tenantId: Scalars['ID'];
  type: RuleType;
  updatedAt: Scalars['Date'];
  updatedBy: Scalars['ID'];
};

export type RulePartnerRequest = {
  distributionCapping?: Scalars['Int'];
  partnerAccountCode: Scalars['String'];
};
