import { forwardRef, useState } from 'react';
import clsx from 'clsx';
import Icon from '@components/icon/Icon';
import { useTheme } from '@contexts/theme';
import './style.css';

type CheckboxSize = 'small' | 'large';

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  checkboxSize?: CheckboxSize;
  handleChange?: (e: any) => void;
  label?: string;
  checkboxStyle?: string;
  labelStyle?: string;
  disabled?: boolean;
  className?: string;
  labelCode?: string;
  labelCodeStyles?: string;
  error?: boolean;
  errorMessage?: string;
  errorStyle?: string;
  htmlKey?: string;
}

const variantStyles = {
  small: `w-3.5 h-3.5`,
  large: `w-[18px] h-[18px]`
};

const commonCheckboxStyles = `appearance-none border-[1px] cursor-pointer rounded border-background30 checked:border-primary disabled:border-none focus:ring-transparent z-40 disabled:cursor-default`;
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checkboxSize = 'small',
      label,
      handleChange,
      checkboxStyle,
      labelStyle,
      disabled,
      className,
      labelCode,
      labelCodeStyles,
      error,
      errorMessage,
      errorStyle,
      htmlKey,
      ...props
    },
    ref
  ) => {
    const { colors } = useTheme();
    const [checked, setChecked] = useState<boolean>(false);
    return (
      <>
        <label
          className={`flex flex-row items-center ${className}`}
          htmlFor={htmlKey || label}
        >
          <div
            className={clsx(
              'relative mr-4 flex items-center',
              checkboxStyle,
              checked ? '' : 'hover:bg-background10'
            )}
          >
            <input
              className={clsx(
                `${commonCheckboxStyles} checkbox ${variantStyles[checkboxSize]}`,
                error && errorStyle
              )}
              type="checkbox"
              ref={ref}
              onChange={(e) => {
                setChecked(e.target.checked);
                handleChange?.(e);
              }}
              id={htmlKey || label}
              disabled={disabled}
              {...props}
            />
            <Icon
              name="check-2"
              fill={disabled ? colors.background30 : colors.primary}
              size={checkboxSize === 'small' ? 'small' : 'large'}
              className="checkbox-icon absolute top-0 left-0 opacity-0"
            />
          </div>
          {labelCode && (
            <label htmlFor={htmlKey || label} className={labelCodeStyles}>
              {labelCode}
            </label>
          )}
          <label
            htmlFor={htmlKey || label}
            className={clsx(labelStyle, 'text-body')}
          >
            {label}
          </label>
        </label>
        {errorMessage && (
          <p
            className={clsx(
              'text-caption mt-2 flex items-center text-error',
              errorStyle
            )}
          >
            <Icon name="info-circle" className="mr-1" />
            {errorMessage}
          </p>
        )}
      </>
    );
  }
);

export default Checkbox;
