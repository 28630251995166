import React, { useCallback, useEffect, useRef } from 'react';
import Spinner from '../spinner/Spinner';

interface InfiniteScrollProps {
  handleScroll: () => void;
  isDisable: boolean;
  showLoader: boolean;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  handleScroll,
  isDisable,
  showLoader
}) => {
  const loaderRef = useRef(null);
  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting) {
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);
  }, [handleObserver]);
  return isDisable ? null : (
    <div ref={loaderRef} className="mt-4 flex justify-center">
      {showLoader && <Spinner />}
    </div>
  );
};

export default InfiniteScroll;
