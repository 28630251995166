import { useNavigate } from 'react-router-dom';
import Dropdown from '@components/dropdown/Dropdown';
import { DropDownOption } from '@types';
import { MY_ACCOUNT } from '@constants/routes';
import useCheckPermission from '@hooks/checkPermission';
import UserPermissionTypes from '@constants/permissions';
import useLogout from '@features/authentication/hooks/logout';
import testId from '@constants/testId';

interface ProfileProps {
  name: string;
}

const Profile = ({ name }: ProfileProps) => {
  const { handleLogout } = useLogout();
  const navigate = useNavigate();

  const { checkPermission } = useCheckPermission();

  const profileOptions = [
    { key: 'logout', value: 'logout', displayName: 'Logout' }
  ];
  if (checkPermission([UserPermissionTypes.VIEW_ACCOUNT]))
    profileOptions.unshift({
      key: 'myaccount',
      value: 'myaccount',
      displayName: 'My Account'
    });

  const handleMyAccountClick = async () => {
    navigate(MY_ACCOUNT);
  };

  const onClickProfileSettings = (selectedOption: DropDownOption) => {
    if (selectedOption.key === 'logout') handleLogout();
    if (selectedOption.key === 'myaccount') handleMyAccountClick();
  };

  const profileLogoText = name?.trim()?.[0] || '';

  return (
    <div className="relative flex items-center ">
      <div className="flex h-[34px] w-[34px] items-center justify-center rounded-full border-[1px] border-background10 bg-secondary ">
        <span className="text-base text-white">{profileLogoText}</span>
      </div>
      <div className="ml-3.5 flex items-center">
        <div className="text-body">{name}</div>
        <Dropdown
          title="Profile"
          type="default"
          dropdownStyle="!mt-0 !ml-2 !mr-4"
          inputStyle="w-[20px] border-none focus: !p-0 mr-3"
          dropdownMenuStyle="w-[160px] right-[0px]"
          options={profileOptions}
          onClick={onClickProfileSettings}
          testId={testId.mainHeader.profile.profileDropdown}
        />
      </div>
    </div>
  );
};
export default Profile;
