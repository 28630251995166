import clsx from 'clsx';
import { TooltipIcon } from '@components/tool-tip/Tooltip';

export interface TabPill {
  value: string;
  label: string;
  count?: number;
  showTooltip?: boolean;
  tooltipText?: string;
  tooltipStyle?: string;
}

export interface TabProps {
  tabs: TabPill[];
  onTabSelect?: (tab: TabPill) => void;
  selectedTab?: TabPill;
  testId?: string;
}

const Tabs = ({
  tabs,
  onTabSelect = () => null,
  selectedTab,
  testId = ''
}: TabProps) => (
  <>
    <div className="flex w-full">
      {tabs?.map((tab) => (
        <div className="group/tabs flex flex-col" key={tab.value}>
          <div className="group-hover/tabs:bg-primary10 group-hover/tabs:rounded-md flex flex-row">
            <div
              role="presentation"
              className={clsx(
                'text-body-semi  table-cell cursor-pointer truncate px-5 py-1',
                selectedTab?.value === tab.value
                  ? 'text-primary'
                  : 'text-background50 group-hover:text-primary'
              )}
              onClick={() => {
                onTabSelect(tab);
              }}
              key={tab.value}
              data-testid={`${testId}_${tab.value}_btn`}
            >
              <div className="relative flex">
                {tab.label}
                {(tab.count || tab.count === 0) && (
                  <span
                    className={clsx(
                      'text-body-semi ml-2.5 rounded-md px-1.5 py-0.5',
                      selectedTab?.value === tab.value
                        ? 'bg-primaryLite10 '
                        : 'group-hover/tabs:bg-primaryLite10 bg-background10 group-hover:text-primary'
                    )}
                  >
                    {tab.count > 0 && tab.count < 10
                      ? `0${String(tab.count)}`
                      : tab.count}
                  </span>
                )}
              </div>
            </div>
            {tab.showTooltip && (
              <span className="mr-2 flex items-center">
                <TooltipIcon
                  text={tab.tooltipText || ''}
                  className={tab.tooltipStyle}
                />
              </span>
            )}
          </div>
          <div
            className={clsx({
              'relative top-[4px] rounded-10px border-b-2 border-primary': true,
              invisible: selectedTab?.value !== tab.value
            })}
          />
        </div>
      ))}
    </div>
    <div className="mt-0.5 rounded-10px border-b-2" />
  </>
);

export default Tabs;
