import { validateRegionCode } from '@features/regions/helpers/helpers';
import { Region, User } from '@types';

const getUsersListState = (usersList: User[]) => {
  const usersListMap = new Map<string, User>();

  usersList.forEach((user) => {
    usersListMap.set(user.entityId, user);
  });

  return usersListMap;
};

const sortRegionByName = (regions: Region[]) => {
  const sortedRegions = regions
    ?.slice()
    .sort((a: Region, b: Region) => a.regionName.localeCompare(b.regionName));
  return sortedRegions;
};

const getSortedRegions = (userDetails: User) => {
  const { defaultRegion, regions } = userDetails || {};
  const isRegionCodeValid =
    defaultRegion &&
    validateRegionCode(defaultRegion.regionCode, regions || []);

  if (isRegionCodeValid) {
    const filteredRegions = regions.filter(
      (obj: Region) => obj.entityId !== defaultRegion.entityId
    );
    const sortedRegions = sortRegionByName(filteredRegions);
    sortedRegions.unshift(defaultRegion);
    return sortedRegions;
  }
  return sortRegionByName(regions);
};

export { getUsersListState, getSortedRegions };
