import { ClipboardEventHandler } from 'react';

import useTranslate from '@hooks/intl';
import { Chip, SearchBar, Input } from '@components';

interface InputChipProps {
  inputPlaceholder?: string;
  searchPlaceholder?: string;
  onKeyDown: (e: any) => void;
  chipList: string[];
  onRemove: (item: string) => void;
  onSearch?: (data: string) => void;
  onChange: (e: any) => void;
  inputValue: string;
  error?: boolean;
  errorMessage?: string;
  searchRequired?: boolean;
  noSearchResult?: boolean;
  onPaste?: ClipboardEventHandler;
  chipWrapperStyle?: string;
  inputFieldStyle?: string;
  clearSearchData?: boolean;
  disableInput?: boolean;
  onChangeSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch?: () => void;
}

const InputChip = ({
  inputPlaceholder,
  searchPlaceholder,
  onKeyDown,
  chipList,
  onRemove,
  onSearch,
  onChange,
  inputValue,
  error = false,
  errorMessage = '',
  searchRequired = false,
  noSearchResult,
  onPaste,
  chipWrapperStyle,
  inputFieldStyle,
  clearSearchData,
  disableInput = false,
  onChangeSearch,
  onClearSearch
}: InputChipProps) => {
  const translate = useTranslate();
  return (
    <>
      {searchRequired && (
        <SearchBar
          className="mt-6 mb-4 !w-full"
          onSearch={(data) => {
            onSearch?.(data);
          }}
          placeholder={searchPlaceholder}
          clearSearchData={clearSearchData}
          onChange={(event) => onChangeSearch?.(event)}
          onClearIconAction={() => onClearSearch?.()}
        />
      )}
      <Input
        className={`${inputFieldStyle}`}
        placeholder={inputPlaceholder}
        onChange={(e) => onChange(e)}
        value={inputValue}
        onKeyDown={(e: any) => onKeyDown(e)}
        error={error}
        errorMessage={errorMessage}
        onPaste={onPaste}
        disabled={disableInput}
      />
      <div
        className={`flex w-full flex-wrap gap-2.5 overflow-y-auto ${
          (chipList.length > 0 || noSearchResult) && 'my-6'
        } ${chipWrapperStyle}`}
      >
        {noSearchResult ? (
          <div className="text-body-base">
            {translate('component.inputChip.noDataText')}
          </div>
        ) : (
          chipList?.map((item, index) => (
            <Chip
              label={item}
              onRemove={() => onRemove(item)}
              closeButtonSize="extrasmall"
              chipType="SECONDARY"
              labelStyle="w-fit truncate"
              className="max-w-full"
              // eslint-disable-next-line react/no-array-index-key
              key={`${item}_chip_${index}`}
            />
          ))
        )}
      </div>
    </>
  );
};

export default InputChip;
