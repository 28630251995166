import { ReactNode } from 'react';

import Modal from './Modal';
import Button, { ButtonLabel } from '../../components/button/Button';

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  message?: string | ReactNode;
  cancelButtonlabel: string;
  confirmButtonlabel: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  actionLoading?: boolean;
  isClickOutsideAllowed?: boolean;
}
const ConfirmationModal = ({
  open,
  title,
  message,
  cancelButtonlabel,
  confirmButtonlabel,
  handleConfirm,
  handleCancel,
  actionLoading,
  isClickOutsideAllowed = true
}: ConfirmationModalProps) => (
  <Modal
    isOpen={open}
    onCancel={handleCancel}
    isClickOutsideAllowed={isClickOutsideAllowed}
  >
    <div className="min-w-[416px] max-w-md py-6 pl-6 pr-9">
      <div className="text-body-base-semi mb-1.5 whitespace-pre-wrap break-normal pb-4">
        {title}
      </div>
      {message && (
        <div className="text-body whitespace-pre-wrap break-words pb-8 text-background50">
          {message}
        </div>
      )}
      <div className="flex">
        <Button handleClick={handleCancel} variant="secondary" size="none">
          <ButtonLabel
            variant="secondary"
            label={cancelButtonlabel}
            className="py-2 px-4"
          />
        </Button>
        <Button
          handleClick={handleConfirm}
          variant="primary"
          size="none"
          className="ml-2.5"
          loading={actionLoading}
        >
          <ButtonLabel
            variant="primary"
            label={confirmButtonlabel}
            className="py-2 px-4"
          />
        </Button>
      </div>
    </div>
  </Modal>
);

export default ConfirmationModal;
