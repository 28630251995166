import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { USER, MY_ACCOUNT } from '@constants/routes';

const Users = lazy(() => import('@features/users/pages/users/Users'));
const MyAccount = lazy(
  () => import('@features/users/pages/my-account/MyAccount')
);
export const UserRoutes: RouteObject[] = [
  {
    path: USER.USER_MANAGEMENT,
    element: <Users />
  },
  { path: MY_ACCOUNT, element: <MyAccount /> }
];
export default UserRoutes;
