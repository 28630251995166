import {
  configureStore,
  PreloadedState,
  combineReducers
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { notificationSlice } from '@features/components/notification/notificationSlice';
import { regionSlice } from '@features/regions/slices/regionSlice';
import { userSlice } from '@features/users/slices/userSlice';
import { courierPartnerSlice } from '@features/couriers/slices/courierSlice';
import {
  baseApi,
  authApi,
  baseApiExcludedRegion,
  baseWebhookApi,
  baseWebhookApiExcludedRegion,
  base3plApi,
  base3plApiExcludedRegion
} from './baseApi';
import storeLogger from './logger';
import { rtkQueryErrorLogger } from './errorHandler';

const middlewareList = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  const list = [
    rtkQueryErrorLogger,
    baseApi.middleware,
    authApi.middleware,
    baseWebhookApi.middleware,
    base3plApi.middleware
  ];
  if (process.env.NODE_ENV === 'development') {
    list.push(storeLogger);
  }
  return getDefaultMiddleware().concat(list);
};

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [baseApiExcludedRegion.reducerPath]: baseApiExcludedRegion.reducer,
  notification: notificationSlice.reducer,
  region: regionSlice.reducer,
  courierPartner: courierPartnerSlice.reducer,
  user: userSlice.reducer,
  // Will be replaced once api gateway implementation is completed
  [baseWebhookApi.reducerPath]: baseWebhookApi.reducer,
  [baseWebhookApiExcludedRegion.reducerPath]:
    baseWebhookApiExcludedRegion.reducer,
  [base3plApi.reducerPath]: base3plApi.reducer,
  [base3plApiExcludedRegion.reducerPath]: base3plApiExcludedRegion.reducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
      middlewareList(getDefaultMiddleware),
    preloadedState
  });

export const store = setupStore({});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
