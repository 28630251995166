import React, { useState, useRef, MutableRefObject } from 'react';
import update from 'immutability-helper';
import clsx from 'clsx';
import useTranslate from '@hooks/intl';

import ClickOutside from '@utils/clickOutside';
import { Scalars } from '@types';
import { useTheme } from '@contexts/theme';
import Chip, { ChipTypes } from '@components/chip/Chip';
import useChipsToDisplay from '@hooks/displayChips';
import Button from '@components/button/Button';
import Icon from '@components/icon/Icon';
import Checkbox from '@components/checkbox/Checkbox';
import Input from '@components/input/Input';

type MultiSelectDropdownType = 'default' | 'chip';

const CHIPS_MAX_LENGTH = 16;

interface Option {
  key: any;
  value: any;
  displayName: Scalars['String'];
  displayCode?: Scalars['String'];
}
interface MultiSelectDropdownProps {
  options: Option[];
  selectedOptions: Option[];
  onSelect: (item: Option[]) => void;
  placeholder?: Scalars['String'];
  type: MultiSelectDropdownType;
  multiSelectDropdownStyle?: Scalars['String'];
  className?: Scalars['String'];
  dropdownWidthStyle?: Scalars['String'];
  error?: boolean;
  errorMessage?: string;
  testId?: Scalars['String'];
  errorStyle?: string;
  noResultsText?: Scalars['String'];
  chipType?: ChipTypes;
  onReset?: () => void;
  chipStyle?: Scalars['String'];
  maxChipsLength?: Scalars['Int'];
  selectAllRequired?: Scalars['Boolean'];
  searchRequired?: Scalars['Boolean'];
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedOptions,
  onSelect,
  multiSelectDropdownStyle,
  placeholder,
  className,
  type,
  dropdownWidthStyle,
  error = false,
  errorMessage,
  testId,
  errorStyle,
  noResultsText,
  onReset,
  chipStyle,
  maxChipsLength,
  chipType = 'PRIMARY',
  selectAllRequired = false,
  searchRequired = false,
  ...props
}: MultiSelectDropdownProps) => {
  const { colors } = useTheme();
  const translate = useTranslate();
  const [open, setOpen] = useState<Scalars['Boolean']>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const multiSelectDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const findIndex = (item: Option) =>
    selectedOptions.findIndex((option) => option.key === item.key);

  const displayChips = useChipsToDisplay(
    selectedOptions?.map((item) => item.value),
    maxChipsLength || CHIPS_MAX_LENGTH
  );

  const filteredOptions: Option[] =
    searchRequired && searchValue
      ? options.filter((option: Option) =>
          option.displayName?.toLowerCase().includes(searchValue.toLowerCase())
        )
      : options;

  const isSelectAllChecked = filteredOptions?.every((filteredItem) =>
    selectedOptions.find(
      (selectedItem) => filteredItem.key === selectedItem.key
    )
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectAllCheckedState = event.target.checked;
    let temp: Option[] = [];
    if (selectAllCheckedState) {
      filteredOptions.map((item) => findIndex(item) < 0 && temp.push(item));
      onSelect(selectedOptions.concat(temp));
    } else {
      temp = selectedOptions.filter(
        (selectedItem) =>
          !filteredOptions.find(
            (filteredItem) => selectedItem.key === filteredItem.key
          )
      );
      onSelect(temp);
    }
  };

  const onChange = (
    element: Option,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedState = event.target.checked;
    let temp: Option[];
    if (checkedState) {
      if (findIndex(element) < 0) {
        temp = update(selectedOptions, { $push: [element] });
        onSelect(temp);
      }
    } else {
      const index = findIndex(element);
      if (index > -1) {
        temp = update(selectedOptions, { $splice: [[index, 1]] });
        onSelect(temp);
      }
    }
  };

  ClickOutside({
    ref: multiSelectDropdownRef,
    handler: () => {
      setOpen(false);
      setSearchValue('');
    }
  });

  const dropdownChip = () => (
    <>
      {!selectedOptions?.length && placeholder}
      {selectedOptions?.slice(0, displayChips.chipLength).map((item) => (
        <Chip
          chipType={chipType}
          label={item.value}
          className="mr-2 border-none !py-0"
          labelStyle={clsx(
            chipStyle,
            chipType === 'SECONDARY' ? '!text-sm' : ''
          )}
          key={item.key}
        />
      ))}
      {displayChips.showMore && selectedOptions && (
        <Chip
          chipType={chipType}
          label={`+ ${selectedOptions.length - displayChips.chipLength}`}
          className="border-none !py-0"
          labelStyle={chipType === 'SECONDARY' ? '!text-sm' : ''}
        />
      )}
    </>
  );

  return (
    <div
      className={clsx('relative', className)}
      ref={multiSelectDropdownRef}
      {...props}
    >
      <Button
        variant="transparent"
        handleClick={() => setOpen((prevState) => !prevState)}
        className={clsx(
          'relative !w-full cursor-pointer !justify-start rounded-10px border border-background30',
          open ? 'border-primary' : '',
          multiSelectDropdownStyle,
          error ? 'border-error' : 'hover:border-background90'
        )}
        data-testid={`${testId}_btn`}
      >
        {searchRequired ? (
          <>
            <Icon name="search" stroke={colors.background50} />
            <Input
              inputDataStyle="border-0 outline-none"
              className="w-full"
              placeholder={placeholder}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setOpen(true);
              }}
              onClick={() => {
                setSearchValue('');
              }}
            />
          </>
        ) : (
          <>
            {selectedOptions?.length > 0 && type === 'chip' && (
              <span
                className={`text-body absolute -top-3 left-1 bg-white px-2 ${
                  open ? 'text-primary' : 'text-background50'
                }`}
              >
                {placeholder}
              </span>
            )}
            <span className="text-body flex items-center text-background50">
              {type === 'chip' ? dropdownChip() : placeholder}
            </span>
          </>
        )}
        <Icon
          name="chevron-down"
          size="small"
          className={`absolute right-3 ${open ? 'rotate-180' : ''}`}
          stroke={open ? colors.primary : colors.background90}
        />
      </Button>
      {open && (
        <div
          className={clsx(
            'absolute z-10 my-3 max-h-48 overflow-y-auto rounded-md bg-white p-2 shadow-dropdownShadow',
            dropdownWidthStyle
          )}
        >
          {!!onReset && (
            <div
              className="flex h-10 w-full cursor-pointer items-center justify-center rounded-lg p-2 text-sm hover:bg-primary10"
              onClick={() => onReset()}
              role="presentation"
            >
              {translate('component.multiSelectDropDown.reset')}
            </div>
          )}
          {filteredOptions.length > 0 ? (
            <>
              {selectAllRequired && (
                <Checkbox
                  label={translate(
                    'component.multiSelectDropDown.selectAll_label'
                  )}
                  handleChange={handleSelectAll}
                  labelStyle="cursor-pointer truncate"
                  className="h-12 w-full cursor-pointer rounded-lg p-2 hover:bg-primary10"
                  checked={isSelectAllChecked}
                />
              )}

              {filteredOptions.map((item, index) => (
                <Checkbox
                  label={item.displayName}
                  labelCode={item.displayCode}
                  handleChange={(e) => onChange(item, e)}
                  key={item.value}
                  checked={findIndex(item) >= 0}
                  labelStyle="cursor-pointer truncate"
                  className="h-12 w-full cursor-pointer rounded-lg p-2 hover:bg-primary10"
                  labelCodeStyles="cursor-pointer font-bold mr-2"
                  data-testid={`${testId}_option_${index}`}
                />
              ))}
            </>
          ) : (
            <div className="m-auto text-center">
              {noResultsText ||
                translate('component.multiSelectDropDown.noResultsText')}
            </div>
          )}
        </div>
      )}
      {errorMessage && (
        <p
          className={clsx(
            'text-caption mt-2 flex items-center text-error',
            errorStyle
          )}
        >
          <Icon name="info-circle" className="mr-1" />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
