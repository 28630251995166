import UserPermissionTypes from '@constants/permissions';
import {
  ORDERS,
  ALLOCATION,
  REGION,
  COURIER,
  USER,
  ROLE,
  WEBHOOK,
  WAREHOUSE,
  GETLOG,
  DASHBOARD
} from '@constants/routes';

export type SideNavItem = {
  id: string;
  nameKey: string;
  path: string;
  icon?: string;
  permissions?: string[];
  childPaths?: SideNavItem[];
};

export const sideNavItems: SideNavItem[] = [
  {
    id: 'dashboard',
    nameKey: 'sideNav.dashboard.title',
    path: DASHBOARD.DASHBOARD,
    icon: 'dashboard',
    childPaths: [
      {
        id: 'dashboard-global',
        nameKey: 'sideNav.dashboard.global',
        path: DASHBOARD.GLOBAL_DASHBOARD,
        permissions: [
          UserPermissionTypes.GLOBAL_DASHBOARD_OVERVIEW,
          UserPermissionTypes.GLOBAL_DASHBOARD_ORDERS,
          UserPermissionTypes.GLOBAL_DASHBOARD_COURIER_PARTNERS
        ]
      },
      {
        id: 'dashboard-region',
        nameKey: 'sideNav.dashboard.region',
        path: DASHBOARD.REGION_DASHBOARD,
        permissions: [
          UserPermissionTypes.DASHBOARD_OVERVIEW,
          UserPermissionTypes.DASHBOARD_ORDERS,
          UserPermissionTypes.DASHBOARD_COURIER_PARTNERS
        ]
      }
    ]
  },
  {
    id: 'orderMgmt',
    nameKey: 'sideNav.orderMgmt.title',
    path: ORDERS.ORDERS,
    icon: 'cart',
    childPaths: [
      {
        id: 'globalOrders',
        nameKey: 'sideNav.orderMgmt.globalOrders',
        path: ORDERS.GLOBAL_ORDERS,
        permissions: [UserPermissionTypes.GLOBAL_VIEW_ORDERS]
      },
      {
        id: 'orders',
        nameKey: 'sideNav.orderMgmt.orders',
        path: ORDERS.ORDERS,
        permissions: [UserPermissionTypes.VIEW_ORDERS]
      },
      {
        id: 'upload',
        nameKey: 'sideNav.orderMgmt.bulkOrderUpload',
        path: ORDERS.BULK_ORDER_UPLOAD,
        permissions: [UserPermissionTypes.UPLOAD_ORDERS]
      },
      {
        id: 'statusUpdate',
        nameKey: 'sideNav.orderMgmt.status',
        path: ORDERS.MANUAL_STATUS_UPDATE,
        permissions: [UserPermissionTypes.UPLOAD_MANUAL_STATUS_UPDATE]
      }
    ]
  },
  {
    id: 'allocation',
    nameKey: 'sideNav.allocation.title',
    path: ALLOCATION.ALLOCATION,
    icon: 'chart',
    childPaths: [
      {
        id: 'allocation-rule',
        nameKey: 'sideNav.allocation.rules',
        path: ALLOCATION.RULE,
        permissions: [UserPermissionTypes.VIEW_RULE]
      },
      {
        id: 'recommendation',
        nameKey: 'sideNav.allocation.recommendation',
        path: COURIER.RECOMMENDATION,
        permissions: [
          UserPermissionTypes.VIEW_TEST_RECOMMENDATION,
          UserPermissionTypes.VIEW_SEARCH_HISTORY
        ]
      }
    ]
  },
  {
    id: 'courier',
    nameKey: 'sideNav.courier.title',
    path: '',
    icon: 'truck',
    childPaths: [
      {
        id: 'integration',
        nameKey: 'sideNav.courier.integration',
        path: COURIER.PARTNERS,
        permissions: [
          UserPermissionTypes.VIEW_COURIER_PARTNERS,
          UserPermissionTypes.VIEW_PARTNER_ACCOUNT_LIST
        ]
      },
      {
        id: 'serviceability',
        nameKey: 'sideNav.courier.serviceability',
        path: COURIER.CHECK_SERVICEABILITY,
        permissions: [UserPermissionTypes.VIEW_CHECK_SERVICEABILITY]
      },
      {
        id: 'addressMapper',
        nameKey: 'sideNav.courier.addressMapper',
        path: COURIER.ADDRESS_MAPPER,
        permissions: [UserPermissionTypes.VIEW_ADDRESS_MAPPER]
      },
      {
        id: 'statusMapping',
        nameKey: 'sideNav.courier.statusMapping',
        path: COURIER.STATUS_MAPPING,
        permissions: [UserPermissionTypes.VIEW_STATUS_MAPPING]
      }
    ]
  },
  {
    id: 'account',
    nameKey: 'sideNav.account.title',
    path: '',
    icon: 'users',
    childPaths: [
      {
        id: 'user-management',
        nameKey: 'sideNav.account.userManagement',
        path: USER.USER_MANAGEMENT,
        permissions: [UserPermissionTypes.VIEW_USER]
      },
      {
        id: 'role-management',
        nameKey: 'sideNav.account.roleManagement',
        path: ROLE.ROLE_MANAGEMENT,
        permissions: [UserPermissionTypes.VIEW_ROLES_PERMISSION]
      }
    ]
  },
  {
    id: 'setup',
    nameKey: 'sideNav.setup.title',
    path: '',
    icon: 'settings',
    childPaths: [
      {
        id: 'region-management',
        nameKey: 'sideNav.setup.regions',
        path: REGION.REGION_MANAGEMENT,
        permissions: [UserPermissionTypes.VIEW_REGION]
      },
      {
        id: 'warehouse',
        nameKey: 'sideNav.setup.warehouse',
        path: WAREHOUSE.WAREHOUSE_MANAGEMENT,
        permissions: [UserPermissionTypes.VIEW_WAREHOUSE]
      },
      {
        id: 'webhook',
        nameKey: 'sideNav.setup.webhooks',
        path: WEBHOOK.WEBHOOK,
        permissions: [
          UserPermissionTypes.VIEW_WEBHOOK,
          UserPermissionTypes.RETRIGGER_WEBHOOK
        ]
      },
      {
        id: 'getlog',
        nameKey: 'sideNav.setup.getLog',
        path: GETLOG.GETLOG_MANAGEMENT,
        permissions: [
          UserPermissionTypes.GET_LOG_ORDER_CREATION,
          UserPermissionTypes.GET_LOG_WEBHOOK_HISTORY
        ]
      }
    ]
  }
];
