import clsx from 'clsx';

type LabelType = 'success' | 'error' | 'orange';

const StatusLabel = ({
  label,
  type,
  className
}: {
  label: string;
  type: LabelType;
  className?: string;
}) => {
  const labelClassNames = clsx({
    'text-body-md rounded-7px bg-white px-4 py-2': true,
    'bg-warning10 text-warning': () => type === 'orange'
  });
  return <div className={clsx(labelClassNames, className)}>{label}</div>;
};

export default StatusLabel;
