import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { COURIER } from '@constants/routes';

// Courier integration
const CourierPartners = lazy(
  () => import('@features/couriers/pages/partners/Partners')
);
const PartnerAccounts = lazy(
  () => import('@features/couriers/pages/partner-accounts/PartnerAccounts')
);
const AddCourierAccount = lazy(
  () => import('@features/couriers/pages/add-courier-account/AddCourierAccount')
);
const AccountDetail = lazy(
  () =>
    import(
      '@features/couriers/pages/partner-account-detail/PartnerAccountDetail'
    )
);
const PartnerServiceability = lazy(
  () =>
    import(
      '@features/couriers/pages/partner-account-configuration/Serviceability'
    )
);
const Allocation = lazy(
  () => import('@features/couriers/pages/allocation/Allocation')
);

const PartnerCredentials = lazy(
  () =>
    import('@features/couriers/pages/partner-account-configuration/Credentials')
);
const AddressMapper = lazy(
  () => import('@features/couriers/pages/address-mapper/AddressMapper')
);
const AddressMapperUpload = lazy(
  () =>
    import('@features/couriers/pages/address-mapper-upload/AddressMapperUpload')
);
const CheckServiceability = lazy(
  () =>
    import('@features/couriers/pages/serviceabilityCheck/ServiceabilityCheck')
);

const StatusMapping = lazy(
  () => import('@features/couriers/pages/statusMapping/StatusMapping')
);

export const CourierRoutes: RouteObject[] = [
  {
    path: COURIER.COURIER,
    children: [
      {
        path: COURIER.PARTNERS,
        element: <CourierPartners />
      },
      {
        path: COURIER.PARTNER_ACCOUNT,
        children: [
          {
            path: '',
            element: <PartnerAccounts />
          },
          {
            path: COURIER.CREATE_COURIER_ACCOUNT,
            element: <AddCourierAccount />
          },
          {
            path: COURIER.PARTNER_ACCOUNT_DETAIL,
            children: [
              {
                path: '',
                element: <AccountDetail />
              },
              {
                path: COURIER.PARTNER_ACCOUNT_SERVICEABILITY,
                element: <PartnerServiceability />
              },
              {
                path: COURIER.PARTNER_ACCOUNT_CREDENTIALS,
                element: <PartnerCredentials />
              }
            ]
          }
        ]
      },
      {
        path: COURIER.RECOMMENDATION,
        element: <Allocation />
      },
      {
        path: COURIER.ADDRESS_MAPPER,
        element: <AddressMapper />
      },
      {
        path: COURIER.ADDRESS_MAPPER_UPLOAD,
        element: <AddressMapperUpload />
      },
      {
        path: COURIER.CHECK_SERVICEABILITY,
        element: <CheckServiceability />
      },
      {
        path: COURIER.STATUS_MAPPING,
        element: <StatusMapping />
      }
    ]
  }
];

export default CourierRoutes;
