import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { WEBHOOK } from '@constants/routes';

const Webhooks = lazy(
  () => import('@features/webhooks/pages/webhooks/Webhooks')
);
const AddWebhook = lazy(
  () => import('@features/webhooks/pages/add-webhook/AddWebhook')
);

export const RuleRoutes: RouteObject = {
  path: WEBHOOK.WEBHOOK,
  children: [
    {
      path: '',
      element: <Webhooks />
    },
    {
      path: WEBHOOK.ADD_WEBHOOK,
      element: <AddWebhook />
    },
    {
      path: WEBHOOK.EDIT_WEBHOOK,
      element: <AddWebhook />
    }
  ]
};

export default RuleRoutes;
