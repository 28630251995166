import ChartLegendText from './ChartLegendText';
import { ChartData } from './types';

interface ChartLegendTextTableProps {
  chartData: ChartData[];
  infoPosition: 'bottom' | 'right';
  maxHeight: number;
  maxWidth: number;
  legendTableHeaders: string[];
  className?: string;
}

const ChartLegendTextTable = ({
  chartData,
  infoPosition,
  maxHeight,
  maxWidth,
  legendTableHeaders: headers,
  className = ''
}: ChartLegendTextTableProps) => (
  <div
    className={`relative flex w-full min-w-[240px] divide-y self-center rounded-lg border ${
      infoPosition === 'bottom' ? 'mt-6 flex-row ' : 'ml-6 flex-col'
    } ${className}`}
    style={{ maxHeight, maxWidth }}
  >
    <div className="mx-4 my-2 flex items-center">
      {headers.map((header, index) => (
        <div
          className={`text-body-semi ${index === 0 ? 'w-2/3' : 'w-1/3 pl-4'}`}
          key={header}
        >
          {header}
        </div>
      ))}
    </div>
    <div className="tinyScrollBar my-2 flex flex-col divide-y overflow-scroll">
      {chartData.map((item) => (
        <div className="flex px-4 py-2 font-medium" key={item.name}>
          <ChartLegendText
            text={item.name}
            color={item.fillColor || '#FFF'}
            key={item.name}
            className="w-2/3"
          />
          <div className="w-1/3 pl-4 text-sm text-black">
            {item.tableValue || item.value}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ChartLegendTextTable;
