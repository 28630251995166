import { useState } from 'react';
import clsx from 'clsx';

import { IconButton, StatusLabel, Switch } from '@components';
import useTranslate from '@hooks/intl';
import { UpdateStatusOperation, User, UserStatus } from '@types';
import useNotify from '@hooks/notify';
import testId from '@constants/testId';
import { useTheme } from '@contexts/theme';
import {
  useResendInviteMutation,
  useUpdateStatusMutation
} from '../../queries/UsersQuery';

const AccessSwitch = ({
  user,
  updateUsersList,
  disabled
}: {
  user: User;
  updateUsersList: () => void;
  disabled?: boolean;
}) => {
  const [updateUserStatus, { isLoading }] = useUpdateStatusMutation();
  const [resendInvite, { isLoading: isResendingInvite }] =
    useResendInviteMutation();
  const translate = useTranslate();
  const { createSuccessAlert, createErrorAlert } = useNotify();
  const { colors } = useTheme();

  const [accessEnabled, setAccessEnabled] = useState(
    user.status === UserStatus.Active
  );

  const isUserInvited = user.status === UserStatus.Invited;
  const labelStyles = clsx({
    'pl-2': true,
    'text-primary': accessEnabled,
    'text-background70': !accessEnabled
  });

  const toggleUserAccessType = async (value: boolean) => {
    const res = await updateUserStatus({
      body: {
        operation: value
          ? UpdateStatusOperation.ACTIVATE
          : UpdateStatusOperation.DEACTIVATE
      },
      id: user.entityId
    });
    if ('data' in res) {
      updateUsersList();
      setAccessEnabled(value);
      createSuccessAlert(
        res.data.status === UserStatus.Disabled
          ? translate('users.accessSwitch.disabled_alert')
          : translate('users.accessSwitch.enabled_alert')
      );
    } else if ('error' in res) createErrorAlert(res.error?.data?.message);
  };

  const onResendInvite = async () => {
    const res = await resendInvite(user.entityId);
    if ('data' in res) {
      updateUsersList();
      createSuccessAlert(translate('users.actionButton.resend_alert'));
    } else if ('error' in res) createErrorAlert(res.error?.data?.message);
  };

  return (
    <div className="flex items-center">
      {isUserInvited ? (
        <>
          <StatusLabel
            type="orange"
            label={translate('users.accessSwitch.invite')}
            className="mr-2"
            data-testid={testId.users.accessSwitch.statusLabel}
          />
          <IconButton
            handleClick={() => onResendInvite()}
            loading={isResendingInvite}
            iconName="refresh"
            toolTipText={translate('users.actionButton.resend')}
            stroke={colors.grey}
          />
        </>
      ) : (
        <>
          <Switch
            handleClick={(val) => toggleUserAccessType(val)}
            value={accessEnabled}
            disabled={disabled}
            loading={isLoading}
            variant="dark"
            data-testid={testId.users.accessSwitch.toggleButton}
          />
          <span
            className={labelStyles}
            data-testid={testId.users.accessSwitch.accessLabel}
          >
            {accessEnabled
              ? translate('users.accessSwitch.enabled')
              : translate('users.accessSwitch.disabled')}
          </span>
        </>
      )}
    </div>
  );
};

export default AccessSwitch;
