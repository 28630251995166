import { baseApi } from '@core/baseApi';
import { END_POINTS } from '@config';
import {
  Scalars,
  UpdateUserStatusRequest,
  User,
  HTTPResponse,
  CreateUserRequest,
  UpdateUserRequest
} from '@types';

export const extendedBaseQuery = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<HTTPResponse<User>, void>({
      query: () => ({
        url: END_POINTS.USER.GET_CURRENT_USER,
        method: 'GET'
      })
    }),
    getAllUsers: builder.query<HTTPResponse<User[]>, Scalars['QueryString']>({
      query: (queryString) => ({
        url: `${END_POINTS.USER.GET_USERS}/${queryString}`,
        method: 'GET'
      })
    }),
    getUserByID: builder.query<HTTPResponse<User>, Scalars['ID']>({
      query: (id) => ({
        url: `${END_POINTS.USER.GET_USER}/${id}`,
        method: 'GET'
      })
    }),
    updateStatus: builder.mutation<HTTPResponse<User>, UpdateUserStatusRequest>(
      {
        query: (reqBody) => ({
          url: `${END_POINTS.USER.UPDATE_STATUS}/${reqBody.id}`,
          method: 'PATCH',
          body: reqBody.body
        })
      }
    ),
    updateUser: builder.mutation<HTTPResponse<User>, UpdateUserRequest>({
      query: (reqBody) => ({
        url: `${END_POINTS.USER.UPDATE_USER}/${reqBody.id}`,
        method: 'PUT',
        body: reqBody.body
      })
    }),
    deleteUser: builder.mutation<any, Scalars['ID']>({
      query: (id) => ({
        url: `${END_POINTS.USER.DELETE_USER}/${id}`,
        method: 'DELETE'
      })
    }),
    resendInvite: builder.mutation<HTTPResponse<User>, Scalars['ID']>({
      query: (id) => ({
        url: END_POINTS.USER.RESEND_INVITE(id),
        method: 'POST'
      })
    }),
    createAndInviteUser: builder.mutation<
      HTTPResponse<User>,
      CreateUserRequest
    >({
      query: (reqBody) => ({
        url: END_POINTS.USER.INVITE,
        method: 'POST',
        body: reqBody
      })
    })
  })
});

export const {
  useLazyGetAllUsersQuery,
  useResendInviteMutation,
  useUpdateStatusMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useCreateAndInviteUserMutation,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery
} = extendedBaseQuery;
export const selectUsersResult =
  extendedBaseQuery.endpoints.getAllUsers.select('data');
