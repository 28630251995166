module.exports = {
  // Primary
  primary: '#03603D',
  primary90: '#1D7051',
  primary70: '#4F9078',
  primary50: '#81AF9E',
  primary30: '#B3CFC4',
  primary10: '#E5EFEB',
  primary5: '#F9FFFC',
  // Primary Lite
  primaryLite: '#07EA97',
  primaryLite90: '#20ECA2',
  primaryLite70: '#52F0B6',
  primaryLite50: '#83F4CB',
  primaryLite30: '#B4F9E0',
  primaryLite10: '#E6FDF4',
  // Secondary
  secondary: '#028EBF',
  secondary90: '#1C9AC6',
  secondary70: '#4EB0D2',
  secondary50: '#80C6DF',
  secondary30: '#B3DDEC',
  secondary10: '#E5F3F8',
  // Background and borders
  background: '#1E2F3F',
  background90: '#354453',
  background70: '#626E79',
  background50: '#8E979F',
  background30: '#BBC0C5',
  background10: '#E8EAEB',
  background5: '#F4F5F5',
  // Others
  white: '#FFFFFF',
  black: '#000000',
  grey: '#3E5468',
  error: '#D83939',
  errorLite: '#FDF5F5',
  warning: '#FF531D',
  success: '#22C275',
  successLite: '#DEF6EA',
  error10: '#FBEBEB',
  warning10: '#FFEEE8',
  success10: '#E9F9F1',
  error5: '#FDF5F5'
};
