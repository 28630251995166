import { isRejectedWithValue } from '@reduxjs/toolkit';
import { createAlert } from '@features/components/notification/notificationSlice';
import { AUTH } from '@constants/routes';

export const rtkQueryErrorLogger =
  (store: any) => (next: any) => (action: any) => {
    const ignore403EndpointList = ['login', 'validateToken', 'resetPassword'];
    const status = action.payload?.status;
    const { dispatch } = store;
    const result = next(action);
    const errorMessage = action.payload?.data?.message;

    if (isRejectedWithValue(action)) {
      switch (status) {
        case 'FETCH_ERROR':
          dispatch(
            createAlert({
              alert: {
                message: 'Failed to fetch',
                type: 'error'
              }
            })
          );
          break;
        case 403:
          if (ignore403EndpointList.includes(action.meta.arg.endpointName)) {
            break;
          } else if (action.payload?.data?.errorCode === 'ACCESS_DENIED') {
            break;
          } else {
            window.location.href = AUTH.LOGIN;
          }
          dispatch(
            createAlert({
              alert: {
                message: 'Unauthenticated',
                type: 'error'
              }
            })
          );
          break;
        case 401:
          if (ignore403EndpointList.includes(action.meta.arg.endpointName))
            break;
          window.location.href = AUTH.LOGIN;
          dispatch(
            createAlert({
              alert: {
                message: 'Unauthenticated',
                type: 'error'
              }
            })
          );
          break;
        case 400:
          if (
            errorMessage === 'given region is not valid' ||
            errorMessage === 'region is missing in the request'
          ) {
            window.location.reload();
          }
          break;
        default:
          break;
      }
    }
    return result;
  };

export default rtkQueryErrorLogger;
