import clsx from 'clsx';
import React from 'react';
import Icon from '../icon/Icon';

export type ToastType = 'error' | 'success' | 'default';

const typeStyles = {
  error: {
    icon: 'rounded-cross',
    iconColor: 'error',
    background: `max-w-md items-center rounded-md bg-error10 px-4 py-3 border border-error`
  },
  success: {
    icon: 'rounded-tick',
    iconColor: 'primaryLite',
    background: `max-w-md items-center rounded-md bg-primaryLite10 px-4 py-3 border border-success`
  },
  default: {
    background: `max-w-md items-center rounded-md bg-secondary10 px-4 py-3 border border-secondary`
  }
};

interface ToastProps {
  type: ToastType;
  title?: string;
  description?: string;
}

const Toast: React.FC<ToastProps> = ({ type, title, description }) => (
  <div className={clsx(typeStyles[type].background)} role="alert">
    <div className="flex w-full items-center">
      {type !== 'default' ? (
        <Icon
          name={typeStyles[type].icon}
          fill={typeStyles[type].iconColor}
          stroke={typeStyles[type].iconColor}
          size="extralarge"
        />
      ) : null}
      <div className="text-body-base-semi pl-2.5">{title}</div>
    </div>
    <div className="text-body pl-9">{description}</div>
  </div>
);

export default Toast;
