import React, { FC } from 'react';
import clsx from 'clsx';
import Icon from '@components/icon/Icon';
import RadioButton from './RadioButton';

interface Option {
  key: string;
  value: string;
}
interface RadioGroupProps {
  options: Array<Option>;
  selectedValue?: string;
  handleChange: (type: string) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  errorStyle?: string;
  labelStyle?: string;
  testId?: string;
}

const RadioGroup: FC<RadioGroupProps> = ({
  options,
  selectedValue,
  handleChange,
  disabled,
  error,
  errorMessage = '',
  errorStyle = '',
  labelStyle = '',
  testId = '',
  ...props
}) => {
  const onSelection = (event: any) => {
    handleChange(event.target.value);
  };

  return (
    <>
      <div className="flex flex-row" {...props}>
        {options?.map((option: any) => (
          <RadioButton
            isSelected={selectedValue === option.key}
            onClick={onSelection}
            label={option.value}
            value={option.key}
            key={option.key}
            disabled={disabled}
            error={error}
            errorStyle={errorStyle}
            labelStyle={labelStyle}
            data-testid={`${testId}_${option.key}`}
          />
        ))}
      </div>
      {errorMessage && (
        <p
          className={clsx(
            'text-caption mt-2 flex items-center text-error',
            errorStyle
          )}
        >
          <Icon name="info-circle" className="mr-1" />
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default RadioGroup;
