import { useEffect, useRef } from 'react';

import {
  DoughnutChartDataProps,
  DoughnutChartProps,
  DoughnutChartTooltipProps
} from './types';
import {
  drawDoughnutChartToolTip,
  getDoughnutChartValues,
  getSumOfItems
} from './helpers';
import ChartLegendTextTable from './ChartLegendTextTable';

const DoughnutChart = ({
  chartData,
  innerText = '',
  infoPosition = 'bottom',
  legendTableHeaders,
  dimensions
}: DoughnutChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const tooltipCanvasRef = useRef<HTMLCanvasElement>(null);

  const { width, height, radius } = dimensions;

  const drawArc = (ctx: any, color: string, startAt: number, endAt: number) => {
    const ctxWidth = ctx.canvas.width;
    const ctxHeight = ctx.canvas.height;
    ctx.strokeStyle = color;
    ctx.beginPath();
    // ctx.arc(width, height, radius, start, end)
    ctx.arc(ctxWidth / 2, ctxHeight / 2, ctxWidth / 2 - radius, startAt, endAt);
    ctx.lineWidth = radius;
    ctx.stroke();
  };

  const drawFixedDoughnutChart = () => {
    const canvas = canvasRef.current;
    const chartList = getDoughnutChartValues(chartData);
    if (canvas && chartList.length > 0) {
      const context = canvas.getContext('2d');
      if (context) {
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        chartList.map((item) => {
          drawArc(
            context,
            item?.fillColor || '#FFF',
            2 * Math.PI * item.startAt,
            2 * Math.PI * item.endAt
          );
          return null;
        });
      }
    }
  };

  const drawAnimatedDoughnutChart = () => {
    const canvas = canvasRef.current;
    let animationFrameId: any;
    const chartList = getDoughnutChartValues(chartData);

    if (canvas && chartList.length > 0) {
      const context = canvas.getContext('2d');
      if (context) {
        const ctxWidth = context.canvas.width;
        const ctxHeight = context.canvas.height;
        context.clearRect(0, 0, ctxWidth, ctxHeight);
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, ctxWidth, ctxHeight);
        let frameCount = 0;
        const totalFrame = 600;
        let counter = 0;
        let element: DoughnutChartDataProps = chartList[0];
        const render = () => {
          [...Array(15)].forEach(() => {
            if (
              frameCount < totalFrame &&
              element.endAt < frameCount / totalFrame
            ) {
              counter += 1;
              element = chartList[counter];
            }
            drawArc(
              context,
              element.fillColor || '#FFF',
              (2 * Math.PI * (frameCount - 1)) / totalFrame,
              (2 * Math.PI * (frameCount + 1)) / totalFrame
            );
            frameCount += 1;
          });

          if (frameCount < totalFrame) {
            animationFrameId = window.requestAnimationFrame(() => render());
          }
        };

        requestAnimationFrame(() => render());
      }
    }
    return animationFrameId;
  };

  useEffect(() => {
    const animationFrameId = drawAnimatedDoughnutChart();
    // Total 60 frames with speed of 60pfs in animation
    // Drawing Complete Chart after 1 sec
    setTimeout(() => {
      drawFixedDoughnutChart();
    }, 2000);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const getPercentage = (angle: number, quarter: number) => {
    if (quarter === 1 || quarter === 2)
      return (Math.PI / 2 - angle) / (2 * Math.PI);
    return (Math.PI + Math.PI / 2 - angle) / (2 * Math.PI);
  };

  const getTooltipText = (
    percentageValue: number,
    chartList: DoughnutChartDataProps[]
  ): DoughnutChartTooltipProps => {
    let tooltipText = {
      text: '',
      value: ''
    };

    chartList.map((item) => {
      if (item.endAt > percentageValue && item.startAt < percentageValue) {
        tooltipText = {
          text: item.name,
          value: item.value.toString()
        };
      }
      return null;
    });
    return tooltipText;
  };

  const handleMouseMove = (event: any) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      let quarter: number = 0;
      if (mouseX > centerX) {
        if (mouseY > centerY) quarter = 1;
        else quarter = 4;
      } else if (mouseY > centerY) quarter = 2;
      else quarter = 3;

      const diffX = mouseX - centerX;
      const diffY = mouseY - centerY;
      const distance = Math.sqrt(diffX * diffX + diffY * diffY);

      // Radius 40 so 20 is half of radius
      if (distance > centerX - 60 && distance < centerX - 20) {
        const angle = Math.atan(diffX / diffY);
        const percentageValue = getPercentage(angle, quarter);
        const tooltipCanvas = tooltipCanvasRef.current;
        if (tooltipCanvas) {
          const chartList = getDoughnutChartValues(chartData);
          const tooltipData = getTooltipText(percentageValue, chartList);
          drawDoughnutChartToolTip(tooltipCanvas, tooltipData, mouseX, mouseY);

          //
        }
      } else {
        // Clear canvas if not hit
        const tooltipCanvas = tooltipCanvasRef.current;
        if (tooltipCanvas) {
          tooltipCanvas.style.left = '-200px';
          const ctx = tooltipCanvas.getContext('2d');
          if (ctx) {
            ctx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);
            ctx.moveTo(-2000, -2000);
          }
        }
      }
    }
  };

  return (
    <div
      className={`flex h-full w-full items-center justify-center gap-4 ${
        infoPosition === 'bottom' ? 'flex-col' : 'flex-row'
      }`}
    >
      <div className="relative flex h-fit w-full justify-center">
        <div className="relative h-fit w-fit">
          <canvas
            ref={canvasRef}
            width={width}
            height={height}
            style={{ backgroundColor: 'white' }}
            onMouseMove={(e) => handleMouseMove(e)}
          />
          <canvas
            ref={tooltipCanvasRef}
            className="absolute "
            style={{ zIndex: 4 }}
            onMouseMove={(e) => handleMouseMove(e)}
          />
        </div>
        {innerText && (
          <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center ">
            <div className="text-body text-center text-background90">
              {innerText}
            </div>
            <div className="text-h4">{getSumOfItems(chartData)}</div>
          </div>
        )}
      </div>
      <ChartLegendTextTable
        chartData={chartData}
        infoPosition={infoPosition}
        maxHeight={height}
        maxWidth={width}
        legendTableHeaders={legendTableHeaders}
      />
    </div>
  );
};

export default DoughnutChart;
