import { baseApi } from '@core/baseApi';
import { END_POINTS } from '@config';
import { Region, HTTPResponse, UpdateSelfDetailsRequest } from '@types';

export const extendedBaseQuery = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateSelfDetails: builder.mutation<
      HTTPResponse<Region>,
      UpdateSelfDetailsRequest
    >({
      query: (reqBody) => ({
        url: END_POINTS.USER.UPDATE_SELF_DETAILS,
        method: 'PUT',
        body: reqBody.body
      })
    })
  })
});

export const { useUpdateSelfDetailsMutation } = extendedBaseQuery;
