import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  extractRegionCodeFromUrl,
  validateRegionCode
} from '@features/regions/helpers/helpers';

import { Breadcrumbs, Spinner } from '@components';
import SideNavBar from '../side-nav-bar/SideNavBar';
import MainHeader from '../main-header/MainHeader';

const MainLayout = () => {
  const { currentUserRegions } = useSelector((state: any) => state.region);
  const hasValidRegion =
    currentUserRegions?.length > 0 &&
    validateRegionCode(extractRegionCodeFromUrl(), currentUserRegions);

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <SideNavBar />
      <div className="ml-20 h-full w-full">
        <Suspense
          fallback={
            <div className="flex h-full w-full items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <MainHeader />
          <div className="h-screen overflow-y-scroll bg-background5 pb-[150px]">
            <Breadcrumbs />
            {hasValidRegion && <Outlet />}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default MainLayout;
