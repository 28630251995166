module.exports = {
  '38px': '38px',
  '22px': '22px'
};

// Note: other available fontSizes as per style guide
// 'xs': '12px'
// 'sm': '14px
// 'base': '16px'
// '2xl': '24px'
// '3xl': '30px'
