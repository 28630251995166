import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { REGION } from '@constants/routes';

const Regions = lazy(() => import('@features/regions/pages/regions/Regions'));

export const AccountSettingsRoutes: RouteObject[] = [
  {
    path: REGION.REGION_MANAGEMENT,
    element: <Regions />
  }
];

export default AccountSettingsRoutes;
